<template>
  <div class="risk-factors-card-container">
    <div
      class="d-flex justify-center align-center risk-factors-card-container__spinner"
      v-if="loading"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <!--  HEADER -->
    <div>
      <div class="sub-title-row d-flex justify-space-between">
        <p class="ma-0">{{ latestDateMonthYear }}</p>
        <!-- Comment out until functionality fleshed out -->
        <!-- <v-icon
          size="1.5rem"
          class="pointer icon-hover"
          @click="expandRiskFactors"
          >mdi-arrow-expand-all</v-icon
        > -->
      </div>
      <div class="d-flex">
        <h4 class="main-title mb-0 mr-2">Risk Factors</h4>
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              size="1.45rem"
              class="pointer icon-hover"
              v-on="on"
              v-bind="attrs"
              @click="getRiskFactorDefinitions"
              >{{ informationIcon }}</v-icon
            >
          </template>
          <span>Risk factor definitions</span>
        </v-tooltip>
      </div>
    </div>
    <div class="risk-factors-table pb-2">
      <table>
        <thead>
          <tr>
            <th></th>
            <th>
              <label class="ma-0 ml-2">STATUS</label>
            </th>
            <th>
              <div class="empty-header"></div>
            </th>
            <th>GENERATOR SIDE</th>
            <th>GRID SIDE 1</th>
            <th>GRID SIDE 2</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="risk in riskFactorsDisplay" :key="risk.id">
            <td>
              <div class="risk-item">{{ risk.name }}</div>
            </td>
            <td>
              <div class="risk-item">
                <v-icon :color="setStatusColor(risk.status)"
                  >mdi-circle-medium</v-icon
                >
              </div>
            </td>
            <td></td>
            <td>
              <div class="risk-item-container">
                <div class="risk-item">
                  <v-icon :color="setStatusColor(risk.status)"
                    >mdi-circle-medium</v-icon
                  >
                </div>
              </div>
            </td>
            <td>
              <div class="risk-item">
                <v-icon :color="setStatusColor(risk.status)"
                  >mdi-circle-medium</v-icon
                >
              </div>
            </td>
            <td>
              <div class="risk-item">
                <v-icon :color="setStatusColor(risk.status)"
                  >mdi-circle-medium</v-icon
                >
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="d-flex align-end justify-space-between">
      <p class="ma-0 updated-text">Updated {{ lastUpdatedDate }}</p>
      <div class="new-button">
        <button class="explore-issues-button" @click="goToIssuesTable">
          EXPLORE ALL ISSUES
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { informationIcon, popupDelay } from "@/helpers/variables";

export default {
  name: "ConverterRiskFactorsCard",
  props: {
    riskFactors: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      riskFactorsNames: [
        {
          text: "STRESS INDEX",
          value: "stress_index_status",
        },
        {
          text: "RELIABILITY",
          value: "reliability_status",
        },
        {
          text: "REMAINING USEFUL LIFE",
          value: "environmental_status",
        },
      ],
      informationIcon,
      popupDelay,
    };
  },
  computed: {
    riskFactorsDisplay() {
      let riskFactorsArray = [];
      if (this.riskFactors && Object.keys(this.riskFactors).length > 0) {
        for (const item of this.riskFactorsNames) {
          if (item.value in this.riskFactors) {
            riskFactorsArray.push({
              name: item.text,
              generator_side:
                this.riskFactors[item.value].generator_side_status,
              status: this.riskFactors[item.value].overall_status,
              grid_side_1: this.riskFactors[item.value].grid_side_1_status,
              grid_side_2: this.riskFactors[item.value].grid_side_2_status,
            });
          }
        }
        return riskFactorsArray;
      }
      return [];
    },
    lastUpdatedDate() {
      if (this.riskFactors && Object.keys(this.riskFactors).length > 0) {
        const latestTimestamp = dayjs(this.riskFactors.latest_timestamp).format(
          "YYYY-MM-DD",
        );
        return latestTimestamp;
      }
      return null;
    },
    latestDateMonthYear() {
      if (this.lastUpdatedDate) {
        return dayjs(this.lastUpdatedDate).format("MMMM YYYY").toUpperCase();
      }
      return null;
    },
  },
  methods: {
    goToIssuesTable() {
      this.$emit("goToIssueTable");
    },
    setStatusColor(status) {
      switch (status) {
        case "unhealthy":
          return "error";
        case "warning":
          return "warning";
        case "healthy":
          return "green";
        case null:
          return "grey";
      }
    },
    getRiskFactorDefinitions() {
      this.$emit("getRiskFactorDefinitions");
    },
  },
};
</script>

<style lang="scss" scoped>
.risk-factors-card-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  padding: 0.5rem 1rem 1rem 1rem;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.sub-title-row {
  p {
    font:
      0.75rem "Museo Sans Rounded",
      sans-serif;
    color: var(--v-text-base);
    font-weight: 600;
  }
}

table {
  border-collpase: collapse;
  width: 100%;
  min-height: 150px;
}
th {
  text-align: center;
  padding-bottom: 0.5rem;
  padding-right: 0.25rem;
  border-bottom: 1px dotted var(--v-converterBorders-base);
  font-family: "Inter", sans-serif;
}
th:last-child {
  padding-right: none;
}
tr {
  border-bottom: 1px dotted var(--v-converterBorders-base);
}
tr:last-child {
  border: none;
}
td {
  border-right: 1px solid var(--v-converterBorders-base);
  padding: 0.75rem;
  text-align: center;
}
td:first-child {
  text-align: left;
  padding-left: 0.25rem;
  color: var(--v-text-base);
}
td:nth-child(3) {
  padding: 0 0;
  padding-left: 0.25rem;
}
td:last-child {
  border-right: none;
}

.empty-header {
  width: 1px;
}

.explore-issues-button {
  border: 1.75px solid var(--v-inverse-base);
  border-radius: 3rem;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
}
.explore-issues-button:hover {
  background-color: var(--v-converterBorders-base);
}
</style>
