<template>
  <div>
    <section class="grid-1">
      <div class="grid-1-row-1-col-1">
        <div class="d-flex">
          <div
            class="view-input-events w100"
            :class="{ 'error-highlight-view': !isViewValid }"
          >
            <v-autocomplete
              ref="viewSelection"
              content-class="pb-0"
              v-model="selectedView"
              :items="views"
              label="Select a view"
              hide-no-data
              hide-selected
              dense
              solo
              :rules="selectedViewRules"
              @change="changeViewSelected"
              @focus="clearError('view')"
              @blur="handleBlurViewSelection"
            ></v-autocomplete>
          </div>
        </div>
      </div>
      <div class="grid-1-row-2-col-1">
        <div class="summary-type">
          <label for="summary-type">Summary type:</label>
          <v-select
            ref="eventSummarySelection"
            content-class="pb-0"
            v-model="selectedSummaryType"
            :items="summaryTypes"
            hide-no-data
            hide-selected
            dense
            solo
            :rules="selectedSummaryTypeRules"
            :menu-props="{ offsetY: true }"
            @change="changeSummaryTypeSelected"
            @blur="handleBlurSummaryTypeSelection"
          ></v-select>
        </div>
      </div>
      <div class="grid-1-row-2-col-2">
        <div class="w100 month-picker">
          <date-picker
            type="month"
            placeholder="Select month"
            v-model="selectedMonth"
            :disabled-date="disabledMonths"
            :clearable="false"
            @pick="handlePickMonth"
          >
          </date-picker>
        </div>
      </div>
    </section>
    <section>
      <div class="figure-description" v-if="eventPlotData">
        <h4>{{ figureTitle }}</h4>
        <div v-if="eventPlotData">
          <ul class="ma-0 ml-2" v-for="item of figureDetails" :key="item">
            <li>
              <p class="ma-0">{{ item }}</p>
            </li>
          </ul>
        </div>
        <p class="divider-border"></p>
      </div>
      <div class="summary-figure">
        <div
          class="d-flex justify-center align-center summary-figure__spinner"
          v-if="loadingPlot"
        >
          <v-progress-circular
            :size="40"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
        <Plotly
          v-if="eventPlotData"
          ref="plotlyDiagram"
          id="plotly_diagram"
          :data="eventPlotData"
          :layout="eventPlotLayout"
          :responsive="true"
        ></Plotly>
        <div
          v-else-if="!loadingPlot && !eventPlotData"
          class="no-data-available"
        >
          NO DATA AVAILABLE
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
import Plotly from "@/components/Plotly";
import {
  eventCountsPlotDescription,
  eventDurationsPlotDescription,
} from "@/helpers/variables";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "EventsExplorer",
  components: {
    DatePicker,
    Plotly,
  },
  props: {
    page: {
      type: String,
      required: false,
    },
    siteId: {
      type: Number,
      required: false,
    },
  },
  data() {
    return {
      events: [],
      selectedView: "events",
      selectedViewRules: [],
      views: [
        { text: "Yaw misalignment", value: "yaw_misalignment" },
        { text: "Minimum pitch", value: "minimum_pitch" },
        { text: "Wind rose", value: "wind_rose" },
        { text: "Mod window suitability", value: "mod_window_suitability" },
        { text: "Power curve", value: "power_curve_explorer" },
        { text: "Events", value: "events" },
      ],
      selectedSummaryType: "counts",
      selectedSummaryTypeRules: [],
      isSummaryTypeValid: true,
      summaryTypes: [
        {
          text: "Duration",
          value: "duration",
        },
        {
          text: "Counts",
          value: "counts",
        },
      ],
      isViewValid: true,
      selectedMonth: null,
      staticSelectedMonth: null,
      eventCountsPlotDescription,
      eventDurationsPlotDescription,
    };
  },
  computed: {
    ...mapState({
      eventCountsPlot: (state) => state.site.siteEventCounts,
      eventDurationsPlot: (state) => state.site.siteEventDurations,
      loading: (state) => state.site.loading,
    }),
    eventPlotData() {
      let data = null;
      if (this.eventDurationsPlot && this.selectedSummaryType === "duration") {
        data = this.eventDurationsPlot?.data;
      } else if (
        this.eventCountsPlot &&
        this.selectedSummaryType === "counts"
      ) {
        data = this.eventCountsPlot?.data;
      }
      return data;
    },
    eventPlotLayout() {
      let layout = null;
      if (this.eventDurationsPlot && this.selectedSummaryType === "duration") {
        layout = {
          ...this.eventDurationsPlot?.layout,
          margin: {
            r: 10,
            t: 40,
          },
        };
      } else if (
        this.eventCountsPlot &&
        this.selectedSummaryType === "counts"
      ) {
        layout = {
          ...this.eventCountsPlot?.layout,
          margin: {
            r: 10,
            t: 40,
          },
        };
      }
      return layout;
    },
    figureTitle() {
      return this.selectedSummaryType === "counts"
        ? "Top 15 event counts"
        : "Top 15 event durations";
    },
    figureDetails() {
      let details = null;
      if (this.selectedSummaryType === "counts") {
        details = this.eventCountsPlotDescription;
      } else if (this.selectedSummaryType === "duration") {
        details = this.eventDurationsPlotDescription;
      }
      return details;
    },
    loadingPlot() {
      if (
        this.loading.getSiteEventCounts ||
        this.loading.getSiteEventDurations
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getEventCountsPlotData: "site/getSiteEventCounts",
      getEventDurationsPlotData: "site/getSiteEventDurations",
    }),
    changeViewSelected(event) {
      const url = new URL(window.location.href);
      const currentState = history.state;
      const views = this.views.map((view) => view.value);
      if (event && views.includes(event.toLowerCase())) {
        this.$emit("viewChanged", event);
        if (event !== "events") {
          url.search = "";
          this.$emit("openEventsView", false, event);
          if (event === "power_curve_explorer") {
            this.$emit("openPowerCurveExplorer", true);
          } else {
            this.$emit("openPowerCurveExplorer", false);
          }
        }
        if (this.page === "siteDashboard") {
          url.searchParams.set("view", event);
        } else {
          url.searchParams.set("analytics_view", event);
        }
        history.replaceState(currentState, null, url);
      }
    },
    // Used to call for an event plot depending on the summary type selected
    changeSummaryTypeSelected(event) {
      let site_id = null;
      const siteId = parseInt(this.$route.params.siteId);
      if (!this.siteId && isNaN(siteId)) {
        return;
      } else if (this.siteId) {
        site_id = this.siteId;
      } else {
        site_id = siteId;
      }
      // Initialize an instance of the url for browser param setting
      const url = new URL(window.location.href);
      // Set current state for the history replace state method
      const currentState = history.state;
      // Use dayjs to make sure dates are formatted correctly
      const selectedMonth = dayjs(this.selectedMonth);
      // Create a date range with the chosen month
      const startDate = selectedMonth.startOf("month").format("YYYY-MM-DD");
      const endDate = selectedMonth.endOf("month").format("YYYY-MM-DD");
      const params = {
        siteId: site_id,
        startDate,
        endDate,
        limit: 15,
      };
      if (event === "counts") {
        this.getEventCountsPlotData(params);
      } else {
        this.getEventDurationsPlotData(params);
      }
      // Set the url params for the selected summary type
      url.searchParams.set("summary_type", event);
      url.searchParams.set("month", selectedMonth.format("YYYY-MM"));
      history.replaceState(currentState, null, url);
    },
    handleBlurViewSelection() {
      if (!this.selectedView) {
        this.selectedViewRules = [(v) => !!v || "Must select a view."];
        this.isViewValid = false;
      } else {
        this.selectedViewRules = [];
        this.isViewValid = true;
      }
    },
    handleBlurSummaryTypeSelection() {
      const summaryTypes = this.summaryTypes.map((summary) => summary.value);
      if (
        !this.selectedSummaryType ||
        !summaryTypes.includes(this.selectedSummaryType.toLowerCase())
      ) {
        this.selectedSummaryTypeRules = [(v) => !!v || "Must select a type."];
        this.isSummaryTypeValid = false;
      } else {
        this.selectedSummaryTypeRules = [];
        this.isSummaryTypeValid = true;
      }
    },
    getPreviousMonth() {
      const today = dayjs();
      const monthAgo = dayjs(today).subtract(1, "month");
      return monthAgo.$d;
    },
    handlePickMonth(event) {
      const selectedMonth = dayjs(event);
      const staticMonth = dayjs(this.staticSelectedMonth);
      if (selectedMonth.isSame(staticMonth)) {
        return;
      }
      this.staticSelectedMonth = event;
      this.changeSummaryTypeSelected(this.selectedSummaryType);
    },
    disabledMonths(date) {
      const today = dayjs();
      const firstDayOfCurrMnth = dayjs(today).startOf("month");
      // Disable dates that are in current and future months
      return (
        dayjs(date).isAfter(firstDayOfCurrMnth) ||
        dayjs(date).isSame(firstDayOfCurrMnth)
      );
    },
    clearError(type) {
      if (type === "view") {
        this.selectedViewRules = [];
        this.isViewValid = true;
      }
    },
  },
  mounted() {
    // Initialize the selected month to the previous month
    this.selectedMonth = this.getPreviousMonth();
    // Create a static copy for comparison
    this.staticSelectedMonth = this.getPreviousMonth();
    // Call for initial plot data for default summary type
    this.changeSummaryTypeSelected("counts");
  },
};
</script>

<style lang="scss" scoped>
.grid-1 {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 65px 1fr;
  gap: 0px 0px;
}
.grid-1-row-1-col-1 {
  grid-area: 1 / 1 / 2 / 2;
}
.grid-1-row-2-col-2 {
  grid-area: 2 / 2 / 2 / 3;
}
.grid-1-row-2-col-1 {
  grid-area: 2 / 1 / 3 / 2;
}

.month-picker {
  padding-left: 25px;
  padding-top: 25.25px;
}

.summary-type {
  min-width: 200px;
  font:
    0.875rem "Inter",
    sans-serif;
}

::v-deep {
  .view-input-events .v-input__slot .v-select__slot {
    cursor: pointer;
  }
}

.figure-description {
  height: 150px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;
}
.divider-border {
  border-bottom: 1px solid var(--v-border-base);
  margin-top: 1.5rem;
}
.summary-figure {
  position: relative;
  height: 460px;
  padding: 0.5rem 0.75rem 0.5rem 0.75rem;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 100;
  }
}
</style>
