<template>
  <div class="issues-tab-container">
    <TableHeaderFilter
      :headers="headers"
      :selectedHeaders="selectedHeaders"
      menuActivator="#main-bearing-issues-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="issues"
      :loading="loading"
      :hasFooter="true"
      tabSelected="issues"
      menuActivator="main-bearing-issues-col-filter"
    >
      <template v-slot:name="{ item }">
        <router-link
          :to="{
            name: 'NewIssueDetail',
            params: {
              issueId: item.id,
            },
          }"
          class="link-cell"
        >
          <div class="d-flex justify-space-between">
            <div class="link-text">{{ item.name }}</div>
            <div class="mr-1 pointer">
              <v-icon size="1.25rem">mdi-open-in-new</v-icon>
            </div>
          </div>
        </router-link>
      </template>
      <template v-slot:status="{ item }">
        <div>
          <span
            class="issue-status"
            :style="{
              'background-color': generateStatusColor(
                item.current_status_def_id,
              ),
            }"
            >{{ item.status }}</span
          >
        </div>
      </template>
      <template v-slot:ticketsInProgress="{ item }">
        <div class="d-flex flex-wrap align-center">
          <div v-for="ticket in item.ticketsInProgress" :key="ticket">
            <div v-if="item.ticketsInProgress.length <= 2">
              <router-link
                :to="{
                  name: 'NewIssueDetail',
                  params: {
                    issueId: item.id,
                  },
                }"
              >
                <div class="issue-in-progress mr-1">
                  {{ ticket }}
                </div>
              </router-link>
            </div>
          </div>
          <div
            class="show-all-issues-container"
            v-if="item.ticketsInProgress && item.ticketsInProgress.length > 2"
          >
            <div
              @click="showAllModal(item)"
              class="show-all-issues-display ml-2"
            >
              {{ showAllIssuesInProgress ? "Show less" : "Show all" }}
            </div>
            <div
              v-if="showAllIssuesInProgress && selectedItem === item"
              class="all-issues"
            >
              <div class="d-flex justify-space-around">
                <p
                  class="ma-0"
                  v-for="ticket in selectedItem.ticketsInProgress"
                  :key="ticket"
                >
                  <router-link
                    :to="{
                      name: 'NewIssueDetail',
                      params: {
                        issueId: item.id,
                      },
                    }"
                  >
                    <div class="issue-in-progress mr-1">
                      {{ ticket }}
                    </div>
                  </router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-slot:impact_mwh="{ item }">
        <div>
          <span class="mr-1">{{ item.impact_mwh }} MWh</span><span>/</span
          ><span class="ml-1">$</span>{{ item.impact_usd }}
        </div>
      </template>
    </DynamicTable>
  </div>
</template>

<script>
import { generateStatusColor } from "@/helpers/variables";
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import DynamicTable from "@/components/DynamicTable";
import debounce from "lodash/debounce";

export default {
  name: "RotorShaftIssuesTab",
  components: {
    DynamicTable,
    TableHeaderFilter,
  },
  props: {
    issues: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      showAllIssuesInProgress: false,
      selectedIssue: null,
      selectedItem: null,
      generateStatusColor,
      headers: [
        {
          id: 1,
          text: "ISSUE NAME",
          value: "name",
        },
        {
          id: 2,
          text: "STATUS",
          value: "status",
        },
        {
          id: 3,
          text: "TICKETS IN PROGRESS",
          value: "ticketsInProgress",
          not_sortable: true,
        },
        {
          id: 4,
          text: "IMPACT (MWh/$)",
          value: "impact_mwh",
        },
        {
          id: 5,
          text: "LAST UPDATED",
          value: "last_updated",
        },
      ],
      selectedColumns: [],
      maxColumns: 5,
    };
  },
  computed: {
    selectedHeaders() {
      let result = this.headers;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    showAllModal(item) {
      this.selectedItem = item;
      this.showAllIssuesInProgress = !this.showAllIssuesInProgress;
    },
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.headers.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.headers;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.headers;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.headers.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
.issues-tab-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.issue-status {
  padding: 0.25rem 0.5rem 0.25rem 0.5rem;
  border-radius: 12px;
  white-space: nowrap;
  color: var(--v-issueStatusBadgeFont-base);
}

.all-issues {
  position: absolute;
  bottom: 0;
  right: 75px;
  width: 250px;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 6px;
  box-shadow: 2px 2px 8px 4px rgba(13, 13, 13, 0.3);
  background: var(--v-issueModal-base);
  z-index: 1000;
  padding: 0.5rem;
}
</style>
