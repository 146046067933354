<template>
  <div class="components-tab-container">
    <TableHeaderFilter
      :headers="headers"
      :selectedHeaders="selectedHeaders"
      menuActivator="#main-bearing-issues-col-filter"
      @changeHeaders="changeSelectedHeaders"
    />
    <DynamicTable
      :headers="selectedHeaders"
      :items="subsystemComponents"
      :loading="loading"
      :hasFooter="true"
      tabSelected="components"
      menuActivator="main-bearing-issues-col-filter"
    >
    </DynamicTable>
  </div>
</template>

<script>
import TableHeaderFilter from "@/components/TableHeaderFilter.vue";
import DynamicTable from "@/components/DynamicTable";
import debounce from "lodash/debounce";

export default {
  name: "RotorShaftFeaturesTab",
  components: {
    DynamicTable,
    TableHeaderFilter,
  },
  props: {
    subsystemComponents: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      headers: [
        {
          id: 1,
          text: "NAME",
          value: "name",
        },
        {
          id: 2,
          text: "MODEL",
          value: "model",
        },
        {
          id: 3,
          text: "OEM",
          value: "oem",
        },
        {
          id: 4,
          text: "INSTALLATION",
          value: "installation",
        },
      ],
      selectedColumns: [],
      maxColumns: 5,
    };
  },
  computed: {
    selectedHeaders() {
      let result = this.headers;
      if (this.selectedColumns?.length > 0) {
        result = result.filter((item) => {
          const matchedHeader = this.selectedColumns.filter((column) => {
            return column.id === item.id;
          });
          if (matchedHeader?.length > 0) {
            return item;
          }
        });
        return result;
      }
      return [];
    },
  },
  methods: {
    changeSelectedHeaders(header) {
      if (header === "selectAll") {
        if (
          this.selectedColumns.length === this.headers.length ||
          this.selectedColumns.length === this.maxColumns
        ) {
          this.selectedColumns = [];
          return;
        } else {
          this.selectedColumns = this.headers;
        }
      } else if (header === "reset") {
        this.selectedColumns = this.headers;
      } else if (header === "clear") {
        this.selectedColumns = [];
      } else {
        const index = this.selectedColumns.indexOf(header);
        if (index !== -1) {
          this.selectedColumns = this.selectedColumns.filter(
            (item) => item !== header,
          );
        } else {
          if (this.selectedColumns.length >= this.maxColumns) {
            this.selectedColumns.shift();
          }
          this.selectedColumns.push(header);
        }
      }
      this.ensureMaxColumns();
    },
    handleResize() {
      this.ensureMaxColumns();
    },
    ensureMaxColumns() {
      const width = window.innerWidth;
      this.maxColumns = width < 900 ? 4 : this.headers.length;
      if (this.selectedColumns.length > this.maxColumns) {
        this.selectedColumns = this.selectedColumns.slice(0, this.maxColumns);
      }
    },
  },
  mounted() {
    this.selectedColumns = this.headers;
    this.handleResize = debounce(this.handleResize, 200);
    this.handleResize();
    window.addEventListener("resize", this.handleResize);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../../assets/scss/_variables";
.components-tab-container {
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.all-issues {
  position: absolute;
  bottom: 0;
  right: 75px;
  width: 250px;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 6px;
  box-shadow: 2px 2px 8px 4px rgba(13, 13, 13, 0.3);
  background: var(--v-issueModal-base);
  z-index: 1000;
  padding: 0.5rem;
}
</style>
