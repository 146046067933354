<template>
  <div
    :class="
      $vuetify.theme.dark
        ? 'power-curve-figure-container-dark'
        : 'power-curve-figure-container-light'
    "
  >
    <div class="power-image-controls">
      <div class="d-flex justify-center">
        <div class="color-column-control mr-4">
          <label for="color-column">Color column:</label>
          <v-autocomplete
            name="color-column"
            id="color-column"
            v-model="colorSelection"
            :items="signalOptions"
            content-class="pb-0"
            hide-no-data
            hide-selected
            hide-details
            placeholder="Select..."
            dense
            solo
            @change="emit10mPowerCurveOptions"
          ></v-autocomplete>
        </div>
        <div class="d-flex flex-column">
          <div class="d-flex checkbox-container mb-1">
            <v-checkbox
              id="density-checkbox"
              name="density-checkbox"
              class="ma-0"
              hide-details
              v-model="useDensityCorrection"
              @change="emit10mPowerCurveOptions"
            ></v-checkbox>
            <label for="density-checkbox" class="no-wrap-text"
              >Density correction</label
            >
          </div>
          <div class="d-flex checkbox-container mt-1">
            <v-checkbox
              id="overlay-curve"
              name="overlay-curve"
              class="ma-0"
              hide-details
              v-model="overlayPowerCurve"
              @change="emit10mPowerCurveOptions"
            ></v-checkbox>
            <label for="overlay-curves" class="overlay-curve"
              >Overlay contract curve</label
            >
          </div>
        </div>
      </div>
      <div
        class="d-flex align-center justify-center marker-select-container mt-2"
      >
        <label for="marker-select" class="no-wrap-text mr-1"
          >Time dependency markers:</label
        >
        <div class="ml-2">
          <v-icon class="time-dependency-markers" size="15"
            >mdi-menu-left</v-icon
          >
          <v-icon class="time-dependency-markers" size="26"
            >mdi-menu-left</v-icon
          >
          <v-icon class="time-dependency-markers" size="20"
            >mdi-plus-thick</v-icon
          >
          <v-icon class="time-dependency-markers" size="26"
            >mdi-menu-right</v-icon
          >
          <v-icon class="time-dependency-markers" size="15"
            >mdi-menu-right</v-icon
          >
        </div>
      </div>
    </div>
    <div class="power-image" ref="plotlyContainer">
      <div
        :class="
          $vuetify.theme.dark ? 'plotly-figure-dark' : 'plotly-figure-light'
        "
      >
        <Plotly
          v-if="
            powerCurve10mFigure && Object.keys(powerCurve10mFigure).length > 0
          "
          :data="powerCurve10mFigure.data"
          :layout="powerCurve10mFigure.layout"
          :responsive="true"
          @plotlyPointClicked="handlePlotlyPointClicked"
        ></Plotly>
        <div
          v-if="
            (!powerCurve10mFigure ||
              Object.keys(powerCurve10mFigure).length === 0) &&
            !loading
          "
          class="no-data-available no-data"
        >
          NO DATA AVAILABLE
        </div>
        <div
          class="d-flex justify-center align-center plotly-figure-light__spinner"
          v-if="loading"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "@/components/Plotly";

export default {
  name: "PowerCurveFigureCard",
  components: {
    Plotly,
  },
  props: {
    powerCurve10mData: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      useDensityCorrection: true,
      overlayPowerCurve: true,
      signalOptions: [
        {
          text: "Ambient temperature",
          value: "ambienttemp_degc_mn",
        },
        {
          text: "Blade 1 pitch",
          value: "pitchangleblade1_deg_mn",
        },
        {
          text: "Generator speed",
          value: "generatorspeed_rpm_mn",
        },
        {
          text: "Nacelle direction",
          value: "nacelledirection_deg_mn",
        },
        {
          text: "Power",
          value: "realpower_kw_mn",
        },
        {
          text: "Rotor speed",
          value: "rotorspeed_rpm_mn",
        },
        {
          text: "Wind direction",
          value: "winddirection_deg_mn",
        },
        {
          text: "Wind speed",
          value: "windspeed_mps_mn",
        },
        {
          text: "Yaw error",
          value: "yawerror_deg_mn",
        },
      ],
      colorSelection: "",
    };
  },
  computed: {
    powerCurve10mFigure() {
      if (
        this.powerCurve10mData &&
        Object.keys(this.powerCurve10mData).length > 0
      ) {
        return this.setPowerCurve10mFigure(this.powerCurve10mData);
      } else {
        return null;
      }
    },
    powerCurveOptions() {
      if (this.options && Object.keys(this.options).length > 0) {
        return this.options;
      } else {
        return {};
      }
    },
  },
  methods: {
    setPowerCurve10mFigure(pc10mData) {
      // eslint-disable-next-line no-undef
      const clonedPC10mData = structuredClone(pc10mData);
      clonedPC10mData.layout.autosize = true;
      clonedPC10mData.layout.height = 355;
      if (this.$vuetify.theme.isDark) {
        clonedPC10mData.layout.paper_bgcolor = "#1e1e1e";
        clonedPC10mData.layout.font = {
          color: "#fff",
        };
      }
      return clonedPC10mData;
    },
    emit10mPowerCurveOptions() {
      const params = {
        color: this.colorSelection,
        overlay: this.overlayPowerCurve,
        density: this.useDensityCorrection,
      };

      this.$emit("powerCurve10mOptions", params);
    },
    handlePlotlyPointClicked(payload) {
      const params = {
        annotate_ts: payload.annotate_ts,
        annotate_turbine: payload.annotate_turbine,
        plot_type: "10mCurvePlot",
      };

      this.$emit("plotlyPointClicked", params, "point_clicked");
    },
  },
  mounted() {
    this.emit10mPowerCurveOptions();
  },
};
</script>

<style lang="scss" scoped>
.power-curve-figure-container-light,
.power-curve-figure-container-dark {
  border-radius: 8px;
  min-width: 530px;
  margin-bottom: 0.65rem;
  padding: 0.25rem;
  display: grid;
  height: 515px;
  grid-template-rows: 1fr 1fr;
}

.power-curve-figure-container-light {
  border: 1px solid var(--v-pcBorderColor-base);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.power-curve-figure-container-dark {
  border: 1px solid var(--v-pcSubtleBorderColor-base);
  box-shadow: 1px 1px 2px 0 rgba(30, 30, 30, 0.4);
}

.power-image {
  padding: 0 0.25rem 0.25rem 0.25rem;
  height: 100%;
}

.plotly-figure-light,
.plotly-figure-dark {
  position: relative;
  min-height: 355px;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--v-pcSpinnerOverlay-base);
    opacity: 0.4;
    z-index: 100;
  }
}

.plotly-figure-light {
  border: 1px solid var(--v-pcBorderColor-base);
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);
}

.plotly-figure-dark {
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.6);
}

.power-image-controls {
  padding: 0.25rem 0.5rem;
}

.color-column-control,
.overlay-curves-control {
  max-width: 250px;
}

label {
  margin-bottom: 0.125rem;
}

.checkbox-container {
  label {
    padding-top: 0.375rem;
    margin: 0;
  }
}

.overlay-curve {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.marker-select-container {
  margin-left: -3rem;
  label,
  .v-input {
    margin: 0;
  }

  .v-input {
    padding: 0;
  }
}

.no-data {
  min-height: 355px;
}

.time-dependency-markers {
  margin: 0 -5px;
}
</style>
