<template>
  <div class="table-container" :class="pageType === 'home' ? 'h100' : ''">
    <div
      class="d-flex justify-center align-center table-container__spinner"
      v-if="loading && tabSelected !== 'next issue'"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <div class="d-flex justify-space-between">
      <div class="d-flex">
        <div v-if="!disableSearch" class="mr-1">
          <SearchWidget
            :searchText="searchText"
            @searchTable="filterBySearch"
          />
        </div>
        <div
          class="toggle-unconfirmed-switch mt-2 ml-1"
          v-if="
            isInternalUser &&
            tabSelected === 'issues' &&
            (pageType === 'site' || pageType === 'allComponents')
          "
        >
          <v-tooltip top :open-delay="popupDelay">
            <template v-slot:activator="{ on, attrs }">
              <div class="d-flex unconfirmed-switch" v-bind="attrs" v-on="on">
                <span class="any-label">Any</span>
                <v-switch
                  inset
                  dense
                  v-model="showUnconfirmedOnly"
                  hide-details
                  class="ma-0 pa-0"
                  style="transform: scale(0.75)"
                  @change="toggleIssueTypesUnconfirmed"
                >
                </v-switch>
                <span>Unconfirmed</span>
              </div>
            </template>
            <span
              >Toggle between issues that contain any status or
              unconfirmed</span
            >
          </v-tooltip>
        </div>
        <div
          class="status-filter-wrapper"
          v-if="
            (tabSelected === 'issues' || tabSelected === 'next issue') &&
            pageType !== 'site' &&
            pageType !== 'allComponents' &&
            pageType !== 'org'
          "
        >
          <v-tooltip top :open-delay="popupDelay">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                id="filter-icon"
                size="1.7rem"
                v-on="on"
                v-bind="attrs"
                class="pointer"
                >mdi-filter-variant</v-icon
              >
            </template>
            <span>Filter issue status</span>
          </v-tooltip>
          <!-- Issue status filter -->
          <v-menu
            offset-x
            nudge-right="5"
            activator="#filter-icon"
            :close-on-content-click="false"
          >
            <div class="menu-items-container">
              <div class="d-flex justify-center pt-3 mb-2">
                <b
                  class="select-action select-all-title text-decoration-underline"
                  @click="selectStatusFilters('selectAll')"
                >
                  Select all
                </b>
                <b
                  class="select-action select-reset-title text-decoration-underline"
                  @click="selectStatusFilters('reset')"
                >
                  Reset
                </b>
                <b
                  class="select-action select-clear-title text-decoration-underline"
                  @click="clearSelectedItems()"
                >
                  Clear
                </b>
              </div>
              <div
                v-for="status of statusFilters"
                :key="status.id"
                class="menu-list"
              >
                <div
                  class="d-flex justify-space-between align-center menu-item"
                  @click="selectStatusFilters(status)"
                >
                  <p class="mb-0 mr-2">{{ status.text }}</p>
                  <v-icon
                    color="green"
                    v-if="selectedStatuses.includes(status.text)"
                    >mdi-check</v-icon
                  >
                </div>
              </div>
            </div>
          </v-menu>
        </div>
      </div>
      <div class="column-filter-wrapper" v-if="pageType !== 'home'">
        <v-tooltip top :open-delay="popupDelay">
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              :id="menuActivator"
              size="1.7rem"
              v-on="on"
              v-bind="attrs"
              class="pointer"
              >mdi-cog-outline</v-icon
            >
          </template>
          <span>Filter table columns</span>
        </v-tooltip>
      </div>
    </div>
    <div
      class="d-flex justify-center align-center no-items-text"
      v-if="filteredItemsBySearch.length === 0 && !loading"
    >
      <p>{{ noItemsText }}</p>
    </div>
    <div v-else class="flex-container">
      <div>
        <table class="dynamic-table">
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header.id"
                @click="handleSort(header)"
              >
                <div class="d-flex align-center" v-if="headersMatch(header)">
                  <slot :name="'header_' + header.value" :header="header">
                    <div>{{ header.text }}</div>
                  </slot>
                  <div
                    v-if="
                      !header.not_sortable && currentSortColumn === header.value
                    "
                  >
                    <v-icon
                      size="1.5rem"
                      v-if="sortDirection === 'asc'"
                      class="sort-icon"
                      >mdi-arrow-up-thin</v-icon
                    >
                    <v-icon
                      size="1.5rem"
                      v-if="sortDirection === 'desc'"
                      class="sort-icon"
                    >
                      mdi-arrow-down-thin
                    </v-icon>
                  </div>
                </div>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="item in paginatedItems" :key="item.id">
              <td v-for="header in headers" :key="header.id">
                <div v-if="headersMatch(header)">
                  <slot :name="header.value" :item="item">
                    <div>
                      {{ item[header.value] ? item[header.value] : "" }}
                    </div>
                  </slot>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="table-footer">
        <slot name="footer">
          <div class="pagination-expression">
            <div
              class="d-flex align-center mr-6"
              @click="goToPreviousPage"
              :class="{ 'previous-disabled': currentPage === 1 }"
            >
              <v-icon :disabled="currentPage === 1">mdi-chevron-left</v-icon>
              <div>PREVIOUS</div>
            </div>

            <div
              v-for="page in paginationRange"
              :key="page"
              class="page-number"
            >
              <span
                class="mr-1"
                :class="{ 'active-page': page === currentPage }"
                @click="goToPage(page)"
                >{{ page }}</span
              >
            </div>

            <div class="d-flex align-center ml-6" @click="goToNextPage">
              <div :class="{ 'next-disabled': isLastPage }">NEXT</div>
              <v-icon :disabled="isLastPage">mdi-chevron-right</v-icon>
            </div>
          </div>
          <div
            class="pagination-params d-flex justify-center align-center mt-1"
          >
            <div
              class="d-flex align-center page-range mr-4"
              v-if="currentPage <= totalPages && totalPages > 5"
            >
              <div class="mr-2">
                <span>Go to: </span>
              </div>
              <div class="mr-2">
                <input
                  type="number"
                  id="page-input"
                  min="1"
                  :value="currentPage"
                  @blur="validatePageInput"
                  @keypress.enter="validatePageInput"
                />
              </div>
              <p class="ma-0 mr-1">of</p>
              <div class="ml-1">
                {{ totalPages }}
              </div>
            </div>
            <div>
              <span class="mr-1">Show rows: </span>
              <span>
                <input
                  type="text"
                  id="page-input"
                  :value="rowsPerPageLocal"
                  readonly
                  @click="isMenuOpen = true"
                />
              </span>
              <v-menu v-model="isMenuOpen" :close-on-content-click="false">
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" class="caret-icon"
                    >mdi-chevron-down</v-icon
                  >
                </template>
                <v-list>
                  <v-list-item
                    v-for="option in rowOptions"
                    :key="option.value"
                    @click="selectRowsPerPage(option.value)"
                  >
                    <v-list-item-title>{{ option.text }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </div>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SearchWidget from "./SearchWidget.vue";
import { informationIcon, popupDelay, rowOptions } from "@/helpers/variables";
import dayjs from "dayjs";

export default {
  name: "DynamicTable",
  components: {
    SearchWidget,
  },
  props: {
    headers: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: false,
      default: () => [],
    },
    items: {
      type: Array,
      required: true,
    },
    hasFooter: {
      type: Boolean,
      required: false,
      default: false,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    rowsPerPage: {
      type: Number,
      required: false,
      default: 5,
    },
    disableSearch: {
      type: Boolean,
      required: false,
      default: false,
    },
    tabSelected: {
      type: String,
      required: false,
      default: null,
    },
    menuActivator: {
      type: String,
      required: false,
      default: null,
    },
    pageType: {
      type: String,
      required: false,
      default: null,
    },
    tableType: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      searchText: "",
      filteredTableItems: [],
      currentPage: 1,
      informationIcon,
      currentSortColumn: null,
      sortDirection: null,
      popupDelay,
      selectedStatuses: ["New", "In progress", "Needs review"],
      isInitialStage: true,
      showUnconfirmedOnly: false,
      rowOptions,
      rowsPerPageLocal: this.rowsPerPage,
      isMenuOpen: false,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
    }),
    filteredItemsBySearch() {
      let result = this.items;

      if (this.searchText) {
        result = this.items.filter((item) => {
          return Object.values(item).some((value) => {
            return String(value)
              .toLowerCase()
              .includes(this.searchText.toLowerCase());
          });
        });
      }

      if (this.tabSelected === "issues" && this.pageType === "turbine") {
        if (this.selectedStatuses.length > 0) {
          result = result.filter((item) => {
            const matchedStatus = this.selectedStatuses.filter((status) => {
              return (
                status.toLowerCase() === item.status.toLowerCase() ||
                (status.toLowerCase() === "unconfirmed" &&
                  item.status.toLowerCase().includes("unconfirmed"))
              );
            });
            if (matchedStatus.length > 0) {
              return item;
            }
          });
        } else {
          return [];
        }
      }

      return result;
    },
    sortedItems() {
      let items = [...this.filteredItemsBySearch];
      if (this.currentSortColumn && this.sortDirection) {
        const dateColumns = ["lastUpdated", "last_updated"];
        items.sort((a, b) => {
          let aValue = a[this.currentSortColumn];
          let bValue = b[this.currentSortColumn];
          // Convert None to null for sorting
          aValue = aValue === "None" ? null : aValue;
          bValue = bValue === "None" ? null : bValue;
          // Look for string and remove dollar signs if exist
          if (typeof aValue === "string" && aValue?.includes("$")) {
            aValue = aValue.replaceAll("$", "");
          }
          if (typeof bValue === "string" && bValue.includes("$")) {
            bValue = bValue.replaceAll("$", "");
          }
          // If its a date column, handle nulls and parse dates
          if (dateColumns.includes(this.currentSortColumn)) {
            if (aValue) aValue = dayjs(aValue);
            if (bValue) bValue = dayjs(bValue);
            // Sorting logic for null values
            if (!aValue && bValue) {
              return this.sortDirection === "desc" ? 1 : -1;
            }
            if (aValue && !bValue) {
              return this.sortDirection === "desc" ? -1 : 1;
            }
            if (!aValue && !bValue) {
              return 0;
            }
            // Compare the values as dates
            return this.sortDirection === "desc"
              ? aValue.diff(bValue)
              : bValue.diff(aValue);
          }
          // Convert to numeric values if necessary
          if (this.isNumeric(aValue)) {
            aValue = parseFloat(aValue);
          }
          if (this.isNumeric(bValue)) {
            bValue = parseFloat(bValue);
          }
          // If the values are strings
          if (typeof aValue === "string" && typeof bValue === "string") {
            aValue = aValue.toLowerCase();
            bValue = bValue.toLowerCase();
            if (aValue < bValue) {
              return this.sortDirection === "desc" ? 1 : -1;
            }
            if (bValue < aValue) {
              return this.sortDirection === "desc" ? -1 : 1;
            }
            return 0;
          }
          // Standard sorting for any other types
          return this.sortDirection === "desc"
            ? (bValue || 0) - (aValue || 0)
            : (aValue || 0) - (bValue || 0);
        });
      }
      return items;
    },
    totalPages() {
      return Math.ceil(this.totalItems / this.rowsPerPageLocal);
    },
    totalItems() {
      return this.sortedItems?.length;
    },
    paginatedItems() {
      if (this.rowsPerPageLocal === "All") {
        return this.sortedItems;
      }
      let startIndex = (this.currentPage - 1) * this.rowsPerPageLocal;
      let endIndex = startIndex + this.rowsPerPageLocal;
      // Using issues array use similar logic as below
      if (this.sortedItems?.length > 0) {
        return this.sortedItems.slice(startIndex, endIndex);
      } else {
        return [];
      }
    },
    paginationRange() {
      if (this.rowsPerPageLocal === "All") {
        return [1];
      }
      let start = Math.max(this.currentPage - 2, 1);
      let end = Math.min(start + 4, this.totalPages);

      if (this.totalPages - this.currentPage < 2) {
        start = Math.max(this.totalPages - 4, 1);
        end = this.totalPages;
      }

      let range = [];
      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      return range;
    },
    isLastPage() {
      if (!this.rowsPerPageLocal || this.sortedItems?.length == 0) {
        return false;
      } else if (
        Math.ceil(this.sortedItems?.length / this.rowsPerPageLocal) ===
        this.currentPage
      ) {
        return true;
      }
      return false;
    },
    totalColumns() {
      return this.headers.length;
    },
    isInternalUser() {
      if (this.userData && Object.keys(this.userData).length > 0) {
        return this.userData.is_internal;
      } else {
        return false;
      }
    },
    statusFilters() {
      let filters = [
        {
          id: 2,
          text: "New",
          value: "new",
        },
        {
          id: 3,
          text: "In progress",
          value: "in_progress",
        },
        {
          id: 4,
          text: "Needs review",
          value: "needs_review",
        },
        {
          id: 5,
          text: "Fixed",
          value: "fixed",
        },
        {
          id: 6,
          text: "Superseded",
          value: "superseded",
        },
        {
          id: 7,
          text: "Deferred",
          value: "deferred",
        },
        {
          id: 8,
          text: "Dismissed",
          value: "dismissed",
        },
      ];

      if (this.isInternalUser) {
        filters.push({
          id: 9,
          text: "Dismissed (internal)",
          value: "dismissed_internal",
        });
        filters.push({
          id: 1,
          text: "Unconfirmed",
          value: "unconfirmed",
        });
      }

      return filters;
    },
    visibleHeaders() {
      return this.headers;
    },
    selectedColumns() {
      return this.selectedHeader;
    },
    noItemsText() {
      return this.tabSelected === "issues" || this.tabSelected === "next issue"
        ? "No issues found"
        : "No data available";
    },
  },
  methods: {
    filterBySearch(event) {
      this.searchText = event;
    },
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber && pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
    validatePageInput(event) {
      const pageValue = parseInt(event.target.value);
      if (pageValue && Number.isInteger(pageValue)) {
        this.goToPage(pageValue);
      }
    },
    // Call this on mount
    setPageByUrl() {
      const urlParams = new URLSearchParams(window.location.search);
      const page = urlParams.get("page");
      if (page) {
        this.currentPage = parseInt(page);
      }
    },
    // Call this on page change
    setUrlParamsByPage() {
      const url = new URL(window.location.href);
      url.searchParams.set("page", this.currentPage);
      history.replaceState(null, null, url);
    },
    handleSort(header) {
      if (header.not_sortable) {
        return;
      }
      if (this.currentSortColumn === header.value) {
        // Toggle sort direction
        this.sortDirection = this.sortDirection === "desc" ? "asc" : "desc";
      } else {
        this.currentSortColumn = header.value;
        this.sortDirection = "desc";
      }
    },
    isNumeric(value) {
      if (typeof value === "number") {
        return true;
      }
      if (typeof value === "string") {
        const parsedValue = parseFloat(value);
        return !isNaN(parsedValue) && value.trim() !== "";
      }
      return false;
    },
    selectStatusFilters(status) {
      if (status === "selectAll") {
        if (this.selectedStatuses.length === this.statusFilters.length) {
          this.selectedStatuses = [];
          return;
        }
        this.selectedStatuses = this.statusFilters.map((status) => status.text);
        return;
      }
      if (status === "reset") {
        this.selectedStatuses = ["New", "In progress", "Needs review"];
        return;
      }
      let statusName = status.text.toLowerCase();
      let matchedStatus = this.selectedStatuses.filter(
        (item) => item.toLowerCase() === statusName,
      );
      if (matchedStatus.length > 0) {
        this.selectedStatuses = this.selectedStatuses.filter(
          (item) => item.toLowerCase() !== statusName,
        );
      } else {
        this.selectedStatuses.push(status.text);
      }
    },
    clearSelectedItems() {
      this.selectedStatuses = [];
    },
    headersMatch(header) {
      const matchedHeader = this.headers.filter((item) => {
        return item.id === header.id;
      });
      if (matchedHeader.length > 0) {
        return true;
      }
      return false;
    },
    toggleIssueTypesUnconfirmed() {
      // Emit to parent the value of the toggle
      this.$emit("toggleIssueTypesUnconfirmed", this.showUnconfirmedOnly);
    },
    selectRowsPerPage(option) {
      const newValue = option === "All" ? "All" : option;
      this.rowsPerPageLocal = newValue;
      this.isMenuOpen = false;
      this.currentPage = 1;
    },
  },
  mounted() {
    this.setPageByUrl();
  },
  watch: {
    rowsPerPage: {
      immediate: true,
      handler(newValue) {
        this.rowsPerPageLocal = newValue;
      },
    },
    tableType: {
      immediate: true,
      handler(oldValue, newValue) {
        if (
          (this.pageType === "home" &&
            oldValue === "site" &&
            newValue === "oem") ||
          (oldValue === "oem" && newValue === "site")
        ) {
          this.currentPage = 1;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.table-container {
  position: relative;
  min-height: 150px;
  padding: 1rem 1rem 0.25rem 1rem;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.status-filter-wrapper,
.column-filter-wrapper {
  padding-top: 0.4rem;
}

.menu-items-container {
  display: flex;
  flex-direction: column;
  background-color: var(--v-searchBackground-base);
  border-radius: 8px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);
  z-index: 100;
  min-width: 200px;

  .menu-item {
    padding: 0.5rem 1rem 0.5rem 1rem;
    cursor: pointer;
    height: 40px;
  }
  .menu-item:hover {
    background-color: var(--v-hover-base);
  }
  p {
    color: var(--v-text-base);
  }
}
.select-action {
  font-size: 0.85rem;
  cursor: pointer;
}
.select-all-title {
  margin-right: 1.5rem;
}
.select-clear-title {
  margin-left: 1.5rem;
}
.select-all-title:hover,
.select-clear-title:hover,
.select-reset-title:hover {
  color: var(--v-primary-base);
}

.any-label {
  margin-right: 13px;
}

.dynamic-table {
  width: 100%;
  min-height: 100px;
  table {
    border-collpase: collapse;
  }
  th,
  td {
    text-align: left;
    padding: 8px 8px 8px 1rem;
  }
  th {
    padding-bottom: 0.75rem;
    border-bottom: 1px solid var(--v-converterBorders-base); // Change to rgb(var(--v-theme-mediumGray))
    border-right: 1px solid var(--v-converterBorders-base);
    font-family: "Inter", sans-serif;
  }
  th:last-child {
    border-right: none;
  }
  th:hover {
    cursor: pointer;
  }
  tr {
    border-bottom: 1px dotted var(--v-converterBorders-base); // Change to rgb(var(--v-theme-mediumGray))
  }
  // This controls row highlighting on hover
  tbody tr:hover {
    background-color: var(--v-hover-base);
  }
  tr td {
    border-right: 1px solid var(--v-converterBorders-base);
  }
  tr td:last-child {
    border-right: none;
  }
}
.table-footer tr,
.table-footer tr td {
  border: none;
}
.table-footer td {
  padding-top: 1rem;
}

.no-items-text {
  font-size: 1rem;
}

.pagination-expression {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.page-number {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
}
.active-page {
  font-weight: bold;
  color: var(--v-primary-base);
}
#page-input {
  outline: none;
  border: 1px solid var(--v-converterBorders-base);
  border-radius: 4px;
  width: 2.5rem;
  text-align: center;
  color: var(--v-text-base);
}
#page-input::-webkit-outer-spin-button,
#page-input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}
#page-input[type="number"] {
  -moz-appearance: textfield;
}
.previous-disabled,
.next-disabled {
  color: var(--v-gray-base);
}

.help-icon-color {
  color: var(--v-text-base);
}

.flex-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
}

.table-footer {
  align-self: center;
  margin: 0.75rem 0 0.5rem 0;
}
.caret-icon {
  cursor: pointer;
}
</style>
