<template>
  <div class="maintenance-card-container">
    <!-- HEADER -->
    <div class="header-container d-flex justify-space-between">
      <div>
        <p class="ma-0">{{ siteName }}</p>
        <h4 class="header">Maintenance</h4>
      </div>
      <!-- Hook up to expanded version when fleshed out -->
      <!-- <div>
        <v-icon
          size="1.5rem"
          class="pointer icon-hover"
          >mdi-arrow-expand-all</v-icon
        >
      </div> -->
    </div>
    <div class="maintenance-items pb-6" v-if="maintenanceData.length > 0">
      <div class="maintenance-item" v-for="item in mods" :key="item.id">
        <div class="d-flex justify-space-between">
          <p>{{ item.turbine_mod_def_name }}</p>
          <p>{{ item.ts }}</p>
        </div>
      </div>
    </div>
    <div v-else class="d-flex justify-center">
      <h6>No maintenance data available</h6>
    </div>
    <div class="d-flex justify-space-between align-end updated-row">
      <p class="updated-text ma-0" v-if="lastUpdatedDate">
        Updated {{ lastUpdatedDate }}
      </p>
      <!-- <div>
        <button class="maintenance-button" @click="goToAllMaintenance">
          EXPLORE ALL MAINTENANCE
        </button>
      </div> -->
    </div>
  </div>
</template>

<script>
export default {
  name: "ConverterMaintenanceCard",
  props: {
    maintenanceData: {
      type: Array,
      required: false,
      default: () => [],
    },
    siteName: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  computed: {
    mods() {
      return this.maintenanceData;
    },
    lastUpdatedDate() {
      if (this.mods.length > 0) {
        const updatedDate = this.mods[0].ts.split(" ")[0];
        return updatedDate;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.maintenance-card-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 1rem;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
}

.header-container {
  p {
    font:
      0.75rem "Museo Sans Rounded",
      sans-serif;
    color: var(--v-text-base);
    font-weight: 600;
  }
}

.maintenance-items {
  max-height: 250px;
  p {
    margin: 0;
  }
}

.maintenance-item {
  border-bottom: 1px dotted var(--v-converterBorders-base);
  padding: 0.75rem 0;
}

.maintenance-button {
  border: 1.75px solid var(--v-inverse-base);
  border-radius: 3rem;
  padding: 0.25rem 0.75rem 0.25rem 0.75rem;
}
.maintenance-button:hover {
  background-color: var(--v-converterBorders-base);
}
</style>
