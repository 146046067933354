<template>
  <div class="filter-container">
    <v-menu
      :activator="menuActivator"
      :close-on-content-click="false"
      left
      top
      offset-x
      nudge-left="15"
    >
      <div class="header-items-container">
        <div class="d-flex justify-center pt-3 mb-2">
          <b
            class="select-action select-all-title text-decoration-underline"
            @click="selectColumnFilters('selectAll')"
          >
            Select all
          </b>
          <b
            class="select-action select-reset-title text-decoration-underline"
            @click="selectColumnFilters('reset')"
          >
            Reset
          </b>
          <b
            class="select-action select-clear-title text-decoration-underline"
            @click="selectColumnFilters('clear')"
          >
            Clear
          </b>
        </div>
        <div v-for="header of headers" :key="header.id" class="menu-list">
          <div
            class="d-flex justify-space-between align-center menu-item"
            @click="selectColumnFilters(header)"
          >
            <p class="mb-0 mr-2">{{ nameOfHeader(header) }}</p>
            <v-icon color="green" v-if="headersMatch(header)">mdi-check</v-icon>
          </div>
        </div>
      </div>
    </v-menu>
  </div>
</template>

<script>
export default {
  name: "TableHeaderFilter",
  props: {
    headers: {
      type: Array,
      required: true,
    },
    selectedHeaders: {
      type: Array,
      required: false,
      default: () => [],
    },
    menuActivator: {
      type: String,
      required: true,
    },
    attachClass: {
      type: String,
      required: false,
      default: "",
    },
  },
  methods: {
    nameOfHeader(header) {
      if (typeof header.text === "string") {
        return header.text;
      } else if (Array.isArray(header.text)) {
        // Use reduce to concatenate the text values of the header.text array
        const headerText = header.text.reduce((acc, curr) => {
          return acc + " " + curr;
        }, "");
        return headerText;
      } else if (typeof header.text === "object") {
        // Chech the text object for an icon property, if it exists, return header.text[header.value]
        if (header.text.icon) {
          return header.text[header.value];
        } else {
          // Return the concatenated phrase from the value of the header.text property values
          return Object.values(header.text).reduce((acc, curr) => {
            return acc + " " + curr;
          }, "");
        }
      }
    },
    selectColumnFilters(column) {
      if (column === "selectAll") {
        this.$emit("changeHeaders", "selectAll");
        return;
      }
      if (column === "reset") {
        this.$emit("changeHeaders", "reset");
        return;
      }
      if (column === "clear") {
        this.$emit("changeHeaders", "clear");
        return;
      }
      this.$emit("changeHeaders", column);
    },
    headersMatch(header) {
      const matchedHeader = this.selectedHeaders.filter((column) => {
        return column.id === header.id;
      });
      if (matchedHeader.length > 0) {
        return true;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-container {
  position: relative;
}
.header-items-container {
  background-color: var(--v-search-base);
  min-width: 250px;
  padding-bottom: 0.5rem;
}
.select-action {
  font-size: 0.85rem;
  cursor: pointer;
}
.select-all-title {
  margin-right: 1rem;
}
.select-clear-title {
  margin-left: 1rem;
}
.select-all-title:hover,
.select-clear-title:hover,
.select-reset-title:hover {
  color: var(--v-primary-base);
}

.menu-item {
  padding: 0 1rem 0 1rem;
  cursor: pointer;
  height: 40px;
}
.menu-item:hover {
  background-color: var(--v-hover-base);
}
</style>
