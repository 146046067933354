<template>
  <div>
    <div v-if="residuals" class="residuals-container">
      <div class="d-flex justify-center align-center">
        <span class="plot-heading">Risk model and associated events</span>
      </div>
      <Plotly
        :data="residuals.data"
        :layout="residuals.layout"
        :responsive="true"
        :showTips="false"
      ></Plotly>
    </div>
    <div v-else class="no-data1 d-flex justify-center align-center">
      <div
        class="d-flex justify-center align-center no-data1__spinner"
        v-if="loading"
      >
        <v-progress-circular
          :size="45"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <p v-else>NO DATA AVAILABLE</p>
    </div>
  </div>
</template>

<script>
import Plotly from "@/components/Plotly";

export default {
  name: "RotorShaftAnalyticsFigures",
  components: {
    Plotly,
  },
  data() {
    return {};
  },
  props: {
    analyticsFiguresData: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    analyticsFigures() {
      if (this.analyticsFiguresData) {
        return this.analyticsFiguresData;
      }
      return null;
    },
    residuals() {
      if (this.analyticsFigures && this.analyticsFigures.residuals) {
        let residualsPlot = this.analyticsFigures.residuals;
        residualsPlot.layout.height = 568;
        residualsPlot.layout.margin = { l: 80, b: 30, r: 40, t: 30 };
        return residualsPlot;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.residuals-container {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  padding: 0.5rem 0.5rem;
  height: 608px;
}
.no-data1 {
  position: relative;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  padding: 0.5rem;
  height: 608px;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}
</style>
