<template>
  <div>
    <div class="loader" v-if="loadingAnalytics">
      <DataLoading />
    </div>
    <div v-else>
      <section id="controls">
        <v-form v-model="formValid">
          <v-row>
            <v-col cols="4" class="analytics-col min-length">
              <div class="d-flex">
                <div
                  class="view-wrapper"
                  :class="{ 'error-highlight-view': !isViewValid }"
                >
                  <v-autocomplete
                    ref="viewSelection"
                    content-class="pb-0"
                    v-model="selectedView"
                    :items="views"
                    label="Select a view"
                    hide-no-data
                    hide-selected
                    dense
                    solo
                    :rules="selectedViewRules"
                    @change="changeViewSelected"
                    @focus="clearError('view')"
                    @blur="handleBlurViewSelection"
                  ></v-autocomplete>
                </div>
              </div>
            </v-col>
            <v-col
              cols="4"
              v-if="
                selectedView === 'yaw_misalignment' ||
                selectedView === 'minimum_pitch'
              "
              class="analytics-col"
            >
              <div :class="{ 'error-highlight-turbines': !isTurbinesValid }">
                <v-combobox
                  ref="turbineCombobox"
                  v-model="selectedTurbines"
                  :items="turbineOptions"
                  label="Turbine selection"
                  placeholder="Turbine selection"
                  multiple
                  clearable
                  solo
                  dense
                  :rules="selectedTurbinesRules"
                  @focus="clearError('turbines')"
                  @blur="callAnalyticsAPI"
                >
                  <template v-slot:selection="{ item, index }">
                    <div class="d-flex no-wrap">
                      <span v-if="!turbinesOnly && index === 0"
                        >All turbines</span
                      >
                      <span v-else-if="turbinesOnly"
                        >{{ item.text
                        }}<span v-if="index !== selectedTurbines.length - 1"
                          >,&nbsp;</span
                        >
                      </span>
                    </div>
                  </template>
                </v-combobox>
              </div>
            </v-col>
          </v-row>
          <div
            class="d-flex mt-5 align-center"
            v-if="
              selectedView !== 'mod_window_suitability' &&
              selectedView !== 'events'
            "
          >
            <div class="d-flex flex-wrap align-center error-anchor">
              <div class="error-msg">{{ dateError }}</div>
              <div class="d-flex mr-7 date-picker">
                <div class="mr-7">
                  <date-picker
                    type="date"
                    placeholder="Start date"
                    v-model="dateRange.startDate"
                    :class="{
                      'error-highlight': !isStartDateValid,
                    }"
                    :clearable="false"
                    @focus="clearError('date')"
                  >
                    <template v-slot:content="slotProps">
                      <calendar-panel
                        :disabled-date="(date) => date > dateRange.endDate"
                        :value="slotProps.value"
                        @select="slotProps.emit"
                      ></calendar-panel>
                    </template>
                  </date-picker>
                </div>
                <div>
                  <date-picker
                    type="date"
                    placeholder="End date"
                    v-model="dateRange.endDate"
                    class="mr-2"
                    :class="{
                      'error-highlight': !isEndDateValid,
                    }"
                    :clearable="false"
                    @focus="clearError('date')"
                  >
                    <template v-slot:content="slotProps">
                      <calendar-panel
                        :disabled-date="(date) => date > new Date()"
                        :value="slotProps.value"
                        @select="slotProps.emit"
                      ></calendar-panel>
                    </template>
                  </date-picker>
                </div>
              </div>
              <div
                class="yaw-adjustments-switch"
                v-if="this.selectedView === 'yaw_misalignment'"
              >
                <label class="mr-2 mb-0">Show adjustments</label>
                <v-switch v-model="showAdjustments" />
              </div>
            </div>
          </div>
        </v-form>
      </section>
      <div v-if="imageLoading" class="mt-10 loader">
        <DataLoading />
      </div>
      <section id="display" class="mt-9" v-else>
        <v-card>
          <div class="analytics-display">
            <div v-if="analyticsFigure">
              <Plotly
                ref="plotlyDiagram"
                id="plotly_diagram"
                :data="yawMisalignmentFigureData"
                :layout="analyticsFigure.figure.layout"
              ></Plotly>
            </div>
            <div
              class="no-data-backdrop no-data-available d-flex justify-center align-center"
              v-else
            >
              NO DATA AVAILABLE
            </div>
          </div>
        </v-card>
        <div
          class="pb-5 d-flex pt-6 request-btn-wrapper"
          v-if="analyticsFigure"
        >
          <div class="pointer">
            <v-menu :open-delay="popupDelay" open-on-hover offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-on="on"
                  v-bind="attrs"
                  size="1.25rem"
                  class="info-icon"
                  >{{ informationIcon }}</v-icon
                >
              </template>
              <v-card>
                <v-card-text>
                  {{ analyticsFigure.figure_description }}
                </v-card-text>
              </v-card>
            </v-menu>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import DatePicker from "vue2-datepicker";
const { CalendarPanel } = DatePicker;
import { mapActions, mapState } from "vuex";
import DataLoading from "@/components/DataLoading";
import Plotly from "@/components/Plotly";
import { compareTwoArraysForEquality } from "@/helpers/functions";
import {
  informationIcon,
  popupDelay,
  clickCountLimit,
} from "@/helpers/variables";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";

const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);

export default {
  name: "NewAnalyticsView",
  components: {
    DatePicker,
    CalendarPanel,
    DataLoading,
    Plotly,
  },
  props: {
    turbineData: {
      type: Array,
      default: () => [],
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    issueCreatedTs: {
      type: String,
      required: false,
      default: null,
    },
    issueStartDate: {
      type: String,
      required: false,
      default: null,
    },
    issueEndDate: {
      type: String,
      required: false,
      default: null,
    },
    pageTitle: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      selectedView: "",
      selectedTurbines: [],
      views: [
        { text: "Yaw misalignment", value: "yaw_misalignment" },
        { text: "Minimum pitch", value: "minimum_pitch" },
        { text: "Wind rose", value: "wind_rose" },
        { text: "Mod window suitability", value: "mod_window_suitability" },
        { text: "Power curve", value: "power_curve_explorer" },
        { text: "Events", value: "events" },
      ],
      selectedViewRules: [],
      selectedTurbinesRules: [],
      date: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      openDescription: false,
      dialogHover: false,
      analyticsDescription: "Description not yet available.",
      formValid: false,
      isStartDateValid: true,
      isEndDateValid: true,
      dateError: "",
      isViewValid: true,
      isTurbinesValid: true,
      turbineOptions: [],
      staticViewRequest: {},
      previousSelected: [],
      allOptionChanged: false,
      informationIcon,
      popupDelay,
      showAdjustments: true,
      isInitial: true,
    };
  },
  computed: {
    ...mapState({
      yawMisalignmentAnalytics: (state) => state.site.yawMisalignmentAnalytics,
      minPitchAnalytics: (state) => state.site.minPitchAnalytics,
      windRoseAnalytics: (state) => state.site.windRoseAnalytics,
      modWindowSuitabilityAnalytics: (state) =>
        state.site.modWindowSuitabilityAnalytics,
      issueData: (state) => state.issueDetail.issueData,
      targetTurbine: (state) => state.turbine.turbineData,
      loading: (state) => state.site.loading,
      siteTurbineIds: (state) => state.site.turbineIds,
      userData: (state) => state.user.userData,
      clickCount: (state) => state.app.clickCount,
    }),
    yawMisalignment() {
      if (
        this.yawMisalignmentAnalytics &&
        Object.keys(this.yawMisalignmentAnalytics).length > 0
      ) {
        return this.yawMisalignmentAnalytics;
      } else {
        return null;
      }
    },
    minPitch() {
      if (
        this.minPitchAnalytics &&
        Object.keys(this.minPitchAnalytics).length > 0
      ) {
        return this.minPitchAnalytics;
      } else {
        return null;
      }
    },
    windRose() {
      if (
        this.windRoseAnalytics &&
        Object.keys(this.windRoseAnalytics).length > 0
      ) {
        return this.windRoseAnalytics;
      } else {
        return null;
      }
    },
    modWindowSuitability() {
      if (
        this.modWindowSuitabilityAnalytics &&
        Object.keys(this.modWindowSuitabilityAnalytics).length > 0
      ) {
        return this.modWindowSuitabilityAnalytics;
      } else {
        return null;
      }
    },
    analyticsFigure() {
      switch (this.selectedView) {
        case "yaw_misalignment":
          return this.setAnalyticsFigure(this.yawMisalignment);
        case "minimum_pitch":
          return this.setAnalyticsFigure(this.minPitch);
        case "wind_rose":
          return this.setAnalyticsFigure(this.windRose);
        case "mod_window_suitability":
          return this.setAnalyticsFigure(this.modWindowSuitability);
        default:
          return null;
      }
    },
    imageLoading() {
      if (
        this.loading?.getYawMisalignmentAnalytics ||
        this.loading?.getMinPitchAnalytics ||
        this.loading?.getWindRoseAnalytics ||
        this.loading?.getModWindowSuitabilityAnalytics
      ) {
        return true;
      } else {
        return false;
      }
    },
    turbinesOnly() {
      for (const item of this.selectedTurbines) {
        if (item.text === "All turbines") {
          return false;
        }
      }
      return true;
    },
    viewStartDate() {
      if (this.isDateValid(this.dateRange.startDate)) {
        return dayjs.utc(this.dateRange.startDate).format("YYYY-MM-DD");
      }
      return this.dateRange.startDate;
    },
    viewEndDate() {
      if (this.isDateValid(this.dateRange.endDate)) {
        return dayjs.utc(this.dateRange.endDate).format("YYYY-MM-DD");
      }
      return this.dateRange.endDate;
    },
    turbineIdsFromTurbineData() {
      if (
        this.turbineData.length &&
        this.turbineData[0].farm_id == this.siteId
      ) {
        const turbineIdArr = [];
        for (const item of this.turbineData) {
          turbineIdArr.push(item.id);
        }
        return turbineIdArr;
      } else {
        return [];
      }
    },
    defaultDateRange() {
      const today = dayjs().utc();
      const thirtyDaysAgo = today.subtract(30, "day");
      let startDate = this.issueStartDate
        ? dayjs(this.issueStartDate).utc().startOf("day")
        : null;
      let endDate = this.issueEndDate
        ? dayjs(this.issueEndDate).utc().startOf("day")
        : today;
      if (!startDate) {
        startDate = thirtyDaysAgo;
      }

      return {
        start_date: startDate.toDate(),
        end_date: endDate.toDate(),
      };
    },
    yawMisalignmentFigureData() {
      if (this.showAdjustments) {
        return this.analyticsFigure.figure.data;
      }
      return this.analyticsFigure.figure.data.filter(
        // Only adjustment plots have a dash so that is the metric for determining
        (plot) => plot.line.dash !== "dash",
      );
    },
    loadingAnalytics() {
      if (
        this.loading.fetchIssueDetailData ||
        this.loading.getTurbinesBySiteId
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    ...mapActions({
      getYawMisalignmentAnalytics: "site/getYawMisalignmentAnalytics",
      getModWindowSuitabilityAnalytics: "site/getModWindowSuitabilityAnalytics",
      getWindRoseAnalytics: "site/getWindRoseAnalytics",
      getMinPitchAnalytics: "site/getMinPitchAnalytics",
      clearAnalytics: "site/clearAnalytics",
      incrementClickCount: "app/incrementClickCount",
    }),
    setInitialTurbineDropdownOptions() {
      let initialTurbineIds = [];
      const options = this.turbineData.map((turbine) => ({
        text: turbine.turbine_name,
        value: turbine.id,
      }));
      options.unshift({
        text: "All turbines",
        value: "all_turbines",
      });
      this.turbineOptions = options;
      // The initial turbine ids are set depending on the page title
      if (this.pageTitle === "Issue detail") {
        initialTurbineIds.push(this.issueData.turbine_id);
      } else if (this.pageTitle === "newTurbineDashboard") {
        const turbineId = parseInt(this.$route.params.turbineId);
        initialTurbineIds.push(turbineId);
      } else if (
        this.pageTitle === "mainBearingDetail" &&
        this.targetTurbine &&
        Object.keys(this.targetTurbine).length > 0
      ) {
        initialTurbineIds.push(this.targetTurbine.id);
      } else {
        initialTurbineIds.push(options[1].value);
      }

      return initialTurbineIds;
    },
    setUrlParamsForNavigation(mode) {
      const url = new URL(window.location.href);
      let view = url.searchParams.get("analytics_view");
      // Leave component and show power curve explorer component
      if (view === "power_curve_explorer") {
        this.$emit("openPowerCurveExplorer", true);
        return;
      }
      if (view === "events") {
        this.$emit("openEventsView", true);
        return;
      }
      if (mode === "initial") {
        this.isInitial = false;
      }
      const initialTurbineIds = this.setInitialTurbineDropdownOptions();

      let defaultStartDate = null;
      let defaultEndDate = null;
      defaultStartDate = dayjs
        .utc(this.defaultDateRange.start_date)
        .format("YYYY-MM-DD");
      defaultEndDate = dayjs
        .utc(this.defaultDateRange.end_date)
        .format("YYYY-MM-DD");

      // Run this code block if this is a fresh load of the page
      if (mode === "initial" && !url.searchParams.get("start_date")) {
        this.dateRange.startDate = this.defaultDateRange.start_date;
      }
      if (mode === "initial" && !url.searchParams.get("end_date")) {
        this.dateRange.endDate = this.defaultDateRange.end_date;
      }

      const staticRequest = {
        site_id: this.siteId,
        view: "yaw_misalignment",
        turbine_ids: initialTurbineIds,
        start_date: defaultStartDate,
        end_date: defaultEndDate,
      };
      // Emit view to parent for url search params
      this.$emit("setStaticViewRequest", staticRequest);

      if (url.search) {
        // Checking all search params and filling in those that are empty
        let startDate = url.searchParams.get("start_date");
        let endDate = url.searchParams.get("end_date");
        if (url.searchParams.get("analytics_view")) {
          this.selectedView = url.searchParams.get("analytics_view");
        } else {
          this.selectedView = staticRequest.view;
          url.searchParams.set("analytics_view", staticRequest.view);
        }
        if (startDate) {
          const date = new Date(startDate);
          const offset = date.getTimezoneOffset();
          const convertedDate = new Date(date.getTime() + offset * 60 * 1000);
          this.dateRange.startDate = convertedDate;
        } else {
          this.dateRange.startDate = new Date(staticRequest.start_date);
          url.searchParams.set(
            "start_date",
            dayjs(this.dateRange.startDate).format("YYYY-MM-DD"),
          );
        }
        if (endDate) {
          const date = new Date(endDate);
          const offset = date.getTimezoneOffset();
          const convertedDate = new Date(date.getTime() + offset * 60 * 1000);
          this.dateRange.endDate = convertedDate;
        } else {
          this.dateRange.endDate = new Date(staticRequest.end_date);
          url.searchParams.set(
            "end_date",
            dayjs(this.dateRange.endDate).format("YYYY-MM-DD"),
          );
        }
        // If there are turbine ids in the url search params
        if (url.searchParams.get("turbine_id")) {
          const turbineIds = url.searchParams.getAll("turbine_id");
          // Parse ids from strings to int because url search params are always stringified
          let convertedTurbineIds = [];
          for (const item of turbineIds) {
            convertedTurbineIds.push(parseInt(item));
          }
          const areArraysIdentical =
            this.turbineIdsFromTurbineData.length ===
              convertedTurbineIds.length &&
            JSON.stringify(this.turbineIdsFromTurbineData) ===
              JSON.stringify(convertedTurbineIds);
          // If the turbine ids from the url search are identical to all turbine options, all turbines are selected
          if (
            areArraysIdentical &&
            this.turbineOptions.length ===
              this.turbineIdsFromTurbineData.length + 1
          ) {
            this.selectedTurbines = this.turbineOptions;
          } else {
            // If not, then the turbines from the url params are selected
            let tempTurbineOptions = [];
            const stringifiedTurbineIds = JSON.stringify(turbineIds);
            for (const item of this.turbineOptions) {
              if (stringifiedTurbineIds.includes(item.value)) {
                tempTurbineOptions.push(item);
              }
            }
            this.selectedTurbines = tempTurbineOptions;
          }
        } else {
          if (this.pageTitle === "Issue detail") {
            // If there were no turbines in the url search params, the issue data turbine is default
            this.selectedTurbines = this.turbineOptions.filter(
              (option) => option.value === this.issueData.turbine_id,
            );
          } else if (this.pageTitle === "newTurbineDashboard") {
            const turbineId = parseInt(this.$route.params.turbineId);
            // If there were no turbines in the url search params, the turbine id from the route params is default
            this.selectedTurbines = this.turbineOptions.filter(
              (option) => option.value === turbineId,
            );
          } else if (this.pageTitle === "mainBearingDetail") {
            // If there were no turbines in the url search params, the target turbine is default
            this.selectedTurbines = this.turbineOptions.filter(
              (option) => option.value === this.targetTurbine.id,
            );
          } else if (
            this.pageTitle === "Site dashboard" ||
            this.pageTitle === "allSiteComponents"
          ) {
            // If there were no turbines in the url search params, all turbines are default
            this.selectedTurbines = [...this.turbineOptions];
          } else {
            // Index 0 is all turbines so choosing the second indice for the first single turbine
            this.selectedTurbines.push(this.turbineOptions[1]);
          }
        }
        // Call for the details of a chosen analytics view
        this.callAnalyticsAPI();
      } else {
        // When there are no url search params at all
        url.searchParams.set("analytics_view", "yaw_misalignment");
        this.selectedView = "yaw_misalignment";
        url.searchParams.set(
          "start_date",
          dayjs.utc(this.defaultDateRange.start_date).format("YYYY-MM-DD"),
        );
        url.searchParams.set(
          "end_date",
          dayjs.utc(this.defaultDateRange.end_date).format("YYYY-MM-DD"),
        );
        // If issue detail page, the turbine id from the issue data is default
        if (this.turbineData.length > 0 && this.pageTitle === "Issue detail") {
          for (const turbine of this.turbineData) {
            if (turbine.id === this.issueData.turbine_id) {
              url.searchParams.set("turbine_id", this.issueData.turbine_id);
            }
          }
          this.selectedTurbines = this.turbineOptions.filter((turbine) => {
            turbine.value === this.issueData.turbine_id;
          });
        } else if (
          this.turbineData.length > 0 &&
          this.pageTitle === "newTurbineDashboard"
        ) {
          const turbineId = parseInt(this.$route.params.turbineId);
          for (const turbine of this.turbineData) {
            if (turbine.id === turbineId) {
              url.searchParams.set("turbine_id", turbine.id);
            }
          }
          for (const turbine of this.turbineOptions) {
            if (turbine.value === turbineId) {
              this.selectedTurbines.push(turbine);
            }
          }
        } else if (this.pageTitle === "mainBearingDetail") {
          // If main bearing page, the target turbine is default
          for (const turbine of this.turbineOptions) {
            if (turbine.value === this.targetTurbine.id) {
              url.searchParams.set("turbine_id", this.targetTurbine.id);
              this.selectedTurbines.push(turbine);
              break;
            }
          }
        } else if (this.pageTitle === "Site dashboard") {
          if (this.turbineData.length > 0) {
            for (const turbine of this.turbineData) {
              url.searchParams.append("turbine_id", turbine.id);
            }
          }
          this.selectedTurbines = [...this.turbineOptions];
        } else {
          // If not issue detail nor main bearing, the second turbine is default because the first is all turbines string
          this.selectedTurbines.push(this.turbineOptions[1]); // First turbine of turbineOptions
          url.searchParams.set("turbine_id", this.turbineOptions[1].value);
        }

        if (mode === "initial") {
          this.getAnalyticsView("yaw_misalignment", staticRequest);
        }
      }

      history.replaceState(null, null, url);
    },
    changeViewSelected(event) {
      const url = new URL(window.location.href);
      url.searchParams.set("analytics_view", event);
      this.$emit("viewChanged", event);
      if (event) {
        this.clearError("view");
      }
      if (event === "mod_window_suitability" || event === "wind_rose") {
        if (url.searchParams.get("turbine_id")) {
          url.searchParams.getAll("turbine_id").forEach(() => {
            url.searchParams.delete("turbine_id");
          });
        }
        if (event === "mod_window_suitability") {
          if (url.searchParams.get("start_date")) {
            url.searchParams.delete("start_date");
          }
          if (url.searchParams.get("end_date")) {
            url.searchParams.delete("end_date");
          }
        }
        if (event === "wind_rose") {
          if (!url.searchParams.get("start_date") && this.viewStartDate) {
            url.searchParams.set("start_date", this.viewStartDate);
          }
          if (!url.searchParams.get("end_date") && this.viewEndDate) {
            url.searchParams.set("end_date", this.viewEndDate) &&
              this.viewEndDate;
          }
        }
      } else if (event === "yaw_misalignment" || event === "minimum_pitch") {
        if (!url.searchParams.get("start_date") && this.viewStartDate) {
          url.searchParams.set("start_date", this.viewStartDate);
        } else if (!url.searchParams.get("start_date")) {
          url.searchParams.set(
            "start_date",
            dayjs.utc(this.defaultDateRange.start_date).format("YYYY-MM-DD"),
          );
        }

        if (!url.searchParams.get("end_date") && this.viewEndDate) {
          url.searchParams.set("end_date", this.viewEndDate);
        } else if (!url.searchParams.get("end_date")) {
          url.searchParams.set(
            "end_date",
            dayjs.utc(this.defaultDateRange.end_date).format("YYYY-MM-DD"),
          );
        }
        if (
          !url.searchParams.get("turbine_id") &&
          url.hash === "#site_analytics"
        ) {
          if (this.selectedTurbines.length > 0) {
            for (const item of this.selectedTurbines) {
              url.searchParams.append("turbine_id", item.value);
            }
          } else {
            for (const item of this.turbineIdsFromTurbineData) {
              if (item !== "all_turbines") {
                url.searchParams.append("turbine_id", item);
              }
            }
            setTimeout(() => {
              this.selectedTurbines = [...this.turbineOptions];
            }, 250);
          }
        }
      } else if (event === "power_curve_explorer") {
        url.search = "";
        url.searchParams.set("analytics_view", "power_curve_explorer");
        if (this.pageTitle === "Issue detail") {
          url.searchParams.set("target_turbine", this.issueData.turbine_id);
        } else if (this.pageTitle === "mainBearingDetail") {
          url.searchParams.set("target_turbine", this.targetTurbine.id);
        } else if (this.pageTitle === "newTurbineDashboard") {
          url.searchParams.set(
            "target_turbine",
            parseInt(this.$route.params.turbineId),
          );
        } else if (
          this.pageTitle === "Site dashboard" ||
          this.pageTitle === "allSiteComponents"
        ) {
          url.searchParams.set("target_turbine", this.turbineOptions[1].value);
        }
        this.$emit("openPowerCurveExplorer", true);
      } else if (event === "events") {
        url.search = "";
        url.searchParams.set("analytics_view", "events");
        this.$emit("openEventsView", true);
      }
      // Data sent to Google Analytics
      const eventLabel = `analytics_view_selected_${event}`;
      if (this.clickCount < clickCountLimit) {
        this.incrementClickCount();
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: eventLabel.toLowerCase(),
          value: this.clickCount,
        });
      }

      history.replaceState(history.state, null, url);
      // Empty the figure
      this.clearAnalytics();
      this.callAnalyticsAPI();
      this.$refs.viewSelection.blur();
    },
    setDateUrlParams(date, type) {
      const url = new URL(window.location.href);
      const tempDate = new Date(date);
      const offset = tempDate.getTimezoneOffset();
      const newDate = new Date(tempDate.getTime() + offset * 60 * 1000);
      if (
        type === "start_date" &&
        newDate &&
        newDate < this.dateRange.endDate
      ) {
        url.searchParams.set("start_date", date);
      } else if (
        type === "end_date" &&
        newDate &&
        newDate > this.dateRange.startDate
      ) {
        url.searchParams.set("end_date", date);
      }

      history.replaceState(null, null, url);
      if (this.siteId) {
        this.callAnalyticsAPI();
      }
    },
    async callAnalyticsAPI() {
      let isError = false;
      //  Validate the inputs here and set error text/highlights
      if (
        this.selectedView !== "mod_window_suitability" &&
        this.selectedView !== "wind_rose" &&
        (!this.selectedTurbines || this.selectedTurbines.length === 0)
      ) {
        this.selectedTurbinesRules = [
          (v) => v.length > 0 || "Must choose at least one turbine.",
        ];
        this.isTurbinesValid = false;
        isError = true;
      }
      if (this.viewStartDate) {
        this.isStartDateValid = true;
      } else {
        this.isStartDateValid = false;
        this.dateError = "Please enter valid start and end dates.";
        isError = true;
      }
      if (this.viewEndDate) {
        this.isEndDateValid = true;
      } else {
        this.isEndDateValid = false;
        this.dateError = "Please enter valid start and end dates.";
        isError = true;
      }
      if (this.selectedView !== "mod_window_suitability") {
        if (
          this.viewStartDate &&
          this.viewEndDate &&
          this.isStartDateValid &&
          this.isEndDateValid &&
          this.dateRange.startDate > this.dateRange.endDate
        ) {
          this.isStartDateValid = false;
          this.isEndDateValid = false;
          this.dateError = "Date range is invalid.";
          isError = true;
        }
      }

      // Return if there are errors
      if (isError) {
        return;
      }

      let analyticsRequestObj = {
        site_id: this.siteId,
        view: this.selectedView,
        turbine_ids: [],
        start_date: null,
        end_date: null,
      };
      if (this.dateRange.startDate) {
        analyticsRequestObj.start_date = this.viewStartDate;
      }
      if (this.dateRange.endDate) {
        analyticsRequestObj.end_date = this.viewEndDate;
      }
      if (this.selectedTurbines.length > 0) {
        let turbineIds = [];
        for (var i = 0; i < this.selectedTurbines.length; i++) {
          turbineIds.push(this.selectedTurbines[i].value);
        }
        analyticsRequestObj.turbine_ids = turbineIds;
      }

      const requestsEqual = this.areRequestObjectsEqual(
        analyticsRequestObj,
        this.staticViewRequest,
      );

      if (requestsEqual) {
        return;
      } else {
        this.staticViewRequest.site_id = analyticsRequestObj.site_id;
        this.staticViewRequest.view = analyticsRequestObj.view;
        this.staticViewRequest.turbine_ids = analyticsRequestObj.turbine_ids;
        this.staticViewRequest.start_date = analyticsRequestObj.start_date;
        this.staticViewRequest.end_date = analyticsRequestObj.end_date;
        this.getAnalyticsView(this.selectedView, analyticsRequestObj);
        this.$emit("setStaticViewRequest", this.staticViewRequest);
      }
    },
    isDateValid(date) {
      if (date && date.toString() !== "Invalid Date") {
        return true;
      } else {
        return false;
      }
    },
    defineAnalytics() {
      this.openDescription = !this.openDescription;
    },
    handleLeaveDialog() {
      if (!this.dialogHover) {
        this.openDescription = false;
      }
    },
    setAnalyticsFigure(analyticsObj) {
      if (analyticsObj && analyticsObj.figure) {
        // eslint-disable-next-line no-undef
        const temp = structuredClone(analyticsObj);
        temp.figure.layout.paper_bgcolor = this.$vuetify.theme.isDark
          ? "#1e1e1e"
          : "";
        temp.figure.layout.font = {
          color: this.$vuetify.theme.isDark ? "#fff" : "",
        };

        return temp;
      } else {
        return null;
      }
    },
    clearError(type) {
      if (type === "date") {
        this.isStartDateValid = true;
        this.isEndDateValid = true;
        this.dateError = "";
      }
      if (type === "view") {
        this.selectedViewRules = [];
        this.isViewValid = true;
      }
      if (type === "turbines") {
        this.selectedTurbinesRules = [];
        this.isTurbinesValid = true;
      }
    },
    areRequestObjectsEqual() {
      if (this.staticViewRequest.site_id !== this.siteId) {
        return false;
      }
      if (this.staticViewRequest.view !== this.selectedView) {
        return false;
      }

      const areTurbinesEqual = compareTwoArraysForEquality(
        this.staticViewRequest.turbine_ids,
        this.selectedTurbines,
      );

      if (!areTurbinesEqual) {
        return false;
      }

      if (
        this.isDateValid(this.viewStartDate) &&
        this.staticViewRequest.start_date !== this.viewStartDate
      ) {
        return false;
      }
      if (
        this.isDateValid(this.viewEndDate) &&
        this.staticViewRequest.end_date !== this.viewEndDate
      ) {
        return false;
      }

      return true;
    },
    handleBlurViewSelection() {
      if (!this.selectedView) {
        this.selectedViewRules = [(v) => !!v || "Must select a view."];
        this.isViewValid = false;
      } else {
        this.selectedViewRules = [];
        this.isViewValid = true;
      }
    },
    getAnalyticsView(viewType, requestObj) {
      switch (viewType) {
        case "yaw_misalignment":
          this.getYawMisalignmentAnalytics(requestObj);
          break;
        case "minimum_pitch":
          this.getMinPitchAnalytics(requestObj);
          break;
        case "wind_rose":
          this.getWindRoseAnalytics(requestObj);
          break;
        case "mod_window_suitability":
          this.getModWindowSuitabilityAnalytics(requestObj);
          break;
      }
    },
  },
  watch: {
    selectedTurbines: {
      handler(newValue, oldValue) {
        const url = new URL(window.location.href);
        if (this.allOptionChanged) {
          this.allOptionChanged = false;
          return;
        }
        let allOptionPresentPrev = false;
        let allOptionPresentCurr = false;
        if (newValue) {
          for (const item of newValue) {
            if (item.text === "All turbines") {
              allOptionPresentCurr = true;
              break;
            }
          }
        }
        if (oldValue) {
          for (const item of oldValue) {
            if (item.text === "All turbines") {
              allOptionPresentPrev = true;
              break;
            }
          }
        }

        if (allOptionPresentPrev && !allOptionPresentCurr) {
          this.selectedTurbines = [];
          this.allOptionChanged = true;
        } else if (
          !allOptionPresentPrev &&
          allOptionPresentCurr &&
          this.selectedTurbines.length !== this.turbineOptions.length
        ) {
          this.selectedTurbines = [...this.turbineOptions];
          this.allOptionChanged = true;
        } else if (allOptionPresentCurr && allOptionPresentPrev) {
          if (this.selectedTurbines.length < this.turbineData.length + 1) {
            const isAllOption = (element) => element.text === "All turbines";
            const allOptionIndex = this.selectedTurbines.findIndex(isAllOption);

            this.selectedTurbines.splice(allOptionIndex, 1);
            this.allOptionChanged = true;
          }
        } else if (!allOptionPresentPrev && !allOptionPresentCurr) {
          if (this.selectedTurbines.length === this.turbineData.length) {
            this.selectedTurbines.push({
              text: "All turbines",
              value: "all_turbines",
            });
            this.allOptionChanged = true;
          }
        }

        if (newValue === oldValue) {
          return;
        } else {
          this.clearError("turbines");
          const turbineIds = url.searchParams.getAll("turbine_id");
          turbineIds.forEach(() => {
            url.searchParams.delete("turbine_id");
          });
          for (const item of this.selectedTurbines) {
            if (item.value !== "all_turbines") {
              url.searchParams.append("turbine_id", item.value);
            }
          }

          history.replaceState(null, null, url);
        }
      },
    },
    turbineData: {
      immediate: true,
      handler(data) {
        // Get site id from parent for comparison
        if (data?.length > 0) {
          if (data[0].site_id == this.siteId && this.isInitial) {
            this.setUrlParamsForNavigation("initial");
          }
        }
      },
    },
    viewStartDate: {
      handler(newDate, oldDate) {
        if (oldDate && newDate !== oldDate) {
          setTimeout(() => {
            this.setDateUrlParams(newDate, "start_date");
          }, 200);
        }
      },
    },
    viewEndDate: {
      handler(newDate, oldDate) {
        if (oldDate && newDate !== oldDate) {
          setTimeout(() => {
            this.setDateUrlParams(newDate, "end_date");
          }, 200);
        }
      },
    },
  },
};
</script>
<style lang="scss" scoped>
.loader {
  height: 398px;
}
.select-action {
  font-size: 0.75rem;
  cursor: pointer;
}

::v-deep {
  .v-select__slot .v-input__icon .v-icon::before,
  .v-select__slot .v-input__icon .v-icon::after {
    cursor: pointer;
  }
}

.links b:hover {
  color: var(--v-primary-base);
}

.filter-on-color {
  color: var(--v-primary-base);
}

.v-list-item__action {
  margin-right: 12px !important;
}

.analytics-display {
  position: relative;
  background-color: var(--var-white-base);
}

.analytics-col {
  min-width: 240px;
  padding-bottom: 0;
}

.min-length {
  min-width: 480px;
}

.info-icon:hover {
  color: var(--v-primary-base);
}

.request-icon:hover {
  color: var(--v-primary-base);
}

.view-wrapper {
  width: 100%;
}

.request-icon-wrapper {
  margin-top: 3px;
}

.no-data-backdrop {
  background-color: var(--v-backdrop-base);
  height: 400px;
}

.no-data-label {
  color: var(--v-white2-base);
  font-size: 1.5rem;
  padding-top: 150px;
}

#display {
  position: relative;
}

.request-btn-wrapper {
  position: absolute;
  top: -14%;
  right: 0;
}

.error-anchor {
  position: relative;
}

.error-msg {
  position: absolute;
  top: 110%;
  left: 3%;
  color: var(--v-error-base);
  font-size: 0.75rem;
}

::v-deep {
  .error-highlight {
    .mx-input {
      border: 2px solid var(--v-error-base);
    }
  }
}

::v-deep {
  .error-highlight-view .v-input__slot,
  .error-highlight-turbines .v-input__slot {
    border-color: var(--v-error-base) !important;
    border-width: 2.5px !important;
  }
}

.yaw-adjustments-switch {
  display: inline-flex;
  align-items: center;
}
</style>
