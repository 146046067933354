<template>
  <div class="all-issues-card-container">
    <div
      class="d-flex justify-center align-center all-issues-card-container__spinner"
      v-if="loading"
    >
      <v-progress-circular
        :size="40"
        color="primary"
        indeterminate
      ></v-progress-circular>
    </div>
    <!-- HEADER -->
    <div class="d-flex flex-column justify-space-between h100">
      <div class="header-container d-flex justify-space-between">
        <div>
          <p class="ma-0 subtitle">{{ siteName }}</p>
          <h4 class="header">All Issues</h4>
        </div>
        <!-- Commenting out until functionality known <div>
        <v-icon size="1.5rem" class="pointer icon-hover"
          >mdi-arrow-expand-all</v-icon
        >
      </div> -->
      </div>

      <div id="issue-chart-container">
        <div class="all-issues" id="issue-chart"></div>
      </div>
      <div class="d-flex justify-end align-end updated-row">
        <!-- Comment in when date is determined -->
        <!-- <p class="updated-text ma-0">{{ lastUpdatedDate }}</p> -->
        <div class="new-button">
          <button @click="goToIssues('all', componentType)">
            EXPLORE ALL ISSUES
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import PopUp from "@/components/PopUp";
import * as d3 from "d3";

export default {
  name: "AllIssuesCard",
  props: {
    siteName: {
      type: String,
      required: false,
      default: "",
    },
    page: {
      type: String,
      required: false,
      default: "turbineIssues",
    },
    issuesByStatus: {
      type: Array,
      required: false,
      default: () => [],
    },
    issuesByType: {
      type: Array,
      required: false,
      default: () => [],
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  components: {
    PopUp,
  },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      issues: (state) => state.issues,
      turbine: (state) => state.turbine,
    }),
    isDataReady() {
      if (
        Array.isArray(this.newIssues) &&
        Array.isArray(this.inProgressIssues) &&
        Array.isArray(this.fixedIssues) &&
        !this.loading
      ) {
        return true;
      }
      return false;
    },
    chartData() {
      if (this.isDataReady) {
        return [
          // Open issues are not included because they contain "in progress" issues
          { status: "NEW", count: this.newIssues.length },
          {
            status: "IN PROGRESS",
            count: this.inProgressIssues.length,
          },
          { status: "FIXED", count: this.fixedIssues.length },
        ];
      }
      return [];
    },
    componentType() {
      if (this.page === "converterDetail") {
        return "converter";
      } else if (this.page === "mainBearing") {
        return "main_bearing";
      } else {
        return null;
      }
    },
    newIssues() {
      if (
        this.page === "issueDetail" ||
        this.page === "converterDetail" ||
        this.page === "mainBearing" ||
        this.page === "turbineDashboard"
      ) {
        return this.issuesByStatus.filter(
          (issue) => issue.current_status_def_name_external === "New",
        );
      } else if (this.page === "groupedSiteComponents") {
        return this.issuesByType.filter((issue) => issue.n_new > 0);
      } else {
        return [];
      }
    },
    inProgressIssues() {
      if (
        this.page === "issueDetail" ||
        this.page === "converterDetail" ||
        this.page === "mainBearing" ||
        this.page === "turbineDashboard"
      ) {
        return this.issuesByStatus.filter(
          (issue) => issue.current_status_def_name_external === "In progress",
        );
      } else if (this.page === "groupedSiteComponents") {
        return this.issuesByType.filter((issue) => issue.n_in_progress > 0);
      } else {
        return [];
      }
    },
    fixedIssues() {
      if (
        this.page === "issueDetail" ||
        this.page === "converterDetail" ||
        this.page === "mainBearing" ||
        this.page === "turbineDashboard"
      ) {
        return this.issuesByStatus.filter(
          (issue) => issue.current_status_def_name_external === "Fixed",
        );
      } else if (this.page === "groupedSiteComponents") {
        return this.issuesByType.filter((issue) => issue.n_fixed > 0);
      } else {
        return [];
      }
    },
  },
  methods: {
    createChart() {
      const vm = this;
      const themeColors = this.$vuetify.theme.dark
        ? this.$vuetify.theme.themes.dark
        : this.$vuetify.theme.themes.light;
      const data = this.chartData;
      const allCountsZero = data.every((d) => d.count === 0);
      const margin = { top: 20, right: 30, bottom: 20, left: 90 };
      let svg;

      function drawChart() {
        d3.select("#issue-chart svg").remove();

        const containerWidth = document.getElementById(
          "issue-chart-container",
        ).clientWidth;
        let width = containerWidth - margin.left - margin.right;
        let height = 150 - margin.top - margin.bottom;

        // Select the div and append the SVG
        svg = d3
          .select("#issue-chart")
          .append("svg")
          .attr("width", width + margin.left + margin.right)
          .attr("height", height + margin.top + margin.bottom)
          .append("g")
          .attr("transform", `translate(${margin.left},${margin.top})`);

        // Create the X and Y scales
        const x = d3
          .scaleLinear()
          .domain([0, Math.max(...data.map((d) => d.count))])
          .range([0, width]);

        const y = d3
          .scaleBand()
          .range([0, height])
          .domain(data.map((d) => d.status))
          .padding(0.1);

        // Customize the X axis
        const xAxis = d3
          .axisBottom(x)
          .ticks(5) // This will create ticks at intervals of 5
          .tickSize(0); // This will remove the tick marks

        // Draw the customized X axis
        svg.append("g").attr("transform", `translate(0,${height})`).call(xAxis);

        // Customize the Y axis
        const yAxis = d3.axisLeft(y).tickSize(0); // This will remove the tick marks

        // Draw the customized Y axis
        const yAxisGroup = svg
          .append("g")
          .style("font-family", "Inter")
          .call(yAxis);

        // Align Y axis labels to the left
        yAxisGroup
          .selectAll("text")
          .style("text-anchor", "start")
          .attr("x", -margin.left) // This moves the text to the left of the axis
          .attr("dx", "0.5em"); // Adds a small rightward offset for padding from the axis
        // Make Y axis labels clickable
        yAxisGroup
          .selectAll("text")
          .style("cursor", "pointer")
          .style("text-decoration", "underline")
          .on("mouseover", function (event, d) {
            if (d === "NEW" && vm.newIssues.length > 0) {
              d3.select(this).style("fill", themeColors.primary);
            }
            if (d === "IN PROGRESS" && vm.inProgressIssues.length > 0) {
              d3.select(this).style("fill", themeColors.primary);
            }
            if (d === "FIXED" && vm.fixedIssues.length > 0) {
              d3.select(this).style("fill", themeColors.primary);
            }
          })
          .on("mouseout", function () {
            d3.select(this).style("fill", themeColors.widgetText);
          })
          .on("click", (event, d) => {
            if (d === "NEW" && vm.newIssues.length > 0) {
              vm.goToIssues("new", vm.componentType);
            } else if (d === "IN PROGRESS" && vm.inProgressIssues.length > 0) {
              vm.goToIssues("in_progress", vm.componentType);
            } else if (d === "FIXED" && vm.fixedIssues.length > 0) {
              vm.goToIssues("fixed", vm.componentType);
            }
          });
        // Draw the bars
        const filteredDataForBars = data.filter((d) => d.count !== 0);
        svg
          .selectAll("rect")
          .data(filteredDataForBars)
          .enter()
          .append("rect")
          .attr("x", x(0))
          .attr("y", (d) => y(d.status))
          .attr("width", (d) => x(d.count))
          .attr("height", y.bandwidth() * 0.75)
          .attr("class", "bar-chart");
        // Add label displaying count to the right of bars
        svg
          .selectAll(".label")
          .data(filteredDataForBars)
          .enter()
          .append("text")
          .attr("class", "label")
          // Position the text right of the bar
          .attr("x", (d) => x(d.count) + 5)
          // Align the text vertically in the middle of the bars
          .attr(
            "y",
            (d) =>
              y(d.status) + (y.bandwidth() * 0.25) / 2 + y.bandwidth() * 0.375,
          )
          // Center the text vertically
          .attr("dy", ".35em") // Vertical alignment for centering
          // Set the text to be the count of the issues
          .text((d) => d.count)
          // Style the text if needed (optional)
          .attr("fill", themeColors.widgetText)
          .attr("font-weight", "bold");
        // Add "No issues found" text in the center if all counts are 0
        if (allCountsZero) {
          svg
            .append("text")
            .attr("class", "no-issues-text")
            .attr("x", width / 2)
            .attr("y", height / 2)
            .attr("text-anchor", "middle")
            .attr("dy", ".35em") // Vertical alignment for centering
            .text("No issues found")
            .attr("fill", themeColors.widgetText)
            .attr("font-weight", "bold");
        }
      }

      drawChart();

      window.addEventListener("resize", drawChart);
    },
    getContentSubHeader() {
      if (this.page === "issueDetail") {
        return "Impact";
      }
    },
    openIssueDialog(item) {
      this.selectedStatus = item.name_external || item.definition_name_external;
      this.showIssueDialog = true;
    },
    closeIssueDialog() {
      this.showIssueDialog = false;
    },
    goToIssues(include_status) {
      const params = {
        include_status:
          include_status === "all"
            ? ["new", "in_progress", "fixed"]
            : include_status,
      };
      this.$emit("goToIssues", params);
    },
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.createChart);
  },
  watch: {
    isDataReady: {
      immediate: true,
      handler(data) {
        if (data) {
          d3.select("#issue-chart svg").remove();
          this.$nextTick(() => {
            this.createChart();
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/_variables";
.all-issues-card-container {
  position: relative;
  padding: 1rem;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.header-container {
  margin-bottom: 0.5rem;

  .subtitle {
    font:
      0.75rem "Museo Sans Rounded",
      sans-serif;
    color: var(--v-text-base);
    font-weight: 600;
  }
}
#issue-chart-container {
  display: flex;
  justify-content: center;
}
.maintenance-items {
  max-height: 450px;
  p {
    margin: 0;
  }
}
.maintenance-item {
  border-bottom: 1px dotted var(--v-converterBorders-base);
  padding: 0.75rem 0;
}
.updated-row {
  margin-top: 3rem;
}

.heading-hover:hover {
  color: $primaryBlue;
}

::v-deep .bar-chart {
  fill: $primaryBlue;
}
</style>
