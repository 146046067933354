import { render, staticRenderFns } from "./NewAnalyticsView.vue?vue&type=template&id=66ff8bc6&scoped=true&"
import script from "./NewAnalyticsView.vue?vue&type=script&lang=js&"
export * from "./NewAnalyticsView.vue?vue&type=script&lang=js&"
import style0 from "./NewAnalyticsView.vue?vue&type=style&index=0&id=66ff8bc6&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "66ff8bc6",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VCombobox } from 'vuetify/lib/components/VCombobox';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSwitch } from 'vuetify/lib/components/VSwitch';
installComponents(component, {VAutocomplete,VCard,VCardText,VCol,VCombobox,VForm,VIcon,VMenu,VRow,VSwitch})
