<template>
  <div>
    <div>
      <div class="rotor-shaft__header d-flex align-center mb-2">
        <h3 class="main-title mr-1">
          <div
            class="d-flex justify-center align-center main-title-spinner"
            v-if="loading.getMainBearingData"
          >
            <v-progress-circular
              :size="22"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div v-else>
            {{ pageTitle }}
          </div>
        </h3>
        <div
          class="rotor-shaft__header__help d-flex align-center justify-space-between"
          @click="handleHelpClick"
        >
          <v-icon>{{ informationIcon }}</v-icon>
        </div>
      </div>
      <div class="grid-1">
        <div class="grid-1-col-1">
          <div class="d-flex flex-column justify-space-between col-1-container">
            <div class="rotor-shaft-img d-flex align-center">
              <div class="svg-container">
                <svg
                  viewBox="0 0 1280 720"
                  preserveAspectRatio="xMidYMid meet"
                  ref="svgObject"
                  class="px-4"
                >
                  <image
                    width="100%"
                    height="100%"
                    href="/img/rotor-shaft-assembly.svg"
                  />
                  <g v-for="(anchor, index) in anchors" :key="index">
                    <text :x="anchor.x" :y="anchor.y" class="svg-text">
                      {{ anchor.label }}
                    </text>
                  </g>
                </svg>
              </div>
            </div>
            <div class="peer-container">
              <div v-if="peersPlot">
                <div class="d-flex justify-center align-center">
                  <span class="plot-heading"
                    >Main bearing temperature vs. peers</span
                  >
                  <div class="peer-plot-info" @click="peerPlotInfoFlyout">
                    <v-icon size="20">{{ informationIcon }}</v-icon>
                  </div>
                </div>
                <Plotly
                  id="peer_plotly_figure"
                  :data="peersPlot.data"
                  :layout="peersPlot.layout"
                  :responsive="true"
                  :showTips="false"
                ></Plotly>
              </div>
              <div
                v-else
                class="no-peer-data d-flex justify-center align-center"
              >
                <div
                  class="d-flex justify-center align-center no-peer-data__spinner"
                  v-if="loadingPeersFigure"
                >
                  <v-progress-circular
                    :size="45"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <p v-else>NO DATA AVAILABLE</p>
              </div>
            </div>
          </div>
        </div>
        <div class="grid-1-col-2">
          <div
            class="d-flex flex-column justify-space-between risk-model-events-container"
          >
            <div class="d-flex justify-center position-relative">
              <div class="plot-heading mt-2">
                Risk model and associated events
              </div>
              <div
                v-if="nbmResidualsPlot"
                class="risk-model-info position-absolute"
                @click="riskModelInfoFlyout"
              >
                <v-icon size="20">{{ informationIcon }}</v-icon>
              </div>
            </div>
            <div class="residuals-plot-container">
              <div v-if="nbmResidualsPlot">
                <Plotly
                  v-if="nbmResidualsPlot"
                  :data="nbmResidualsPlot.data"
                  :layout="nbmResidualsPlot.layout"
                  :responsive="true"
                  :showTips="false"
                ></Plotly>
              </div>
              <div v-else class="no-data d-flex justify-center align-center">
                <div
                  class="d-flex justify-center align-center no-data__spinner"
                  v-if="loadingNbmResidualsFigure"
                >
                  <v-progress-circular
                    :size="45"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <p v-else>NO DATA AVAILABLE</p>
              </div>
            </div>
            <div class="events-plot-container">
              <div v-if="eventsPlot">
                <Plotly
                  v-if="eventsPlot"
                  :data="eventsPlot.data"
                  :layout="eventsPlot.layout"
                  :responsive="true"
                  :showTips="false"
                ></Plotly>
              </div>
              <div v-else class="no-data d-flex justify-center align-center">
                <div
                  class="d-flex justify-center align-center no-data__spinner"
                  v-if="loadingEventsFigure"
                >
                  <v-progress-circular
                    :size="45"
                    color="primary"
                    indeterminate
                  ></v-progress-circular>
                </div>
                <p v-else>NO DATA AVAILABLE</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="grid-2">
        <div class="grid2-row-1-col-1-2">
          <div class="new-tab">
            <button
              v-for="tab in tabs"
              :key="tab.id"
              :ref="tab.name"
              :class="{ tablinks: true, active: activeTab === tab.id }"
              @click="openTab(tab.id, tab.name, 'click_event')"
            >
              {{ tab.name }}
            </button>
          </div>
          <div class="rotor-shaft-tabbed__section">
            <!-- Comment in when recommendations tab is ready -->
            <!-- <div
              id="Recommendations"
              class="tabcontent"
              v-show="activeTab === 1"
            >
              <RotorShaftRecommendationsTab
                :recommendations="tempRecommendations"
              />
            </div> -->
            <div id="Components" class="tabcontent" v-if="activeTab === 2">
              <RotorShaftFeaturesTab
                :subsystemComponents="rotorShaftComps"
                :loading="loading.getSubsystemComponents"
              />
            </div>
            <div id="Issues" class="tabcontent" v-if="activeTab === 3">
              <RotorShaftIssuesTab
                :issues="mappedIssues"
                :siteId="siteId"
                :loading="loading.getPaginatedIssuesForIssuesTable"
              />
            </div>
            <div id="Explore" class="tabcontent pa-4" v-if="activeTab === 4">
              <div v-if="!isPowerCurveExplorerOpen && !isEventsViewOpen">
                <NewAnalyticsView
                  ref="analyticsView"
                  :turbineData="turbinesData"
                  :siteId="siteId"
                  pageTitle="mainBearingDetail"
                  @setStaticViewRequest="setAnalyticsViewRequest"
                  @openPowerCurveExplorer="togglePowerCurveExplorer"
                  @viewChanged="updateAnalyticsViewSelected"
                />
              </div>
              <div v-if="isPowerCurveExplorerOpen && !isEventsViewOpen">
                <NewPowerCurveExplorer
                  :targetTurbineOptions="targetTurbineOptions"
                  :loadingTurbines="turbinesLoading"
                  :siteId="siteId"
                  @openPowerCurveExplorer="togglePowerCurveExplorer"
                  @viewChanged="updateAnalyticsViewSelected"
                />
              </div>
              <div
                v-if="isEventsViewOpen && !isPowerCurveExplorerOpen && siteId"
              >
                <EventsExplorer
                  page="mainBearingDetail"
                  :siteId="siteId"
                  @openEventsView="toggleEventsView"
                  @viewChanged="updateAnalyticsViewSelected"
                />
              </div>
            </div>
            <div id="Description" class="tabcontent" v-if="activeTab === 5">
              <div class="details-widget-container">
                <p>
                  The main shaft assembly of a wind turbine, which connects the
                  hub to the gearbox or generator, includes either one or two
                  main bearings depending on its configuration. These bearings,
                  also known as rotor bearings, have been identified as some of
                  the most critical components to the reliability and
                  availability of the turbine's transmission system.
                </p>
                <div>
                  <span>
                    There are three main approaches to detecting incipient main
                    bearing damage:
                  </span>
                  <ul>
                    <li>
                      SCADA-based:
                      <ul class="explanation-list-item">
                        <li>
                          Utilizes machine learning to detect issues by
                          analyzing the relationship between friction and
                          temperature correlations.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Vibration-based:
                      <ul class="explanation-list-item">
                        <li>
                          Monitors the mechanical vibrations to identify
                          abnormalities indicating potential damage.
                        </li>
                      </ul>
                    </li>
                    <li>
                      Oil-based:
                      <ul class="explanation-list-item">
                        <li>
                          Analyzes oil samples for wear particles that signal
                          bearing damage.
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <p>
                  WindESCo embraces an holistic approach for monitoring main
                  bearing health, harnessing every available data source. Most
                  prevalent being the SCADA-based method, effectively
                  correlating friction and temperature to identify potential
                  issues and preempt failures.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="grid2-row-2-col-1">
          <AllIssuesCard
            :siteName="siteName"
            :issuesByStatus="issuesByStatus"
            :loading="loading.getPaginatedIssuesForIssuesTable"
            page="mainBearing"
            @goToIssues="goToIssuesTable"
          ></AllIssuesCard>
        </div>
        <div class="grid2-row-2-col-2">
          <RotorShaftMaintenanceCard
            :maintenanceData="maintenanceData"
            :siteName="siteName"
            :siteId="siteId"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import RotorShaftAnalyticsFigures from "@/components/RotorShaftComponents/RotorShaftAnalyticsFigures";
import NewAnalyticsView from "@/components/NewIssueDetailComponents/NewAnalyticsView.vue";
import NewPowerCurveExplorer from "@/components/NewIssueDetailComponents/NewPowerCurveExplorer";
import EventsExplorer from "@/components/NewSiteDashboardComponents/EventsExplorer";
import RotorShaftRecommendationsTab from "@/components/RotorShaftComponents/RotorShaftRecommendationsTab";
import RotorShaftFeaturesTab from "@/components/RotorShaftComponents/RotorShaftFeaturesTab";
import RotorShaftIssuesTab from "@/components/RotorShaftComponents/RotorShaftIssuesTab";
import RotorShaftMaintenanceCard from "@/components/RotorShaftComponents/RotorShaftMaintenanceCard";
import AllIssuesCard from "@/components/AllIssuesCard";
import { informationIcon } from "@/helpers/variables";
import { roundToString } from "@/helpers/functions";
import Plotly from "@/components/Plotly";
import dayjs from "dayjs";
import mixpanel from "mixpanel-browser";

export default {
  name: "RotorShaftAssembly",
  components: {
    NewAnalyticsView,
    NewPowerCurveExplorer,
    EventsExplorer,
    RotorShaftRecommendationsTab,
    RotorShaftFeaturesTab,
    RotorShaftIssuesTab,
    RotorShaftAnalyticsFigures,
    RotorShaftMaintenanceCard,
    AllIssuesCard,
    Plotly,
  },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    siteName: {
      type: String,
      default: null,
    },
    nbmResidualsFigureData: {
      type: Object,
      default: null,
    },
    peersFigureData: {
      type: Object,
      default: null,
    },
    eventsFigureData: {
      type: Object,
      default: null,
    },
    mainBearingData: {
      type: Object,
      default: null,
    },
    turbineData: {
      type: Object,
      default: null,
    },
    turbinesData: {
      type: Array,
      default: null,
    },
    modsByMainBearing: {
      type: Array,
      default: null,
    },
    issuesByStatus: {
      type: Array,
      default: null,
    },
    loadingNbmResidualsFigure: {
      type: Boolean,
      default: false,
    },
    loadingEventsFigure: {
      type: Boolean,
      default: false,
    },
    loadingPeersFigure: {
      type: Boolean,
      default: false,
    },
    rotorShaftComponents: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      tabs: [
        // {
        //   id: 1,
        //   name: "RECOMMENDATIONS",
        // },
        {
          id: 2,
          name: "COMPONENTS",
        },
        {
          id: 3,
          name: "ISSUES",
        },
        {
          id: 4,
          name: "EXPLORE",
        },
        {
          id: 5,
          name: "DESCRIPTION",
        },
      ],
      activeTab: 2,
      isPowerCurveExplorerOpen: false,
      staticAnalyticsViewRequest: {},
      informationIcon,
      anchors: [
        { x: 125, y: 200, label: "Hub" },
        { x: 300, y: 200, label: "Main Bearing" },
        { x: 450, y: 320, label: "Main Shaft" },
        { x: 700, y: 200, label: "Gearbox" },
        { x: 940, y: 320, label: "Coupling" },
        { x: 1150, y: 200, label: "Generator" },
      ],
      isEventsViewOpen: false,
      openExploreTab: false,
    };
  },
  computed: {
    ...mapState({
      clickCount: (state) => state.app.clickCount,
      loading: (state) => state.turbine.loading,
      turbinesLoading: (state) => state.site.loading.getTurbinesBySiteId,
      isInternal: (state) => state.user.userData.is_internal,
    }),
    nbmResidualsPlot() {
      if (
        this.nbmResidualsFigureData &&
        Object.keys(this.nbmResidualsFigureData).length > 0
      ) {
        let nbmResidualsFigure = this.nbmResidualsFigureData;
        nbmResidualsFigure.layout.height = 260;
        nbmResidualsFigure.layout.margin = { l: 80, b: 30, r: 40, t: 30 };
        // nbmResidualsFigure.layout.autosize = true;
        return nbmResidualsFigure;
      }
      return null;
    },
    eventsPlot() {
      if (
        this.eventsFigureData &&
        Object.keys(this.eventsFigureData).length > 0
      ) {
        let eventsFigure = this.eventsFigureData;
        eventsFigure.layout.height = 260;
        eventsFigure.layout.margin = { l: 80, b: 0, r: 40, t: 0 };
        // eventsFigure.layout.autosize = true;
        return eventsFigure;
      }
      return null;
    },
    peersPlot() {
      if (this.peersFigureData) {
        let peerFigure = this.peersFigureData;
        peerFigure.layout.height = 260;
        peerFigure.layout.margin = { l: 80, b: 20, r: 20, t: 30 };
        // peerFigure.layout.autosize = true;
        return peerFigure;
      }
      return null;
    },
    pageTitle() {
      return "Rotor-shaft Assembly";
    },
    mainBearingConfigurationType() {
      return this.mainBearingData?.model_name ?? "Unknown model";
    },
    targetTurbineOptions() {
      if (this.turbinesData?.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    maintenanceData() {
      return this.modsByMainBearing;
    },
    issues() {
      if (this.issuesByStatus && this.isInternal) {
        return this.issuesByStatus;
      } else {
        return this.issuesByStatus.filter(
          (issue) =>
            !issue.current_status_def_name_external.includes("internal"),
        );
      }
    },
    mappedIssues() {
      if (this.issues.length > 0) {
        return this.issues.map((issue) => {
          return {
            id: issue.id,
            name: issue.name,
            description: issue.description,
            status: issue.current_status_def_name_external,
            current_status_def_id: issue.current_status_def_id,
            impact_mwh: roundToString(issue.aep_loss_mwhpyr, 0),
            impact_usd: roundToString(issue.aep_loss_upper_usdpyr, 0),
            ticketsInProgress: issue.work_order_ids,
            last_updated: dayjs(issue.current_status_created_ts).format(
              "YYYY-MM-DD",
            ),
          };
        });
      }
      return [];
    },
    rotorShaftComps() {
      return this.rotorShaftComponents ? this.rotorShaftComponents : [];
    },
  },
  methods: {
    ...mapActions({
      incrementClickCount: "app/incrementClickCount",
    }),
    handleHelpClick() {
      this.$emit("helpIconClicked");
    },
    openTab(tabId, tabName, method) {
      const url = new URL(window.location.href);
      const analyticsView = url.searchParams.get("analytics_view");
      this.activeTab = tabId;
      if (method !== "method") {
        mixpanel.track("main_bearing_tab_click", {
          tab_name: tabName,
        });
      }
      this.setUrlByTab(tabId);
      if (tabId === 3) {
        if (this.siteId) {
          this.goToExploreTab();
          if (analyticsView === "events") {
            this.isEventsViewOpen = true;
          } else if (analyticsView === "power_curve_explorer") {
            this.isPowerCurveExplorerOpen = true;
          }
        } else {
          this.openExploreTab = true;
        }
      }
      if (tabId === 2) {
        this.goToIssuesTab();
      }
      // Using timeout because next tick is not fast enough to see the element
      if (tabName) {
        this.timeout = setTimeout(() => {
          const element = this.$refs[tabName];
          // Scroll to the active tab
          element[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 1000);
      }
    },
    // Call this when tab is clicked
    setUrlByTab(tabId) {
      const url = new URL(window.location.href);
      const tab = this.tabs.find((tab) => tab.id === tabId);
      if (tab) {
        url.search = "";
        url.hash = tab.name.toLowerCase();
        window.history.replaceState(null, null, url);
      }
    },
    // Call this on mounted
    setTabByUrl(hashName) {
      const tabName = hashName.replace("#", "").toUpperCase();
      const tab = this.tabs.find((tab) => tab.name === tabName);
      if (tab && tab.id) {
        this.openTab(tab.id, tabName, "method");
      }
    },
    isActiveTab(tabId) {
      return this.activeTab === tabId;
    },
    goToLossModelTab() {
      this.$nextTick(() => {
        this.$refs.newLossModel.handleClick();
      });
    },
    updateAtAGlance() {
      this.$emit("updateAtAGlance");
    },
    goToExploreTab() {
      this.$emit("goToExploreTab");
      this.openExploreTab = false;
    },
    setAnalyticsViewRequest(request) {
      // Request details for setting url params on navigation to site analytics tab
      this.staticAnalyticsViewRequest.site_id = request.site_id;
      this.staticAnalyticsViewRequest.start_date = request.start_date;
      this.staticAnalyticsViewRequest.end_date = request.end_date;
      this.staticAnalyticsViewRequest.turbine_ids = request.turbine_ids;
      this.staticAnalyticsViewRequest.view = request.view;
    },
    togglePowerCurveExplorer(isOpen) {
      this.isPowerCurveExplorerOpen = isOpen;
    },
    toggleEventsView(isOpen) {
      this.isEventsViewOpen = isOpen;
    },
    updateAnalyticsViewSelected(view) {
      if (view === "events") {
        this.toggleEventsView(true);
      } else {
        this.toggleEventsView(false);
      }
    },
    goToIssuesTab() {
      this.$emit("goToIssuesTab");
    },
    goToIssuesTable(payload) {
      this.$emit("goToIssuesTable", payload);
    },
    peerPlotInfoFlyout() {
      this.$emit("openInfoFlyout", "peer_plot");
    },
    riskModelInfoFlyout() {
      this.$emit("openInfoFlyout", "risk_model");
    },
  },
  mounted() {
    const url = new URL(window.location.href);
    // If the url has a hash, set the active tab by the hash
    if (url.hash) {
      this.setTabByUrl(url.hash);
    }
  },
  watch: {
    mainBearingData: {
      handler(data) {
        if (!this.loading.getMainBearingData && data?.siteId && !data?.id) {
          this.$router.push({
            path: `/sites/${data.siteId}/main-bearings`,
          });
        }
      },
    },
    // Open explore tab only after site id is available
    siteId: {
      immediate: true,
      handler(value) {
        const url = new URL(window.location.href);
        const analyticsView = url.searchParams.get("analytics_view");
        if (value && this.openExploreTab) {
          this.goToExploreTab();
          if (analyticsView === "events") {
            this.isEventsViewOpen = true;
          } else {
            this.isEventsViewOpen = false;
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.main-title-spinner {
  min-width: 315px;
}
.grid-1 {
  display: grid;
  grid-template-columns: 1fr 1.05fr;
  grid-template-rows: auto auto;
  grid-gap: 0.5rem;
}
.grid-1-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 650px;
}
.grid-1-col-2 {
  height: 650px;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  padding: 0 0.25rem 0.25rem 0.25rem;
}

.col-1-container {
  height: 100%;
}
.rotor-shaft-img {
  border: 1px dotted var(--v-componentImageBorder-base);
  border-radius: 8px;
  height: 49.5%;
}
.peer-container {
  height: 49.5%;
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
}
.peer-plot-info {
  .v-icon {
    color: var(--v-black3-base);
  }
  .v-icon:hover {
    color: var(--v-primary-base) !important;
  }
}
.no-peer-data {
  position: relative;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
    border-radius: 8px;
  }
}

.risk-model-events-container {
  height: 100%;
}
.risk-model-info {
  padding: 0.25rem 0.25rem 0 0;
  cursor: pointer;

  .v-icon {
    color: var(--v-black3-base);
  }
  .v-icon:hover {
    color: var(--v-primary-base) !important;
  }
}
.position-relative {
  position: relative;
}
.position-absolute {
  position: absolute;
  right: 0;
  top: 0;
}
.residuals-plot-container {
  height: 46%;
}
.events-plot-container {
  height: 46.5%;
}
.plot-heading {
  text-align: center;
}

.no-data {
  position: relative;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 8px;
  height: 100%;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
    border-radius: 8px;
  }
}

.residuals-container {
  padding: 0.5rem 0.5rem;
  height: 100%;
}

.grid-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto 350px;
  grid-gap: 0.5rem;
  margin-bottom: 1rem;
}
.grid2-row-1-col-1-2 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.grid2-row-2-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.grid2-row-2-col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}

.rotor-shaft {
  &__subtitle {
    p {
      font:
        0.75rem "Museo Sans Rounded",
        sans-serif;
      color: var(--v-text-base);
      font-weight: 600;
    }
  }

  &__header {
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 16px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }

  &__image {
    width: 100%;
    height: 100%;
    border: 1px solid var(--v-border-base);
    border-radius: 8px;
  }
}

.rotor-shaft-tabbed__section {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0;
  min-height: 150px;
}

.details-widget-container {
  padding: 1rem;
}
.explanation-list-item {
  list-style-type: none;
  padding-left: 0.75rem;
}

@media (max-width: 1264px) {
  .rotor-shaft {
    &__image {
      width: 100%;
      height: 300px;
      border: 1px solid var(--v-border-base);
      border-radius: 8px;
    }
  }
  .grid-1 {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 650px 650px;
    grid-gap: 0.5rem;
    margin-bottom: 0.5rem;
  }
}

.svg-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  max-width: inherit;
  max-height: 325px;
}
.svg-container svg {
  display: inline-block;
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 100%;
  z-index: 0;
}

.svg-text {
  fill: var(--v-text-base);
  font-family: "Museo", sans-serif;
  font-size: 1.5rem;
  text-anchor: middle;
}
</style>
