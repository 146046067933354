<template>
  <div>
    <div v-if="componentName === 'rotor shaft assembly'">
      <RotorShaftAssembly
        :siteName="siteName"
        :siteId="siteId"
        :nbmResidualsFigureData="nbmResidualsFigureData"
        :loadingNbmResidualsFigure="loadingNbmResidualsFigure"
        :eventsFigureData="eventsFigureData"
        :loadingEventsFigure="loadingEventsFigure"
        :peersFigureData="peersFigureData"
        :loadingPeersFigure="loadingPeersFigure"
        :mainBearingData="assetData"
        :turbineData="turbineData"
        :turbinesData="turbinesData"
        :modsByMainBearing="modsByAsset"
        :issuesByStatus="issuesByStatus"
        :rotorShaftComponents="subsystemComps"
        @helpIconClicked="handleHelpClick"
        @updateAtAGlance="updateAtAGlance"
        @goToExploreTab="goToExploreTab"
        @goToIssuesTab="goToIssuesTab"
        @goToIssuesTable="goToIssuesTable"
        @openInfoFlyout="openInfoFlyout"
      />
    </div>
    <div v-else-if="componentName === 'converter'">
      <ConverterDetail
        :siteName="siteName"
        :siteId="siteId"
        :converterData="assetData"
        :converterDescription="converterDescription"
        :turbineData="turbineData"
        :turbinesData="turbinesData"
        :modsByConverter="modsByAsset"
        :issuesByStatus="issuesByStatus"
        :analyticsFiguresData="analyticsFiguresData"
        :loadingAnalyticsFigures="loadingAnalyticsFigures"
        :converterRiskFactors="converterRiskFactors"
        :loadingConverterRiskFactors="loadingConverterRiskFactors"
        @helpIconClicked="handleHelpClick"
        @updateAtAGlance="updateAtAGlance"
        @goToIssuesTable="goToIssuesTable"
        @goToExploreTab="goToExploreTab"
      />
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import { mapActions, mapState } from "vuex";
import RotorShaftAssembly from "@/components/RotorShaftAssembly";
import ConverterDetail from "@/components/ConverterDetail";
import {
  rotorShaftDescription,
  converterDescription,
} from "@/helpers/variables";
import { roundToString } from "@/helpers/functions";
import dayjs from "dayjs";

export default {
  name: "SubsystemComponentDetail",
  components: {
    RotorShaftAssembly,
    ConverterDetail,
  },
  data() {
    return {
      tabs: [
        // {
        //   id: 1,
        //   name: "RECOMMENDATIONS",
        // },
        {
          id: 2,
          name: "ISSUES",
        },
        {
          id: 3,
          name: "EXPLORE",
        },
        {
          id: 4,
          name: "DESCRIPTION",
        },
        //   {
        //      id: 5,
        //      name: "MAIN BEARING",
        //     },
      ],
      activeTab: 2,
      isPowerCurveExplorerOpen: false,
      staticAnalyticsViewRequest: {},
      analyticsFiguresData: null,
      loadingAnalyticsFigures: false,
      nbmResidualsFigureData: null,
      loadingNbmResidualsFigure: false,
      eventsFigureData: null,
      loadingEventsFigure: false,
      peersFigureData: null,
      loadingPeersFigure: false,
      modsByMainBearing: [],
      modsByConverter: [],
      converterRiskFactors: null,
      loadingGetMods: false,
      anchors: [
        { x: 125, y: 200, label: "Hub" },
        { x: 300, y: 200, label: "Main Bearing" },
        { x: 450, y: 320, label: "Main Shaft" },
        { x: 700, y: 200, label: "Gearbox" },
        { x: 940, y: 320, label: "Coupling" },
        { x: 1150, y: 200, label: "Generator" },
      ],
      isEventsViewOpen: false,
      openExploreTab: false,
      converterDescription,
    };
  },
  computed: {
    ...mapState({
      clickCount: (state) => state.app.clickCount,
      userData: (state) => state.user.userData,
      loading: (state) => state.turbine.loading,
      turbineData: (state) => state.turbine.turbineData,
      turbinesData: (state) => state.site.turbinesData,
      turbinesLoading: (state) => state.site.loading.getTurbinesBySiteId,
      issuesByStatus: (state) => state.turbine.paginatedIssuesForIssuesTable,
      isInternal: (state) => state.user.userData.is_internal,
      mainBearingData: (state) => state.turbine.mainBearingData,
      converterData: (state) => state.turbine.converterData,
      subsystemComponents: (state) => state.turbine.subsystemComponents,
    }),
    componentName() {
      const componentName = this.$route.params.componentName;
      const formattedComponentName = componentName.replace(/[-_]/g, " ");
      return formattedComponentName;
    },
    assetName() {
      if (this.componentName === "main bearing") {
        return this.mainBearingData?.name ?? "Main Bearing";
      } else if (this.componentName === "converter") {
        return this.converterData?.name ?? "Converter";
      }
      return null;
    },
    assetConfigurationType() {
      if (this.componentName === "main bearing") {
        return this.mainBearingData?.model_name ?? "Unknown model";
      } else if (this.componentName === "converter") {
        return this.converterData?.model_name ?? "Unknown model";
      }
      return null;
    },
    assetData() {
      if (this.componentName === "main bearing") {
        return this.mainBearingData;
      } else if (this.componentName === "converter") {
        return this.converterData;
      }
      return null;
    },
    modsByAsset() {
      if (this.componentName === "main bearing") {
        return this.modsByMainBearing;
      } else if (this.componentName === "converter") {
        return this.modsByConverter;
      }
      return null;
    },
    siteName() {
      return this.turbineData?.farm
        ? this.turbineData.farm.name.toUpperCase()
        : "Site Name";
    },
    siteId() {
      return this.turbineData?.farm ? this.turbineData.farm.id : null;
    },
    targetTurbineOptions() {
      if (this.turbinesData?.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    issues() {
      if (this.issuesByStatus && this.isInternal) {
        return this.issuesByStatus;
      } else {
        return this.issuesByStatus.filter(
          (issue) =>
            !issue.current_status_def_name_external.includes("internal"),
        );
      }
    },
    mappedIssues() {
      if (this.issues.length > 0) {
        return this.issues.map((issue) => {
          return {
            id: issue.id,
            name: issue.name,
            description: issue.description,
            status: issue.current_status_def_name_external,
            current_status_def_id: issue.current_status_def_id,
            impact_mwh: roundToString(issue.aep_loss_mwhpyr, 0),
            impact_usd: roundToString(issue.aep_loss_upper_usdpyr, 0),
            ticketsInProgress: issue.work_order_ids,
            last_updated: dayjs(issue.current_status_created_ts).format(
              "YYYY-MM-DD",
            ),
          };
        });
      }
      return [];
    },
    assetDescription() {
      if (this.componentName.includes("rotor shaft")) {
        return rotorShaftDescription;
      } else if (this.componentName === "converter") {
        return this.converterHelpInfo;
      }
      return null;
    },
    converterHelpInfo() {
      return (
        this.converterDescription.description1 +
        "\n\n" +
        this.converterDescription.description2 +
        "\n\n" +
        this.converterDescription.description3
      );
    },
    subsystemComps() {
      if (this.subsystemComponents.length > 0) {
        const mappedComps = this.subsystemComponents.map((comp) => {
          return {
            name: comp.name,
            model: comp.model_name,
            oem: comp.serial_number,
            installation: comp.installation_date
              ? dayjs(comp.installation_date).format("YYYY-MM-DD")
              : null,
          };
        });
        return mappedComps;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      incrementClickCount: "app/incrementClickCount",
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
      getTurbineData: "turbine/getTurbine",
      getTurbinesBySiteId: "site/getTurbinesBySiteId",
      handleAxiosError: "error/raiseError",
      getIssuesByStatus: "turbine/getPaginatedIssuesForIssuesTable",
      getIssuesByType: "turbine/getChecks",
      sortChecks: "turbine/sortChecks",
      getMainBearingData: "turbine/getMainBearingData",
      getConverterData: "turbine/getConverterData",
      getSubsystemComponents: "turbine/getSubsystemComponents",
    }),
    handleHelpClick() {
      this.updateShowBurger(true);
      let name = "";
      if (this.componentName.includes(" ")) {
        const splitName = this.componentName.split(" ");
        name = splitName
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
      } else {
        name =
          this.componentName.charAt(0).toUpperCase() +
          this.componentName.slice(1);
      }
      if (name.toLowerCase().includes("rotor shaft")) {
        name = "Rotor-shaft Assembly";
      }
      const burgerData = {
        name,
        description: this.assetDescription,
      };
      this.updateBurgerData(burgerData);
    },
    updateAtAGlance() {
      this.refreshComments();
      this.updateStatusHistory();
    },
    goToExploreTab() {
      const siteId = this.turbineData.farm_id;
      if (
        siteId &&
        (siteId !== this.turbinesData[0]?.site_id ||
          this.turbinesData?.length === 0)
      ) {
        this.getTurbinesBySiteId(siteId);
      }
    },
    goToIssuesTab() {
      const turbineId = parseInt(this.$route.params.turbineId);
      if (this.issuesByStatus.length === 0) {
        const params = {
          turbineId,
          component_type: this.componentName.replaceAll(" ", "_"),
        };
        this.getIssuesByStatus(params);
      }
    },
    goToIssuesTable(payload) {
      this.$router.push({
        name: "SiteDashboard",
        params: {
          siteId: this.siteId,
        },
        query: payload === "default" ? null : payload,
        hash: "#issues",
      });
    },
    openInfoFlyout(plotType) {
      this.updateShowBurger(true);
      let name = "";
      let description = null;
      if (plotType === "peer_plot") {
        name = "Peers figure";
        description = "Peers figure description";
      } else if (plotType === "risk_model") {
        name = "Risk model and associated events";
        description = "Risk model and associated events description";
      }
      const burgerData = {
        name,
        description,
      };
      this.updateBurgerData(burgerData);
    },
    // API calls
    async getNbmResidualsFigure() {
      // Make this dynamic when more than just rotor shaft using nbm
      try {
        this.loadingNbmResidualsFigure = true;
        const turbineId = parseInt(this.$route.params.turbineId);
        const params = {
          component_def_id: 1,
        };

        const res = await axios.get(
          `/turbines/${turbineId}/plots/nbm-residuals`,
          {
            params,
          },
        );

        if (res.status === 200) {
          this.nbmResidualsFigureData = res.data;
        } else {
          this.nbmResidualsFigureData = null;
          const error = {
            name: "getNbmResidualsFigure",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.nbmResidualsFigureData = null;
        const err = {
          name: "getNbmResidualsFigure",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingNbmResidualsFigure = false;
    },
    // Make this dynamic when more than just rotor shaft using nbm
    async getEventsFigure() {
      try {
        this.loadingEventsFigure = true;
        const turbineId = parseInt(this.$route.params.turbineId);
        const params = {
          component_def_id: 1,
        };

        const res = await axios.get(`/turbines/${turbineId}/plots/nbm-events`, {
          params,
        });

        if (res.status === 200) {
          this.eventsFigureData = res.data;
        } else {
          this.eventsFigureData = null;
          const error = {
            name: "getEventsFigure",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.eventsFigureData = null;
        const err = {
          name: "getEventsFigure",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingEventsFigure = false;
    },
    // Make this more dynamic when more than just rotor shaft using nbm
    async getPeersFigure() {
      try {
        this.loadingPeersFigure = true;
        const turbineId = parseInt(this.$route.params.turbineId);
        const params = {
          component_def_id: 1,
        };

        const res = await axios.get(`/turbines/${turbineId}/plots/nbm-peers`, {
          params,
        });

        if (res.status === 200) {
          this.peersFigureData = res.data;
        } else {
          this.peersFigureData = null;
          const error = {
            name: "getPeersFigure",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.peersFigureData = null;
        const err = {
          name: "getPeersFigure",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingPeersFigure = false;
    },
    async getAnalyticsFigures() {
      try {
        this.loadingAnalyticsFigures = true;
        const turbineId = parseInt(this.$route.params.turbineId);

        const res = await axios.get(
          `/turbines/${turbineId}/plots/converter-igbt-temp-ts`,
        );

        if (res.status === 200) {
          this.analyticsFiguresData = res.data;
        } else {
          this.analyticsFiguresData = null;
          const error = {
            name: "getAnalyticsFigures",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.analyticsFiguresData = null;
        const err = {
          name: "getAnalyticsFigures",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingAnalyticsFigures = false;
    },
    async getConverterRiskFactors(turbineId) {
      try {
        this.loadingConverterRiskFactors = true;

        const res = await axios.get(`/turbines/${turbineId}/converter-status`);

        if (res.status === 200) {
          this.converterRiskFactors = res.data;
        } else {
          const error = {
            name: "getConverterRiskFactors",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        const err = {
          name: "getConverterRiskFactors",
          error,
        };
        this.handleAxiosError(err);
      }
      this.loadingConverterRiskFactors = false;
    },
    async getModsByComponent(payload) {
      try {
        this.loadingGetMods = true;
        const turbineId = payload.turbineId;
        const componentType = payload.component_type;
        const params = {
          component_type: componentType,
        };

        const res = await axios.get(`/turbines/${turbineId}/mods`, {
          params,
        });

        if (res.status === 200) {
          if (componentType === "main_bearing") {
            this.modsByMainBearing = res.data;
          } else {
            this.modsByConverter = res.data;
          }
        } else {
          const error = {
            name: "getModsByComponent",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.handleAxiosError(error);
      }
      this.loadingGetMods = false;
    },
  },
  beforeMount() {
    const turbineId = parseInt(this.$route.params.turbineId);
    // Only call for turbine data if no data or turbine id does not match route
    if (
      (this.turbineData && Object.keys(this.turbineData)?.length === 0) ||
      this.turbineData?.id !== turbineId
    ) {
      this.getTurbineData({
        turbineId: turbineId,
        recursive: 1,
      });
    }
    if (this.componentName === "rotor shaft assembly") {
      this.getNbmResidualsFigure();
      this.getEventsFigure();
      this.getPeersFigure();
    }
    if (this.componentName === "converter") {
      this.getAnalyticsFigures();
      this.getConverterRiskFactors(turbineId);
    }
    if (
      this.issuesByStatus.length === 0 ||
      !this.issuesByStatus.component_type ||
      this.issuesByStatus.turbine_id !== turbineId
    ) {
      let compName = this.componentName;
      if (this.componentName === "rotor shaft assembly") {
        compName = "main bearing";
      }
      this.getIssuesByStatus({
        turbineId,
        component_type: compName.replaceAll(" ", "_"),
      });
    }
    // Alter this to be dynamic based on the asset type
    if (
      this.componentName === "rotor shaft assembly" &&
      (this.modsByMainBearing.length === 0 ||
        this.modsByMainBearing[0].turbine_id !== turbineId)
    ) {
      this.getModsByComponent({ turbineId, component_type: "main_bearing" });
    } else if (
      this.componentName === "converter" &&
      (this.modsByConverter.length === 0 ||
        this.modsByConverter[0].turbine_id !== turbineId)
    ) {
      this.getModsByComponent({ turbineId, component_type: "converter" });
    }
    this.getSubsystemComponents({ turbineId, subsystem: this.componentName });
  },
  watch: {
    turbineData: {
      immediate: true,
      handler(data) {
        const turbineId = parseInt(this.$route.params.turbineId);
        if (
          data?.farm_id &&
          data?.id === turbineId &&
          this.componentName === "main bearing"
        ) {
          const params = {
            turbineId,
            siteId: data.farm_id,
          };
          this.getMainBearingData(params);
        }
      },
    },
    siteId: {
      immediate: true,
      handler(value) {
        const turbineId = parseInt(this.$route.params.turbineId);
        if (
          value &&
          this.componentName === "converter" &&
          (!this.converterData || this.converterData?.turbine_id !== turbineId)
        ) {
          const params = {
            turbineId,
            siteId: value,
          };
          this.getConverterData(params);
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped></style>
