<template>
  <div class="explorer-container">
    <section id="explorer-header">
      <div class="power-curve-turbine">
        <div class="turbine-input1">
          <label for="view-selection">Select view:</label>
          <v-autocomplete
            ref="viewSelection"
            name="view-selection"
            id="view-selection"
            content-class="pb-0"
            v-model="selectedView"
            :items="views"
            label="Select a view"
            hide-no-data
            hide-selected
            dense
            solo
            @change="changeViewSelected"
            @focus="clearError('date')"
          ></v-autocomplete>
        </div>
        <div class="turbine-input2">
          <label for="target-turbine">Target turbine:</label>
          <v-autocomplete
            ref="targetTurbineSelect"
            name="target-turbine"
            id="target-turbine"
            content-class="pb-0"
            hide-no-data
            hide-selected
            placeholder="Select target turbine"
            dense
            solo
            v-model="targetTurbineSelection"
            :items="targetTurbineOptions"
            @keyup.enter="handleKeyupEnterTargetTurbine"
            @blur="selectTargetTurbine"
          ></v-autocomplete>
        </div>
        <!-- Disabled if no target turbine has been chosen  -->
        <div class="turbine-input3">
          <label for="reference-turbine">Reference turbines:</label>
          <v-autocomplete
            ref="referenceTurbines"
            name="reference-turbine"
            id="reference-turbine"
            clearable
            content-class="pb-0"
            hide-no-data
            hide-selected
            placeholder="Select reference turbines"
            multiple
            dense
            solo
            :disabled="!targetTurbineSelection"
            v-model="referenceTurbineSelections"
            :items="referenceTurbineOptions"
            @keyup.enter="handleKeyupEnterRefTurbines"
            @blur="commitReferenceTurbines"
            @click:clear="clearReferenceTurbineSelections"
          ></v-autocomplete>
        </div>
        <div class="date-picker-container picker1">
          <label for="start-date">Start date:</label>
          <date-picker
            ref="start-date"
            type="date"
            name="start-date"
            id="start-date"
            placeholder="Start date"
            v-model="dateRange.startDate"
            class="datepicker"
            :clearable="false"
            :class="{
              'error-highlight': !isDateRangeValid,
            }"
            @blur="blurStartDate"
            @pick="handlePickStartDate"
          >
            <template v-slot:content="slotProps">
              <calendar-panel
                :disabled-date="
                  (date) => isDateDisabled(date, dateRange.endDate, 'startDate')
                "
                :value="slotProps.value"
                @select="slotProps.emit"
              ></calendar-panel>
            </template>
          </date-picker>
        </div>
        <div class="date-picker-container picker2">
          <label for="end-date">End date:</label>
          <date-picker
            ref="end-date"
            type="date"
            placeholder="End date"
            name="end-date"
            id="end-date"
            v-model="dateRange.endDate"
            class="datepicker"
            :class="{
              'error-highlight': !isDateRangeValid,
            }"
            :clearable="false"
            @blur="blurEndDate"
            @pick="handlePickEndDate"
          >
            <template v-slot:content="slotProps">
              <calendar-panel
                :disabled-date="
                  (date) => isDateDisabled(date, dateRange.startDate, 'endDate')
                "
                :value="slotProps.value"
                @select="slotProps.emit"
              ></calendar-panel>
            </template>
          </date-picker>
        </div>
      </div>
      <div class="date-error-grid-container">
        <div class="date-error" :class="{ 'has-error': !isDateRangeValid }">
          {{ dateErrorMsg }}
        </div>
      </div>
    </section>
    <!-- Grid layout -->
    <div class="images-container">
      <!-- Plotly figures and controls -->
      <div class="power-curve-images">
        <PowerCurveFigureCard
          :powerCurve10mData="powerCurve10m"
          :loading="loading.fetch10mPowerCurve"
          @powerCurve10mOptions="applyPowerCurve10mOptions"
          @plotlyPointClicked="callForTimeseries"
        />
      </div>
      <div class="power-curve-images">
        <XYPlotCard
          :xyPlotData="xyPlot"
          :loading="loading.fetchXYPlot"
          @xyPlotOptions="applyXYPlotOptions"
          @plotlyPointClicked="callForTimeseries"
        />
      </div>
      <div class="timeseries-plot-card">
        <TimeSeriesPlotCard
          :loading="loading.fetchTimeseriesPlots"
          :timeseriesPlots="timeseriesPlots"
          @timeseriesOptions="applyTimeseriesOptions"
        />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "@/helpers/axiosAPI";
import DatePicker from "vue2-datepicker";
const { CalendarPanel } = DatePicker;
import PowerCurveFigureCard from "../PowerCurveComponents/PowerCurveFigureCard";
import XYPlotCard from "../PowerCurveComponents/XYPlotCard";
import TimeSeriesPlotCard from "../PowerCurveComponents/TimeSeriesPlotCard";
import { mapActions, mapState } from "vuex";
import {
  mapTileLayerUrl,
  darkMapTileLayerUrl,
  clickCountLimit,
} from "@/helpers/variables";
import { gaTrackEvent } from "@/helpers/googleAnalyticsUtility";
import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
const utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
dayjs.extend(advancedFormat);

export default {
  name: "NewPowerCurveExplorer",
  components: {
    DatePicker,
    CalendarPanel,
    PowerCurveFigureCard,
    XYPlotCard,
    TimeSeriesPlotCard,
  },
  props: {
    targetTurbineOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
    loadingTurbines: {
      type: Boolean,
      required: false,
      default: false,
    },
    showMap: {
      type: Boolean,
      required: false,
      default: true,
    },
    siteId: {
      type: Number,
      required: false,
      default: null,
    },
    issueCreatedTs: {
      type: String,
      required: false,
      default: null,
    },
    issueStartDate: {
      type: String,
      required: false,
      default: null,
    },
    issueEndDate: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      powerCurve10mData: {},
      xyPlotData: {},
      loading: {
        fetch10mPowerCurve: false,
        fetchXYPlot: false,
        fetchTimeseriesPlots: false,
      },
      date: null,
      dateRange: {
        startDate: null,
        endDate: null,
      },
      targetTurbineSelection: null,
      referenceTurbineSelections: [],
      staticPowerCurveRequest: {
        turbine_id: null,
        start_ts: null,
        end_ts: null,
        ref_turbine_ids: null,
        preprocess: null,
        filter: null,
        color_col: null,
        annotate_turbine_id: null,
        annotate_ts: null,
        density_correction: false,
        contract_curve: false,
      },
      isDateRangeValid: true,
      isEndDateValid: true,
      isStartDateValid: true,
      dateErrorMsg: "",
      powerCurve10mOptions: {},
      xyPlotOptions: {},
      dataPointDetails: {},
      timeseriesPlots: {},
      timeseriesOptions: {},
      mapMarkers: [],
      selectedView: "power_curve_explorer",
      views: [
        { text: "Yaw misalignment", value: "yaw_misalignment" },
        { text: "Minimum pitch", value: "minimum_pitch" },
        { text: "Wind rose", value: "wind_rose" },
        { text: "Mod window suitability", value: "mod_window_suitability" },
        { text: "Power curve", value: "power_curve_explorer" },
        { text: "Events", value: "events" },
      ],
      current10mPowerCurveRequestId: null,
      currentXYPlotRequestId: null,
      currentTimeseriesRequestId: null,
    };
  },
  computed: {
    ...mapState({
      clickCount: (state) => state.app.clickCount,
    }),
    // Do not include the target turbine in the reference turbine options
    referenceTurbineOptions() {
      if (
        this.targetTurbineOptions.length > 0 &&
        !this.targetTurbineSelection
      ) {
        return this.targetTurbineOptions;
      } else if (
        this.targetTurbineOptions.length > 0 &&
        this.targetTurbineSelection
      ) {
        return this.targetTurbineOptions.filter(
          (option) => option.value !== this.targetTurbineSelection,
        );
      } else {
        return [];
      }
    },
    startDate() {
      if (this.dateRange.startDate) {
        return this.dateRange.startDate;
      } else {
        return null;
      }
    },
    endDate() {
      if (this.dateRange.endDate) {
        return this.dateRange.endDate;
      } else {
        return null;
      }
    },
    powerCurve10m() {
      return this.powerCurve10mData;
    },
    xyPlot() {
      return this.xyPlotData;
    },
    mapConfig() {
      if (this.mapMarkers.length > 0) {
        return {
          zoom: 10,
          center: [0, 0],
          url: this.$vuetify.theme.isDark
            ? darkMapTileLayerUrl
            : mapTileLayerUrl,
          markers: this.mapMarkers,
        };
      } else {
        return {};
      }
    },
    turbineCount() {
      // Establish the turbine count for date range limit
      let turbineCount = 0;
      if (
        this.targetTurbineSelection &&
        this.referenceTurbineSelections.length > 0
      ) {
        turbineCount = 1 + this.referenceTurbineSelections.length;
      } else if (
        this.targetTurbineSelection &&
        this.referenceTurbineSelections.length === 0
      ) {
        turbineCount = 1;
      }
      return turbineCount;
    },
    defaultDateRange() {
      const today = dayjs().utc();
      const thirtyDaysAgo = today.subtract(30, "day");
      let startDate = null;
      let endDate = null;
      const maxDays = Math.floor(500 / this.turbineCount);

      if (this.issueStartDate) {
        startDate = dayjs(this.issueStartDate).utc();
      } else {
        startDate = thirtyDaysAgo;
      }
      if (this.issueEndDate) {
        endDate = dayjs(this.issueEndDate).utc();
      } else {
        endDate = today;
      }

      let days = endDate.diff(startDate, "day");
      if (days > maxDays) {
        endDate = endDate.subtract(days - maxDays, "day");
      }

      return {
        start_date: startDate.toDate(),
        end_date: endDate.toDate(),
      };
    },
  },
  methods: {
    ...mapActions({
      handleAxiosError: "error/raiseError",
      incrementClickCount: "app/incrementClickCount",
    }),
    initialLoad() {
      const url = new URL(window.location.href);
      // Checking for all needed defaults
      const targetTurbineParam = url.searchParams.get("target_turbine");
      const startDateParam = url.searchParams.get("pc_start_date");
      const endDateParam = url.searchParams.get("pc_end_date");

      if (targetTurbineParam) {
        this.targetTurbineSelection = parseInt(targetTurbineParam);
        this.staticPowerCurveRequest.turbine_id = this.targetTurbineSelection;
      }

      if (startDateParam) {
        this.dateRange.startDate = dayjs(startDateParam).toDate();
        this.staticPowerCurveRequest.start_ts = dayjs(startDateParam).toDate();
      } else {
        this.dateRange.startDate = this.defaultDateRange.start_date;
        this.staticPowerCurveRequest.start_ts =
          this.defaultDateRange.start_date;
      }

      if (endDateParam) {
        this.dateRange.endDate = dayjs(endDateParam).toDate();
        this.staticPowerCurveRequest.end_ts = dayjs(endDateParam).toDate();
      } else {
        this.dateRange.endDate = this.defaultDateRange.end_date;
        this.staticPowerCurveRequest.end_ts = this.defaultDateRange.end_date;
      }
      this.setStartAndEndDateUrlParams(url);
      // Checking for all optional params for API calls
      let referenceTurbinesParam = [];
      if (url.searchParams.get("ref_turbine_id")) {
        url.searchParams.getAll("ref_turbine_id").forEach((id) => {
          referenceTurbinesParam.push(parseInt(id));
        });
        this.staticPowerCurveRequest.ref_turbine_ids = referenceTurbinesParam;
        this.referenceTurbineSelections = referenceTurbinesParam;
      }

      const params = {
        turbine_id: this.targetTurbineSelection,
        start_ts: this.dateRange.startDate,
        end_ts: this.dateRange.endDate,
        ref_turbine_id: this.referenceTurbineSelections,
      };

      // Once all url params have been checked and v-models populated, the APIs are called
      if (targetTurbineParam && this.referenceTurbineSelections.length === 0) {
        this.selectTargetTurbine(params.turbine_id, "initial");
      } else if (
        targetTurbineParam &&
        this.referenceTurbineSelections.length > 0
      ) {
        this.commitReferenceTurbines("initial");
      }

      history.replaceState(null, null, url);
    },
    handleKeyupEnterTargetTurbine() {
      this.$nextTick(() => {
        this.$refs["targetTurbineSelect"].blur();
      });
    },
    selectTargetTurbine(targetTurbine, mode) {
      let targetTurbineSelected = null;
      if (targetTurbine && typeof targetTurbine === "number") {
        targetTurbineSelected = targetTurbine;
      } else {
        if (!this.targetTurbineSelection) {
          this.targetTurbineSelection = this.staticPowerCurveRequest.turbine_id;
        }
        targetTurbineSelected = this.targetTurbineSelection;
      }
      const url = new URL(window.location.href);
      if (
        this.timeseriesPlots &&
        Object.keys(this.timeseriesPlots).length > 0
      ) {
        this.timeseriesPlots = {};
      }
      if (
        this.dataPointDetails &&
        Object.keys(this.dataPointDetails).length > 0
      ) {
        this.dataPointDetails = {};
      }
      if (
        this.staticPowerCurveRequest?.turbine_id !== targetTurbineSelected ||
        mode === "initial"
      ) {
        this.staticPowerCurveRequest.turbine_id = targetTurbineSelected;
        url.searchParams.set("target_turbine", targetTurbineSelected);
      } else {
        return;
      }
      if (this.startDate && this.endDate) {
        this.findMaxDateRange();
      }
      if (!this.isDateRangeValid) {
        return;
      }
      this.setStartAndEndDateUrlParams(url);
      // Call API with defaults of date range (start and end dates) and turbine id
      if (this.isDateRangeValid && this.staticPowerCurveRequest?.turbine_id) {
        const defaultParams = {
          turbine_id: targetTurbineSelected,
          start_date: this.startDate,
          end_date: this.endDate,
        };

        // Check for the 10m power curve options here
        const optional10mPCParams =
          this.setPlotlyFigureOptions("powerCurve10m");
        // Call for new or updated plotly 10m power curve figure
        this.fetch10mPowerCurve(defaultParams, optional10mPCParams);
        // Check default and optional params for xy plot
        if (Object.keys(this.xyPlotOptions).length > 0) {
          const params = {
            ...defaultParams,
            x_col: this.xyPlotOptions.x_col,
            y_col: this.xyPlotOptions.y_col,
          };
          const optionalXYParams = this.setPlotlyFigureOptions("xyPlot");
          // Call for new or updated plotly scatter figure
          this.fetchXYPlot(params, optionalXYParams);
        }
      }
      history.replaceState(null, null, url);
    },
    handleKeyupEnterRefTurbines() {
      this.$nextTick(() => {
        this.$refs["referenceTurbines"].blur();
      });
    },
    commitReferenceTurbines(mode) {
      const url = new URL(window.location.href);
      let defaultParams = {
        turbine_id: this.targetTurbineSelection,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      const referenceTurbines = this.referenceTurbineSelections;
      const arraysAreEqual = (arr1, arr2) => {
        if (arr1?.length !== arr2?.length) {
          return false;
        }
        for (let i = 0; i < arr1.length; i++) {
          if (arr1[i] !== arr2[i]) {
            return false;
          }
        }
        return true;
      };
      if (this.startDate && this.endDate) {
        this.findMaxDateRange();
      }
      if (
        referenceTurbines?.length > 0 &&
        this.isDateRangeValid &&
        this.targetTurbineSelection
      ) {
        const staticReferenceTurbines =
          this.staticPowerCurveRequest.ref_turbine_ids;
        // Make sure that a change was made to reference turbines before calling APIs
        if (
          !arraysAreEqual(referenceTurbines, staticReferenceTurbines) ||
          mode === "initial"
        ) {
          this.staticPowerCurveRequest.ref_turbine_ids = referenceTurbines;
          // Remove data point details and timeseries plots if change reference turbines
          if (
            this.timeseriesPlots &&
            Object.keys(this.timeseriesPlots).length > 0
          ) {
            this.timeseriesPlots = {};
          }
          if (
            this.dataPointDetails &&
            Object.keys(this.dataPointDetails).length > 0
          ) {
            this.dataPointDetails = {};
          }
          // Color the selected turbines blue, and any that don't match the targetTurbine grey
          for (const turbine of this.targetTurbineOptions) {
            if (this.referenceTurbineSelections.includes(turbine.value)) {
              if (turbine.color !== "blue") {
                turbine.color = "blue";
              }
            } else if (this.targetTurbineSelection !== turbine.value) {
              turbine.color = "grey";
            }
          }
          this.mapMarkers = this.targetTurbineOptions;
          // Check for params in url queries, if not present, add them
          if (url.searchParams.get("ref_turbine_id")) {
            url.searchParams.getAll("ref_turbine_id").forEach(() => {
              // Delete previous ref turbine id search params
              url.searchParams.delete("ref_turbine_id");
            });
          }
          for (const id of referenceTurbines) {
            url.searchParams.append("ref_turbine_id", id);
          }
          // Make sure that the start and end dates are in the url search params
          this.setStartAndEndDateUrlParams(url);
          // Call for both 10m and xy images
          this.callForPowerCurvePlots(defaultParams);
        } else {
          // Arrays do not differ, no API call needs to be made
          return;
        }
      } else if (
        referenceTurbines?.length === 0 &&
        this.isDateRangeValid &&
        this.targetTurbineSelection
      ) {
        if (url.searchParams.get("ref_turbine_id")) {
          url.searchParams.getAll("ref_turbine_id").forEach(() => {
            // Delete previous ref turbine id search params
            url.searchParams.delete("ref_turbine_id");
          });
        }
        // Remove data point details and timeseries plots if reference turbines are emptied
        if (
          this.timeseriesPlots &&
          Object.keys(this.timeseriesPlots).length > 0
        ) {
          this.timeseriesPlots = {};
        }
        if (
          this.dataPointDetails &&
          Object.keys(this.dataPointDetails).length > 0
        ) {
          this.dataPointDetails = {};
        }
        this.setStartAndEndDateUrlParams(url);
        // Make sure to only call for plots again if ref turbine ids selection has changed
        if (
          this.staticPowerCurveRequest?.ref_turbine_ids?.length > 0 &&
          referenceTurbines?.length === 0
        ) {
          this.callForPowerCurvePlots(defaultParams);
        }
        if (this.staticPowerCurveRequest?.ref_turbine_ids?.length > 0) {
          this.staticPowerCurveRequest.ref_turbine_ids = [];
        }
      }
      // Set url search bar query params
      history.replaceState(null, null, url);
    },
    clearReferenceTurbineSelections() {
      const url = new URL(window.location.href);
      // Color all turbines other than the target turbine grey
      for (const turbine of this.targetTurbineOptions) {
        if (turbine.value !== this.targetTurbineSelection) {
          turbine.color = "grey";
        }
      }
      // Delete ref turbine id search params
      if (url.searchParams.get("ref_turbine_id")) {
        url.searchParams.getAll("ref_turbine_id").forEach(() => {
          url.searchParams.delete("ref_turbine_id");
        });
      }
      // Set url search bar query params
      history.replaceState(null, null, url);
    },
    callForPowerCurvePlots(defaultParams) {
      let optionalParams = {};
      // Check for 10m curve options here
      optionalParams = this.setPlotlyFigureOptions("powerCurve10m");
      // Call for new or updated plotly 10m power curve figure
      this.fetch10mPowerCurve(defaultParams, optionalParams);

      // Check for xy plot options here
      if (Object.keys(this.xyPlotOptions).length > 0) {
        const params = {
          ...defaultParams,
          x_col: this.xyPlotOptions.x_col,
          y_col: this.xyPlotOptions.y_col,
        };
        const optionalXYParams = this.setPlotlyFigureOptions("xyPlot");
        // Call for new or updated plotly scatter figure(xy plot)
        this.fetchXYPlot(params, optionalXYParams);
      }
    },
    applyPowerCurve10mOptions(options) {
      const url = new URL(window.location.href);
      this.powerCurve10mOptions = options;
      if (this.startDate && this.endDate) {
        this.findMaxDateRange();
      }
      if (this.targetTurbineSelection && this.isDateRangeValid) {
        const defaultParams = {
          start_date: this.startDate,
          end_date: this.endDate,
          turbine_id: this.targetTurbineSelection,
        };
        const optionalParams = {
          density_correction: options.density,
          contract_curve: options.overlay,
        };
        // If a data point has been chosen, make sure its data is included in API calls
        if (
          this.dataPointDetails &&
          Object.keys(this.dataPointDetails).length > 0
        ) {
          let ts = this.dataPointDetails.annotate_ts.split("T");
          const annotateTs = `${ts[0]} ${ts[1]}`;
          if (this.targetTurbineOptions.length > 0) {
            for (const turbine of this.targetTurbineOptions) {
              if (turbine.text === this.dataPointDetails.annotate_turbine) {
                defaultParams.annotate_turbine_id = turbine.value;
                break;
              }
            }
          }
          defaultParams.annotate_ts = annotateTs;
        }
        if (options.color) {
          optionalParams.color_col = options.color;
        }
        if (this.referenceTurbineSelections.length > 0) {
          optionalParams.ref_turbine_id = this.referenceTurbineSelections;
        }
        // Set url search params
        history.replaceState(null, null, url);
        // Call for updated or new power curve 10m plotly figure
        this.fetch10mPowerCurve(defaultParams, optionalParams);
      }
    },
    applyXYPlotOptions(options) {
      this.xyPlotOptions = options;
      if (
        this.startDate &&
        this.endDate &&
        this.targetTurbineSelection &&
        this.startDate < this.endDate &&
        options.x_col &&
        options.y_col
      ) {
        const defaultParams = {
          start_date: this.startDate,
          end_date: this.endDate,
          turbine_id: this.targetTurbineSelection,
          x_col: options.x_col,
          y_col: options.y_col,
        };
        const optionalParams = {};
        if (options.color_col) {
          optionalParams.color_col = options.color_col;
        }
        // If a data point has been chosen, make sure its data is included in API calls
        if (
          this.dataPointDetails &&
          Object.keys(this.dataPointDetails).length > 0
        ) {
          let ts = this.dataPointDetails.annotate_ts.split("T");
          const annotateTs = `${ts[0]} ${ts[1]}`;
          if (this.targetTurbineOptions.length > 0) {
            for (const turbine of this.targetTurbineOptions) {
              if (turbine.text === this.dataPointDetails.annotate_turbine) {
                defaultParams.annotate_turbine_id = turbine.value;
                break;
              }
            }
          }
          defaultParams.annotate_ts = annotateTs;
        }
        if (this.referenceTurbineSelections.length > 0) {
          optionalParams.ref_turbine_id = this.referenceTurbineSelections;
        }
        // Call for updated or new xy plot plotly figure
        this.fetchXYPlot(defaultParams, optionalParams);
      }
    },
    applyTimeseriesOptions(params) {
      this.timeseriesOptions = params;
      if (this.dataPointDetails?.annotate_turbine) {
        if (params.signals.length === 0) {
          // Signals were emptied, empty timeseries plots
          this.timeseriesPlots = {};
          return;
        } else {
          this.callForTimeseries(this.dataPointDetails);
        }
      } else {
        return;
      }
    },
    setPlotlyFigureOptions(figureType) {
      const optionsObj = {};
      if (this.referenceTurbineSelections?.length > 0) {
        if (
          this.referenceTurbineSelections.includes(this.targetTurbineSelection)
        ) {
          const index = this.referenceTurbineSelections.indexOf(
            this.targetTurbineSelection,
          );
          if (index > -1) {
            this.referenceTurbineSelections.splice(index, 1);
          }
        }
        optionsObj.ref_turbine_id = this.referenceTurbineSelections;
      }
      if (
        figureType === "powerCurve10m" &&
        this.powerCurve10mOptions &&
        Object.keys(this.powerCurve10mOptions).length > 0
      ) {
        if (this.powerCurve10mOptions.color) {
          optionsObj.color_col = this.powerCurve10mOptions.color;
        }
        if ("overlay" in this.powerCurve10mOptions) {
          optionsObj.contract_curve = this.powerCurve10mOptions.overlay;
        }
        if ("density" in this.powerCurve10mOptions) {
          optionsObj.density_correction = this.powerCurve10mOptions.density;
        }
      } else if (
        figureType === "xyPlot" &&
        this.xyPlotOptions &&
        Object.keys(this.xyPlotOptions).length > 0
      ) {
        if (this.xyPlotOptions.color_col) {
          optionsObj.color_col = this.xyPlotOptions.color_col;
        }
      }

      return optionsObj;
    },
    setStartAndEndDateUrlParams(url) {
      if (!url.searchParams.get("pc_start_date")) {
        const formattedStartDate = dayjs(this.startDate).format("YYYY-MM-DD");
        url.searchParams.set("pc_start_date", formattedStartDate);
      }
      if (!url.searchParams.get("pc_end_date")) {
        const formattedEndDate = dayjs(this.endDate).format("YYYY-MM-DD");
        url.searchParams.set("pc_end_date", formattedEndDate);
      }
    },
    callForTimeseries(payload, action) {
      const turbineId = this.targetTurbineSelection;
      this.dataPointDetails = payload;
      if (
        turbineId &&
        this.startDate &&
        this.endDate &&
        this.startDate < this.endDate &&
        payload.annotate_ts &&
        this.timeseriesOptions.source &&
        this.timeseriesOptions.signals.length > 0 &&
        this.timeseriesOptions.timeWindow
      ) {
        this.loading.fetchTimeseriesPlots = true;
        let ts = payload.annotate_ts.split("T");
        const annotateTs = `${ts[0]} ${ts[1]}`;
        // Find the amount of minutes inside the time frame of this.timeseriesOptions.timeWindow
        const timeWindow = this.timeseriesOptions.timeWindow;
        const timeWindowSplit = timeWindow.split("_");
        const timeWindowNumber = timeWindowSplit[0];
        const timeWindowUnit = timeWindowSplit[1];
        const timeWindowInMinutes = this.findTimeWindowInMinutes(
          timeWindowNumber,
          timeWindowUnit,
        );
        // Find the start and end times for the timeseries API call
        const timeWindowStart = dayjs(annotateTs).subtract(
          timeWindowInMinutes / 2,
          "minute",
        );
        const timeWindowEnd = dayjs(annotateTs).add(
          timeWindowInMinutes / 2,
          "minute",
        );
        const params = {
          turbine_id: turbineId,
          annotate_ts: annotateTs,
          source: this.timeseriesOptions.source,
          signals: this.timeseriesOptions.signals,
          start_ts: dayjs(timeWindowStart).format("YYYY-MM-DD HH:mm:ss"),
          end_ts: dayjs(timeWindowEnd).format("YYYY-MM-DD HH:mm:ss"),
        };
        if (this.referenceTurbineSelections?.length > 0) {
          params.ref_turbine_id = this.referenceTurbineSelections;
        }
        params.start_date = this.startDate;
        params.end_date = this.endDate;
        this.fetchTimeseriesPlots(params);

        if (action === "point_clicked") {
          // Call for all figures again
          const defaultParams = {
            start_date: this.startDate,
            end_date: this.endDate,
            turbine_id: this.targetTurbineSelection,
            annotate_ts: annotateTs,
            x_col: this.xyPlotOptions.x_col,
            y_col: this.xyPlotOptions.y_col,
          };
          if (this.targetTurbineOptions.length > 0) {
            for (const turbine of this.targetTurbineOptions) {
              if (turbine.text === payload.annotate_turbine) {
                defaultParams.annotate_turbine_id = turbine.value;
                break;
              }
            }
          }
          let optionalParamsFor10m = {};
          let optionalParamsForXY = {};
          optionalParamsFor10m = this.setPlotlyFigureOptions("powerCurve10m");
          optionalParamsForXY = this.setPlotlyFigureOptions("xyPlot");
          this.fetch10mPowerCurve(defaultParams, optionalParamsFor10m);
          this.fetchXYPlot(defaultParams, optionalParamsForXY);
        }
        return;
      } else {
        return;
      }
    },
    findTimeWindowInMinutes(number, unit) {
      let num = number;
      if (typeof num !== "number") {
        num = parseInt(number);
      }
      const minutesPerHour = 60;
      const hoursPerDay = 24;
      let minutes = 0;
      if (unit.includes("hour")) {
        minutes = num * minutesPerHour;
      } else if (unit.includes("day")) {
        minutes = num * hoursPerDay * minutesPerHour;
      }
      return minutes;
    },
    isDateDisabled(dateFromPicker, comparisonDate, type) {
      const utcDate = dayjs(dateFromPicker).utc();
      const utcComparisonDate = dayjs(comparisonDate).utc();
      if (type === "startDate") {
        if (
          utcDate.isSame(utcComparisonDate) ||
          utcDate.isAfter(utcComparisonDate)
        ) {
          this.isStartDateValid = false;
          return true;
        } else {
          this.isStartDateValid = true;
          return false;
        }
      } else if (type === "endDate") {
        if (
          utcDate.isSame(utcComparisonDate) ||
          utcDate.isBefore(utcComparisonDate)
        ) {
          this.isEndDateValid = false;
          return true;
        } else {
          this.isEndDateValid = true;
          return false;
        }
      }
    },
    clearError(type) {
      if (
        type === "date" &&
        this.startDate &&
        this.endDate &&
        this.startDate < this.endDate
      ) {
        this.isDateRangeValid = true;
      } else {
        this.isDateRangeValid = false;
      }
    },
    blurStartDate() {
      const url = new URL(window.location.href);
      const computedEndDate = dayjs(this.endDate);
      if (!this.startDate) {
        const dayBeforeEndDate = computedEndDate.subtract(1, "day");
        this.dateRange.startDate = dayBeforeEndDate.toDate();
      }
      // Start date is populated, convert for comparisons
      const computedStartDate = dayjs(this.startDate);
      const staticStartDate = dayjs(this.staticPowerCurveRequest.start_ts);
      this.findMaxDateRange("Start date");
      // Date did not change from last API call, do nothing and return
      if (
        staticStartDate &&
        computedStartDate &&
        staticStartDate.isSame(computedStartDate)
      ) {
        return;
      } else {
        this.staticPowerCurveRequest.start_ts = this.startDate;
      }
      const formattedStarDate = dayjs(this.startDate).format("YYYY-MM-DD");
      url.searchParams.set("pc_start_date", formattedStarDate);
      const formattedEndDate = dayjs(this.endDate).format("YYYY-MM-DD");
      url.searchParams.set("pc_end_date", formattedEndDate);
      // Check for validity of default params
      if (
        this.targetTurbineSelection &&
        this.endDate &&
        this.startDate &&
        this.isDateRangeValid
      ) {
        const defaultParams = {
          start_date: this.startDate,
          end_date: this.endDate,
          turbine_id: this.targetTurbineSelection,
        };
        // Remove data point details and timeseries plots if new date chosen
        if (
          this.timeseriesPlots &&
          Object.keys(this.timeseriesPlots).length > 0
        ) {
          this.timeseriesPlots = {};
        }
        if (
          this.dataPointDetails &&
          Object.keys(this.dataPointDetails).length > 0
        ) {
          this.dataPointDetails = {};
        }

        this.callForPowerCurvePlots(defaultParams);
      }
      history.replaceState(null, null, url);
    },
    blurEndDate() {
      const url = new URL(window.location.href);
      const computedStartDate = dayjs(this.startDate);
      if (!this.endDate) {
        const dayAfterStartDate = computedStartDate.add(1, "day");
        this.dateRange.endDate = dayAfterStartDate.toDate();
      }
      // End date is populated, convert for comparisons
      const computedEndDate = dayjs(this.endDate);
      const staticEndDate = dayjs(this.staticPowerCurveRequest.end_ts);
      this.findMaxDateRange("End date");
      // Date did not change from last API call, do nothing and return
      if (
        staticEndDate &&
        computedEndDate &&
        staticEndDate.isSame(computedEndDate)
      ) {
        return;
      } else {
        this.staticPowerCurveRequest.end_ts = computedEndDate;
      }
      const formattedStarDate = dayjs(this.startDate).format("YYYY-MM-DD");
      url.searchParams.set("pc_start_date", formattedStarDate);
      const formattedEndDate = dayjs(this.endDate).format("YYYY-MM-DD");
      url.searchParams.set("pc_end_date", formattedEndDate);
      // Check for validity of default params
      if (
        this.targetTurbineSelection &&
        this.endDate &&
        this.startDate &&
        this.isDateRangeValid
      ) {
        const defaultParams = {
          start_date: this.startDate,
          end_date: this.endDate,
          turbine_id: this.targetTurbineSelection,
        };
        // Remove data point details and timeseries plots if new date chosen
        if (
          this.timeseriesPlots &&
          Object.keys(this.timeseriesPlots).length > 0
        ) {
          this.timeseriesPlots = {};
        }
        if (
          this.dataPointDetails &&
          Object.keys(this.dataPointDetails).length > 0
        ) {
          this.dataPointDetails = {};
        }

        this.callForPowerCurvePlots(defaultParams);
      }
      history.replaceState(null, null, url);
    },
    changeViewSelected(event) {
      const url = new URL(window.location.href);
      this.$emit("viewChanged", event);
      if (event !== "power_curve_explorer") {
        url.search = "";
        this.$emit("openPowerCurveExplorer", false, event);
      }
      // Click data for google analytics
      const eventLabel = `analytics_view_selected_${event}`;
      if (this.clickCount < clickCountLimit) {
        this.incrementClickCount();
        gaTrackEvent(this.$gtag, {
          eventName: "first_clicks_after_login",
          eventCategory: "user_interaction",
          eventLabel: eventLabel,
          value: this.clickCount,
        });
      }

      url.searchParams.set("analytics_view", event);
      history.replaceState(null, null, url);
    },
    findMaxDateRange(dateChanged) {
      let deadline = null;
      // Calculate the number of days between the start and end dates
      const days = dayjs(this.endDate).diff(this.startDate, "day");
      // Calculate the maximum number of days allowed based on the turbine number
      const maxDays = Math.floor(500 / this.turbineCount);
      // If the number of days exceeds the maximum or is negative, set isDateRangeValid to false
      if (days > maxDays) {
        this.isDateRangeValid = false;
        if (dateChanged === "Start date") {
          deadline = dayjs(this.endDate)
            .subtract(maxDays, "day")
            .format("YYYY-MM-DD");
        } else if (dateChanged === "End date") {
          deadline = dayjs(this.startDate)
            .add(maxDays, "day")
            .format("YYYY-MM-DD");
        }
        const suggestedDateChange = ` (${dateChanged}: ${deadline})`;
        this.dateErrorMsg = `Invalid date range, days can not exceed: ${maxDays}${
          deadline ? suggestedDateChange : ""
        }`;
      } else if (days < 0) {
        this.isDateRangeValid = false;
        this.dateErrorMsg = "Start date must be less than end date.";
      } else {
        this.isDateRangeValid = true;
        this.dateErrorMsg = "";
      }
    },
    // These methods handle when a date is picked from the calendar panels
    handlePickStartDate(event) {
      const url = new URL(window.location.href);
      let defaultParams = {
        turbine_id: this.targetTurbineSelection,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      if (this.startDate && this.endDate) {
        this.findMaxDateRange("Start date");
      }
      // If date does not change, return with no action taken
      if (
        this.staticPowerCurveRequest.start_ts &&
        event &&
        dayjs(this.staticPowerCurveRequest.start_ts).isSame(dayjs(event))
      ) {
        return;
      }
      // Set URL query params for start and end dates
      const formattedStartDate = dayjs(event).utc().format("YYYY-MM-DD");
      url.searchParams.set("pc_start_date", formattedStartDate);
      const formattedEndDate = dayjs(this.endDate).format("YYYY-MM-DD");
      url.searchParams.set("pc_end_date", formattedEndDate);
      if (
        this.isDateRangeValid &&
        this.targetTurbineSelection &&
        dayjs(this.staticPowerCurveRequest.start_ts) !== dayjs(event)
      ) {
        // Remove data point details and timeseries plots if new date chosen
        if (
          this.timeseriesPlots &&
          Object.keys(this.timeseriesPlots).length > 0
        ) {
          this.timeseriesPlots = {};
        }
        if (
          this.dataPointDetails &&
          Object.keys(this.dataPointDetails).length > 0
        ) {
          this.dataPointDetails = {};
        }
        this.staticPowerCurveRequest.start_ts = event;

        this.callForPowerCurvePlots(defaultParams);
      }
      history.replaceState(null, null, url);
    },
    handlePickEndDate(event) {
      const url = new URL(window.location.href);
      let defaultParams = {
        turbine_id: this.targetTurbineSelection,
        start_date: this.startDate,
        end_date: this.endDate,
      };
      if (this.startDate && this.endDate) {
        this.findMaxDateRange("End date");
      }
      // If date does not change, return with no action taken
      if (
        this.staticPowerCurveRequest.end_ts &&
        event &&
        dayjs(this.staticPowerCurveRequest.end_ts).isSame(dayjs(event))
      ) {
        return;
      }
      // Set URL query params for start and end dates
      const formattedStartDate = dayjs(this.startDate).format("YYYY-MM-DD");
      url.searchParams.set("pc_start_date", formattedStartDate);
      const formattedEndDate = dayjs(event).utc().format("YYYY-MM-DD");
      url.searchParams.set("pc_end_date", formattedEndDate);
      if (this.isDateRangeValid && this.targetTurbineSelection) {
        // Remove data point details and timeseries plots if new date chosen
        if (
          this.timeseriesPlots &&
          Object.keys(this.timeseriesPlots).length > 0
        ) {
          this.timeseriesPlots = {};
        }
        if (
          this.dataPointDetails &&
          Object.keys(this.dataPointDetails).length > 0
        ) {
          this.dataPointDetails = {};
        }
        this.staticPowerCurveRequest.end_ts = event;

        this.callForPowerCurvePlots(defaultParams);
      }
      history.replaceState(null, null, url);
    },
    // API calls
    async fetch10mPowerCurve(defaultParams, optionalParams) {
      // Set a unique id for each request and save in a data variable
      const currentRequestId = new Date().getTime();
      this.current10mPowerCurveRequestId = currentRequestId;
      try {
        this.loading.fetch10mPowerCurve = true;
        this.powerCurve10mData = {};
        const turbineId = defaultParams.turbine_id;
        let refTurbineIdsString = "";
        let url = `/turbines/${turbineId}/plots/10m-power-curve`;
        const params = {
          start_ts: dayjs(defaultParams.start_date).format("YYYY-MM-DD"),
          end_ts: dayjs(defaultParams.end_date).format("YYYY-MM-DD"),
        };
        params.start_ts = params.start_ts + " 00:00:00";
        params.end_ts = params.end_ts + " 00:00:00";
        if (defaultParams.annotate_turbine_id && defaultParams.annotate_ts) {
          params.annotate_turbine_id = defaultParams.annotate_turbine_id;
          params.annotate_ts = defaultParams.annotate_ts;
        }
        if (optionalParams?.ref_turbine_id) {
          optionalParams.ref_turbine_id.forEach((id) => {
            refTurbineIdsString += `&ref_turbine_id=${id}`;
          });
          if (refTurbineIdsString) {
            url = `/turbines/${turbineId}/plots/10m-power-curve?${refTurbineIdsString}`;
          }
        }
        if (optionalParams?.color_col) {
          params.color_col = optionalParams.color_col;
        }
        if ("contract_curve" in optionalParams) {
          params.contract_curve = optionalParams.contract_curve;
        }
        if ("density_correction" in optionalParams) {
          params.density_correction = optionalParams.density_correction;
        }

        const res = await axios.get(url, {
          params,
        });

        if (res.status === 200) {
          // Only save response if id is for latest request
          if (this.current10mPowerCurveRequestId === currentRequestId) {
            this.powerCurve10mData = res.data;
          }
        } else {
          this.powerCurve10mData = {};
          const error = {
            name: "fetch10mPowerCurve",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.powerCurve10mData = {};
        const err = {
          name: "fetch10mPowerCurve",
          error,
        };
        this.handleAxiosError(err);
      }
      if (this.current10mPowerCurveRequestId === currentRequestId) {
        this.loading.fetch10mPowerCurve = false;
      }
    },
    async fetchXYPlot(defaultParams, optionalParams) {
      const currentRequestId = new Date().getTime();
      this.currentXYPlotRequestId = currentRequestId;
      try {
        this.loading.fetchXYPlot = true;
        this.xyPlotData = {};
        const turbineId = defaultParams.turbine_id;
        let refTurbineIdsString = "";
        let url = `/turbines/${turbineId}/plots/10m-scatter`;
        const params = {
          start_ts: dayjs(defaultParams.start_date).format("YYYY-MM-DD"),
          end_ts: dayjs(defaultParams.end_date).format("YYYY-MM-DD"),
          x_col: defaultParams.x_col,
          y_col: defaultParams.y_col,
        };
        if (defaultParams.annotate_turbine_id && defaultParams.annotate_ts) {
          params.annotate_turbine_id = defaultParams.annotate_turbine_id;
          params.annotate_ts = defaultParams.annotate_ts;
        }
        params.start_ts = params.start_ts + " 00:00:00";
        params.end_ts = params.end_ts + " 00:00:00";
        if (optionalParams?.ref_turbine_id) {
          optionalParams.ref_turbine_id.forEach((id) => {
            refTurbineIdsString += `&ref_turbine_id=${id}`;
          });
          if (refTurbineIdsString) {
            url = `/turbines/${turbineId}/plots/10m-scatter?${refTurbineIdsString}`;
          }
        }
        if (optionalParams.color_col) {
          params.color_col = optionalParams.color_col;
        }

        const res = await axios.get(url, {
          params,
        });

        if (res.status === 200) {
          // Check the latest request id and only populate object if ids match
          if (this.currentXYPlotRequestId === currentRequestId) {
            this.xyPlotData = res.data;
          }
        } else {
          this.xyPlotData = {};
          const error = {
            name: "fetchXYPlot",
            message: "Error API call",
            value: "unknown error",
          };
          this.handleAxiosError(error);
        }
      } catch (error) {
        this.xyPlotData = {};
        if (error.message !== "Request aborted") {
          const err = {
            name: "fetchXYPlot",
            error,
          };
          this.handleAxiosError(err);
        }
      }
      if (this.currentXYPlotRequestId === currentRequestId) {
        this.loading.fetchXYPlot = false;
      }
    },
    async fetchTimeseriesPlots(payload) {
      const currentRequestId = new Date().getTime();
      this.currentTimeseriesRequestId = currentRequestId;
      try {
        this.timeseriesPlots = {};
        let res = null;
        if (
          payload.annotate_ts &&
          payload.signals.length > 0 &&
          payload.start_ts &&
          payload.end_ts
        ) {
          const turbineId = payload.turbine_id;
          let signalsString = "";
          let refTurbineIdsString = "";
          const params = {
            annotate_ts: payload.annotate_ts,
            // Format start_ts and end_ts the same way as annotate_ts
            start_ts: payload.start_ts,
            end_ts: payload.end_ts,
          };
          // Checking for optional params
          if (payload.ref_turbine_id?.length > 0) {
            payload.ref_turbine_id.forEach((id) => {
              refTurbineIdsString += `&ref_turbine_id=${id}`;
            });
          }
          // Call either 10m power curve or 10m scatter(xy plot)
          if (payload.source === "10m") {
            payload.signals.forEach((signal) => {
              signalsString += `&col=${signal}`;
            });

            res = await axios.get(
              `/turbines/${turbineId}/plots/10m-ts?${signalsString}${refTurbineIdsString}`,
              {
                params,
              },
            );
          } else if (payload.source === "hf") {
            for (let item of payload.signals) {
              if (item.includes("_mn")) {
                item = item.substring(0, item.length - 3);
                signalsString += `&col=${item}`;
              }
            }

            res = await axios.get(
              `/turbines/${turbineId}/plots/hf-ts?${signalsString}${refTurbineIdsString}`,
              {
                params,
              },
            );
          }
          if (res.status === 200) {
            // Check for latest id, if a match with current id, populate object
            if (this.currentTimeseriesRequestId === currentRequestId) {
              this.timeseriesPlots = res.data;
            }
          } else {
            this.timeseriesPlots = {};
            const error = {
              name: "fetchTimeseriesPlots",
              message: "Error API call",
              value: "unknown error",
            };
            this.handleAxiosError(error);
          }
        }
      } catch (error) {
        this.timeseriesPlots = {};
        const err = {
          name: "fetchTimeseriesPlots",
          error,
        };
        this.handleAxiosError(err);
      }
      if (this.currentTimeseriesRequestId === currentRequestId) {
        this.loading.fetchTimeseriesPlots = false;
      }
    },
  },
  mounted() {
    // Prepopulate inputs with url search params and make initial API calls
    this.initialLoad();
  },
  watch: {
    issueCreatedTs: {
      immediate: true,
      handler(value) {
        if (value && this.turbineCount) {
          const maxDays = Math.floor(500 / this.turbineCount);
          const startDate = value.substring(0, 10);
          const today = this.getTodayInLocalTime;
          this.dateRange.startDate = startDate;
          let endDate = dayjs(startDate)
            .add(maxDays, "day")
            .format("YYYY-MM-DD");

          if (endDate > today) {
            endDate = today;
          }
          this.dateRange.endDate = endDate;
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.explorer-container {
  label {
    margin-bottom: 0.125rem;
  }
}

#explorer-header {
  position: relative;
  padding-top: 0.5rem;

  h6 {
    min-width: 6.25rem;
    margin-top: 1.85rem;
  }
}

::v-deep {
  .power-curve-turbine {
    display: grid;
    grid-template-columns: 1fr 1fr 1.25fr 1fr 1fr;
    grid-template-rows: auto;
    gap: 1.5rem;

    .v-input__slot,
    .v-text-field__details {
      margin-bottom: 0;
    }
  }
}

.date-error-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1.25fr 1fr 1fr;
  column-gap: 1.5rem;
  color: var(--v-error-base);
}

.date-error {
  grid-column: 4 / span 2;
  text-align: center;
  visibility: hidden;
}

.has-error {
  visibility: visible;
}

::v-deep {
  .date-picker-container {
    .mx-datepicker {
      width: 100%;
    }
  }
}

.images-container {
  display: grid;
  grid-template-rows: 32rem 45.25rem;
  grid-template-columns: 1fr 1fr;
  gap: 0.5rem;
}

.timeseries-plot-card {
  grid-row: 2;
  grid-column: 1/ 3;
}

.power-curve-images {
  height: 100%;
}

::v-deep {
  .error-highlight {
    .mx-input {
      border: 2px solid var(--v-error-base);
    }
  }
}

@media (max-width: 90.625rem) {
  .images-container {
    display: block;
  }

  .timeseries-plot-card {
    margin-top: 0.65rem;
  }
}

@media (max-width: 79rem) {
  .date-picker-container {
    padding: 0;
  }

  .power-curve-turbine {
    grid-template-rows: auto;
    grid-template-columns: 1fr 1fr 1fr;
  }

  .picker1 {
    grid-column: 2;
  }

  .picker2 {
    grid-column: 3;
  }

  .date-error-grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    column-gap: 1.5rem;
    color: var(--v-error-base);
  }

  .date-error {
    grid-column: 2 / span 2;
    padding-top: 0.75rem;
  }
}
</style>
