import { render, staticRenderFns } from "./ConverterDetail.vue?vue&type=template&id=1b39028e&scoped=true&"
import script from "./ConverterDetail.vue?vue&type=script&lang=js&"
export * from "./ConverterDetail.vue?vue&type=script&lang=js&"
import style0 from "./ConverterDetail.vue?vue&type=style&index=0&id=1b39028e&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1b39028e",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
installComponents(component, {VIcon})
