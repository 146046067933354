<template>
  <div class="issues-tab-container">
    <table class="static-length">
      <thead>
        <tr>
          <th>
            <div>Recommendations</div>
          </th>
          <th>
            <div>Completed actions</div>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="item in paginatedItems" :key="item.id">
          <td>
            <div>{{ item.action }}</div>
          </td>
          <td>
            <div>{{ item.completed_ts }}</div>
          </td>
        </tr>
      </tbody>
      <tfoot class="table-footer">
        <tr>
          <td colspan="6">
            <div class="pagination-expression">
              <div
                class="d-flex align-center mr-6"
                @click="goToPreviousPage"
                :class="{ 'previous-disabled': currentPage === 1 }"
              >
                <v-icon :disabled="currentPage === 1">mdi-chevron-left</v-icon>
                <div>PREVIOUS</div>
              </div>

              <div
                v-for="page in paginationRange"
                :key="page"
                class="page-number"
              >
                <span
                  class="mr-1"
                  :class="{ 'active-page': page === currentPage }"
                  @click="goToPage(page)"
                  >{{ page }}</span
                >
              </div>

              <div class="d-flex align-center ml-6" @click="goToNextPage">
                <div :class="{ 'next-disabled': isLastPage }">NEXT</div>
                <v-icon :disabled="isLastPage">mdi-chevron-right</v-icon>
              </div>
            </div>
            <div
              class="d-flex justify-center align-center page-range ml-6 mt-1"
              v-if="currentPage <= totalPages && totalPages > 5"
            >
              <div class="mr-2">
                <input
                  type="number"
                  id="page-input"
                  min="1"
                  :value="currentPage"
                  @blur="validatePageInput"
                  @keypress.enter="validatePageInput"
                />
              </div>
              <p class="ma-0 mr-1">of</p>
              <div class="ml-1">
                {{ totalPages }}
              </div>
            </div>
          </td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
export default {
  name: "ConverterRecommendationsTab",
  props: {
    recommendations: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      currentPage: 1,
      rowsPerPage: 5,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
    totalItems() {
      // Use real data when APIs available
      return this.recommendations?.length;
    },
    paginatedItems() {
      let startIndex = (this.currentPage - 1) * this.rowsPerPage;
      let endIndex = startIndex + this.rowsPerPage;
      // Using issues array use similar logic as below
      if (this.recommendations?.length > 0) {
        return this.recommendations.slice(startIndex, endIndex);
      }
      return [];
    },
    paginationRange() {
      let start = Math.max(this.currentPage - 2, 1);
      let end = Math.min(start + 4, this.totalPages);

      if (this.totalPages - this.currentPage < 2) {
        start = Math.max(this.totalPages - 4, 1);
        end = this.totalPages;
      }

      let range = [];
      for (let i = start; i <= end; i++) {
        range.push(i);
      }

      return range;
    },
    isLastPage() {
      if (!this.rowsPerPage || this.recommendations?.length == 0) {
        return false;
      } else if (
        Math.ceil(this.recommendations?.length / this.rowsPerPage) ===
        this.currentPage
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    goToPreviousPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    goToNextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    goToPage(pageNumber) {
      if (pageNumber && pageNumber >= 1 && pageNumber <= this.totalPages) {
        this.currentPage = pageNumber;
      }
    },
    validatePageInput(event) {
      const pageValue = parseInt(event.target.value);
      if (pageValue && Number.isInteger(pageValue)) {
        this.goToPage(pageValue);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.issues-tab-container {
  position: relative;
  padding: 1rem 1rem 0 1rem;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

table {
  border-collpase: collapse;
  width: 100%;
}
.static-length {
  min-height: 150px;
}
th,
td {
  text-align: left;
  padding: 8px 8px 8px 1rem;
}
th {
  padding-bottom: 0.75rem;
  border-bottom: 1px solid var(--v-converterBorders-base); // Change to rgb(var(--v-theme-mediumGray))
  border-right: 1px solid var(--v-converterBorders-base);
}
th:last-child {
  border-right: none;
}
tr {
  border-bottom: 1px dotted var(--v-converterBorders-base); // Change to rgb(var(--v-theme-mediumGray))
}
tr td {
  border-right: 1px solid var(--v-converterBorders-base);
}
tr td:last-child {
  border-right: none;
}
tr:last-child {
  border-bottom: 1px solid var(--v-converterBorders-base);
}
.table-footer tr,
.table-footer tr td {
  border: none;
}
.table-footer td {
  padding-top: 1rem;
}

.pagination-expression {
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.page-number {
  margin: 0 5px;
  font-size: 1rem;
  cursor: pointer;
}
.active-page {
  font-weight: bold;
  color: var(--v-primary-base);
}
#page-input {
  outline: none;
  border: 1px solid var(--v-converterBorders-base);
  border-radius: 4px;
  width: 2.5rem;
  text-align: center;
  color: white;
}
#page-input::-webkit-outer-spin-button,
#page-input::-webkit-inner-spin-button {
  margin: 0;
  appearance: none;
}
#page-input[type="number"] {
  -moz-appearance: textfield;
}
.previous-disabled,
.next-disabled {
  color: rgba(242, 242, 242, 0.5);
}
</style>
