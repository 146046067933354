<template>
  <div
    :class="
      $vuetify.theme.dark ? 'xy-plot-container-dark' : 'xy-plot-container-light'
    "
  >
    <div class="pitch-image-controls">
      <div class="d-flex align-center justify-center">
        <div class="d-flex justify-space-between">
          <div class="predefined-curves-control mr-4">
            <label for="predefined-curves">Predefined curves:</label>
            <v-autocomplete
              name="predefined-curves"
              id="predefined-curves"
              content-class="pb-0"
              hide-no-data
              hide-selected
              hide-details
              placeholder="Select..."
              dense
              solo
              return-object
              :items="predefinedCurves"
              v-model="curveSelection"
              @change="setPredefinedCurves"
            ></v-autocomplete>
            <div>
              <p class="operator mt-1 mb-1">Or specify</p>
            </div>
          </div>
          <div class="color-column-control ml-4">
            <label for="color-column">Color column:</label>
            <v-autocomplete
              name="color-column"
              id="color-column"
              content-class="pb-0"
              hide-no-data
              hide-selected
              hide-details
              placeholder="Select..."
              dense
              solo
              :items="signalOptions"
              v-model="colorSelection"
              @change="emitXYPlotOptions"
            ></v-autocomplete>
          </div>
        </div>
      </div>
      <div class="d-flex justify-center specify-coords-container mr-1">
        <div class="d-flex x-input mr-4">
          <label for="specify-x" class="mt-2 mr-2">X:</label>
          <v-autocomplete
            name="specify-x"
            id="specify-x"
            content-class="pb-0"
            hide-no-data
            hide-selected
            hide-details
            placeholder="Select..."
            dense
            solo
            return-object
            :items="xOptions"
            v-model="xSelection"
            @change="setXOrY($event, 'x')"
          ></v-autocomplete>
        </div>
        <div class="d-flex y-input">
          <label for="specify-y" class="mt-2 mr-2">Y:</label>
          <v-autocomplete
            name="specify-y"
            id="specify-y"
            content-class="pb-0"
            hide-no-data
            hide-selected
            hide-details
            placeholder="Select..."
            dense
            solo
            return-object
            :items="yOptions"
            v-model="ySelection"
            @change="setXOrY($event, 'y')"
          ></v-autocomplete>
        </div>
      </div>
    </div>
    <div class="pitch-image" ref="scatterPlotContainer">
      <div
        :class="
          $vuetify.theme.dark ? 'plotly-figure-dark' : 'plotly-figure-light'
        "
      >
        <Plotly
          v-if="xyPlotFigure && Object.keys(xyPlotFigure).length > 0"
          :data="xyPlotFigure.data"
          :layout="xyPlotFigure.layout"
          :responsive="true"
          @plotlyPointClicked="handlePlotlyPointClicked"
        ></Plotly>
        <div
          v-if="
            (!xyPlotData || Object.keys(xyPlotData).length === 0) && !loading
          "
          class="no-data-available no-data"
        >
          NO DATA AVAILABLE
        </div>
        <div
          class="d-flex justify-center align-center plotly-figure-light__spinner"
          v-if="loading"
        >
          <v-progress-circular
            :size="50"
            color="primary"
            indeterminate
          ></v-progress-circular>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Plotly from "@/components/Plotly";

export default {
  name: "XYPlotCard",
  components: {
    Plotly,
  },
  props: {
    xyPlotData: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      predefinedCurves: [
        {
          text: "Pitch vs. Power",
          value: "pitch_vs_power",
          x: "realpower_kw_mn",
          y: "pitchangleblade1_deg_mn",
        },
        {
          text: "Rotor speed vs. Power",
          value: "rotor_speed_vs_power",
          x: "realpower_kw_mn",
          y: "rotorspeed_rpm_mn",
        },
        {
          text: "Rotor speed vs. Wind speed",
          value: "rotor_speed_vs_wind_speed",
          x: "windspeed_mps_mn",
          y: "rotorspeed_rpm_mn",
        },
      ],
      signalOptions: [
        {
          text: "Ambient temperature",
          value: "ambienttemp_degc_mn",
        },
        {
          text: "Blade 1 pitch",
          value: "pitchangleblade1_deg_mn",
          curve: "pitch_vs_power",
        },
        {
          text: "Generator speed",
          value: "generatorspeed_rpm_mn",
        },
        {
          text: "Nacelle direction",
          value: "nacelledirection_deg_mn",
        },
        {
          text: "Power",
          value: "realpower_kw_mn",
          curve: ["pitch_vs_power", "rotor_speed_vs_power"],
        },
        {
          text: "Rotor speed",
          value: "rotorspeed_rpm_mn",
          curve: ["rotor_speed_vs_power", "rotor_speed_vs_wind_speed"],
        },
        {
          text: "Wind direction",
          value: "winddirection_deg_mn",
        },
        {
          text: "Wind speed",
          value: "windspeed_mps_mn",
          curve: "rotor_speed_vs_wind_speed",
        },
        {
          text: "Yaw error",
          value: "yawerror_deg_mn",
        },
      ],
      curveSelection: {},
      xSelection: {},
      ySelection: {},
      colorSelection: "",
    };
  },
  computed: {
    xyPlotFigure() {
      if (this.xyPlotData && Object.keys(this.xyPlotData).length > 0) {
        return this.setXYPlotStyle(this.xyPlotData);
      } else {
        return null;
      }
    },
    xOptions() {
      if (this.signalOptions.length > 0 && this.ySelection) {
        return this.signalOptions.filter(
          (option) => option.value !== this.ySelection.value,
        );
      } else {
        return [];
      }
    },
    yOptions() {
      if (this.signalOptions.length > 0 && this.ySelection) {
        return this.signalOptions.filter(
          (option) => option.value !== this.xSelection.value,
        );
      } else {
        return [];
      }
    },
  },
  methods: {
    setXYPlotOptions() {
      // Apply initial url params check and assignment here
      this.curveSelection = this.predefinedCurves[0]; // Pitch vs. Power
      this.xSelection = this.signalOptions[4]; // Power
      this.ySelection = this.signalOptions[1]; // Pitch angle blade 1
      this.colorSelection = "";
      this.emitXYPlotOptions();
    },
    setXYPlotStyle(plotData) {
      // eslint-disable-next-line no-undef
      const clonedXYPlotData = structuredClone(plotData);
      clonedXYPlotData.layout.autosize = true;
      clonedXYPlotData.layout.height = 355;
      if (this.$vuetify.theme.isDark) {
        clonedXYPlotData.layout.paper_bgcolor = "#1e1e1e";
        clonedXYPlotData.layout.font = {
          color: "#fff",
        };
      }
      return clonedXYPlotData;
    },
    setPredefinedCurves(event) {
      if (event.value === "pitch_vs_power") {
        this.xSelection = {
          text: "realpower_kw_mn",
          value: "realpower_kw_mn",
          curve: ["pitch_vs_power", "rotor_speed_vs_power"],
        };
        this.ySelection = {
          text: "pitchangleblade1_deg_mn",
          value: "pitchangleblade1_deg_mn",
          curve: "pitch_vs_power",
        };
      }
      if (event.value === "rotor_speed_vs_power") {
        this.xSelection = {
          text: "realpower_kw_mn",
          value: "realpower_kw_mn",
          curve: ["pitch_vs_power", "rotor_speed_vs_power"],
        };
        this.ySelection = {
          text: "rotorspeed_rpm_mn",
          value: "rotorspeed_rpm_mn",
          curve: ["rotor_speed_vs_power", "rotor_speed_vs_wind_speed"],
        };
      }
      if (event.value === "rotor_speed_vs_wind_speed") {
        this.xSelection = {
          text: "windspeed_mps_mn",
          value: "windspeed_mps_mn",
          curve: "rotor_speed_vs_wind_speed",
        };
        this.ySelection = {
          text: "rotorspeed_rpm_mn",
          value: "rotorspeed_rpm_mn",
          curve: ["rotor_speed_vs_power", "rotor_speed_vs_wind_speed"],
        };
      }

      this.emitXYPlotOptions();
    },
    setXOrY(event, type) {
      if (type === "x" && Array.isArray(event.curve)) {
        if (
          this.curveSelection.value !== event.curve[0] &&
          this.curveSelection.value !== event.curve[1]
        ) {
          this.curveSelection = {};
        }
      } else if (type === "x") {
        if (this.curveSelection !== event.curve) {
          this.curveSelection = {};
        }
      }
      if (type === "y" && Array.isArray(event.curve)) {
        if (
          this.curveSelection.value !== event.curve[0] &&
          this.curveSelection !== event.curve[1]
        ) {
          this.curveSelection = {};
        }
      } else if (type === "y") {
        if (this.curveSelection !== event.curve) {
          this.curveSelection = {};
        }
      }

      this.emitXYPlotOptions();
    },
    emitXYPlotOptions() {
      const params = {
        x_col: this.xSelection.value,
        y_col: this.ySelection.value,
        color_col: this.colorSelection,
      };

      this.$emit("xyPlotOptions", params);
    },
    handlePlotlyPointClicked(payload) {
      const params = {
        annotate_ts: payload.annotate_ts,
        annotate_turbine: payload.annotate_turbine,
        plot_type: "xyPlot",
      };

      this.$emit("plotlyPointClicked", params, "point_clicked");
    },
  },
  mounted() {
    this.setXYPlotOptions();
  },
};
</script>

<style lang="scss" scoped>
.xy-plot-container-light,
.xy-plot-container-dark {
  border-radius: 8px;
  width: 100%;
  padding: 0.25rem;
  display: grid;
  height: 515px;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: 1fr;
}

.xy-plot-container-light {
  border: 1px solid var(--v-pcBorderColor-base);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.xy-plot-container-dark {
  border: 1px solid var(--v-pcSubtleBorderColor-base);
  box-shadow: 1px 1px 2px 0 rgba(30, 30, 30, 0.4);
}

.pitch-image {
  padding: 0.25rem 0.25rem 0.25rem 0.25rem;
  height: 100%;
}

.plotly-figure-light,
.plotly-figure-dark {
  position: relative;
  min-height: 355px;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--v-pcSpinnerOverlay-base);
    opacity: 0.4;
    z-index: 100;
  }
}

.plotly-figure-light {
  border: 1px solid var(--v-pcBorderColor-base);
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);
}

.plotly-figure-dark {
  box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.6);
}

.pitch-image-controls {
  padding: 0.25rem;
}

::v-deep {
  .predefined-curves-control {
    width: 255px;

    .v-text-field__details {
      margin: 0;
    }
  }
}

label {
  margin-bottom: 0.125rem;
}

.operator {
  text-align: center;
}

.specify-coords-container {
  margin-left: -50px;
}

.x-input,
.y-input {
  width: 225px;
}

::v-deep {
  .color-column-control {
    width: 255px;
    .v-text-field__details {
      margin: 0;
    }
  }
}

.no-data {
  min-height: 355px;
}
</style>
