<template>
  <div class="d-flex align-center justify-center ma-6">
    <v-progress-circular
      :size="spinnerSize"
      color="primary"
      indeterminate
    ></v-progress-circular>
  </div>
</template>

<script>
/**
 * Simple loading component that displays a spinner
 */
export default {
  name: "DataLoading",
  props: {
    /**
     * Size of spinner animation
     */
    spinnerSize: {
      type: Number,
      default: 50,
    },
  },
};
</script>
