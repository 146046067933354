<template>
  <div class="mb-4 converter-detail">
    <DataLoading v-if="loading.getTurbine" class="mt-10" />
    <div class="converter-page-grid" v-else>
      <div class="title-grid-row-1">
        <!-- HEADER -->
        <div>
          <div class="converter-detail__header d-flex align-center">
            <h3 class="d-flex align-center">
              <span class="main-title">
                {{ converterName }} ({{ converterModelName }})</span
              >
            </h3>
            <div
              class="converter-detail__header__help d-flex align-center justify-space-between"
            >
              <v-icon @click="handleClickInfoIcon">{{
                informationIcon
              }}</v-icon>
            </div>
          </div>
        </div>
      </div>
      <!-- PAGE CONTENT -->
      <div class="converter-risks-grid-row-2-col-1">
        <div class="converter-img">
          <img
            src="/img/converter.svg"
            alt="Converter diagram"
            width="100%"
            height="100%"
          />
        </div>
      </div>
      <div class="converter-risks-grid-row-2-col-2">
        <ConverterRiskFactorsCard
          :riskFactors="converterRiskFactors"
          :loading="loadingConverterRiskFactors"
          @goToIssueTable="goToIssuesTable('default')"
          @getRiskFactorDefinitions="getRiskFactorDefinitions"
        />
      </div>
      <div class="tab-bank-grid-row-3">
        <div class="new-tab">
          <button
            v-for="tab in tabs"
            :ref="tab.name"
            :key="tab.id"
            :class="{ tablinks: true, active: activeTab === tab.id }"
            @click="openTab(tab.id, tab.name, 'click_event')"
          >
            {{ tab.name }}
          </button>
        </div>
        <div class="tabbed-section">
          <!-- Needs to be fleshed out -->
          <!--
          <div id="Recommendations" class="tabcontent" v-show="activeTab === 1">
            <ConverterRecommendationsTab
              :recommendations="tempRecommendations"
            />
          </div> -->
          <div id="Issues" class="tabcontent" v-show="activeTab === 2">
            <ConverterIssuesTab
              :issues="mappedIssues"
              :siteId="siteId"
              :loading="loading.getPaginatedIssuesForIssuesTable"
            />
          </div>
          <div id="Explore" class="tabcontent pa-4" v-if="activeTab === 3">
            <div v-if="!isPowerCurveExplorerOpen && !isEventsViewOpen">
              <NewAnalyticsView
                ref="analyticsView"
                :turbineData="turbinesData"
                :siteId="siteId"
                pageTitle="mainBearingDetail"
                @setStaticViewRequest="setAnalyticsViewRequest"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
            <div v-if="isPowerCurveExplorerOpen && !isEventsViewOpen">
              <NewPowerCurveExplorer
                :targetTurbineOptions="targetTurbineOptions"
                :loadingTurbines="turbinesLoading"
                :siteId="siteId"
                @openPowerCurveExplorer="togglePowerCurveExplorer"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
            <div v-if="isEventsViewOpen && !isPowerCurveExplorerOpen && siteId">
              <EventsExplorer
                page="converterDetail"
                :siteId="siteId"
                @openEventsView="toggleEventsView"
                @viewChanged="updateAnalyticsViewSelected"
              />
            </div>
          </div>
          <div id="Description" class="tabcontent" v-if="activeTab === 4">
            <div class="converter-description">
              <p class="ma-0">{{ converterDescription.description1 }}</p>
              <p class="ma-0 mt-2">{{ converterDescription.description2 }}</p>
              <p class="ma-0 mt-2">{{ converterDescription.description3 }}</p>
            </div>
          </div>
          <!-- Hiding until functionality fleshed out -->
          <!-- <div id="Converters" class="tabcontent" v-show="activeTab === 5">
            <p class="pa-4 converter-links-text">Links to other coverters</p>
          </div> -->
        </div>
      </div>
      <div class="evidence-grid-row-4-col-1">
        <ConverterDetailAnalyticsFigure
          :analyticsFigureData="igbtTempPlotData"
          :loading="loadingAnalyticsFigures"
        />
      </div>
      <div class="evidence-grid-row-4-col-2">
        <ConverterDetailAnalyticsFigure
          :analyticsFigureData="coolingLiquidTempPlotData"
          :loading="loadingAnalyticsFigures"
        />
      </div>
      <div class="summary-cards-grid-row-5-col-1">
        <AllIssuesCard
          :siteName="siteName"
          :issuesByStatus="issuesByStatus"
          :loading="loading.getPaginatedIssuesForIssuesTable"
          page="converterDetail"
          @goToIssues="goToIssuesTable"
        ></AllIssuesCard>
      </div>
      <div class="summary-cards-grid-row-5-col-2">
        <ConverterMaintenanceCard
          :siteName="siteName"
          :maintenanceData="modsByConverter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { informationIcon } from "@/helpers/variables";
import ConverterDetailAnalyticsFigure from "@/components/ConverterDetailComponents/ConverterDetailAnalyticsFigure";
import ConverterMaintenanceCard from "@/components/ConverterDetailComponents/ConverterMaintenanceCard";
import ConverterRiskFactorsCard from "@/components/ConverterDetailComponents/ConverterRiskFactorsCard";
import DataLoading from "@/components/DataLoading";
import ConverterIssuesTab from "@/components/ConverterDetailComponents/ConverterIssuesTab";
import ConverterRecommendationsTab from "@/components/ConverterDetailComponents/ConverterRecommendationsTab";
import NewAnalyticsView from "@/components/NewIssueDetailComponents/NewAnalyticsView";
import NewPowerCurveExplorer from "@/components/NewIssueDetailComponents/NewPowerCurveExplorer";
import EventsExplorer from "@/components/NewSiteDashboardComponents/EventsExplorer";
import AllIssuesCard from "@/components/AllIssuesCard";
import { roundToString } from "@/helpers/functions";
import dayjs from "dayjs";
import mixpanel from "mixpanel-browser";

export default {
  name: "ConverterDetail",
  components: {
    ConverterDetailAnalyticsFigure,
    ConverterMaintenanceCard,
    ConverterRiskFactorsCard,
    DataLoading,
    ConverterIssuesTab,
    ConverterRecommendationsTab,
    NewAnalyticsView,
    NewPowerCurveExplorer,
    AllIssuesCard,
    EventsExplorer,
  },
  props: {
    siteId: {
      type: Number,
      default: null,
    },
    siteName: {
      type: String,
      default: null,
    },
    converterData: {
      type: Object,
      default: null,
    },
    converterDescription: {
      type: Object,
      default: null,
    },
    turbineData: {
      type: Object,
      default: null,
    },
    turbinesData: {
      type: Array,
      default: null,
    },
    modsByConverter: {
      type: Array,
      default: null,
    },
    issuesByStatus: {
      type: Array,
      default: null,
    },
    converterRiskFactors: {
      type: Object,
      default: null,
    },
    loadingConverterRiskFactors: {
      type: Boolean,
      default: false,
    },
    analyticsFiguresData: {
      type: Object,
      default: null,
    },
    loadingAnalyticsFigures: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      informationIcon,
      tabs: [
        // {
        //   id: 1,
        //   name: "RECOMMENDATIONS",
        // },
        {
          id: 2,
          name: "ISSUES",
        },
        {
          id: 3,
          name: "EXPLORE",
        },
        {
          id: 4,
          name: "DESCRIPTION",
        },
        // {
        //   id: 5,
        //   name: "CONVERTERS",
        // },
      ],
      activeTab: 2,
      isPowerCurveExplorerOpen: false,
      staticAnalyticsViewRequest: {},
      tempRecommendations: [
        {
          id: 1,
          action: "Action item 1: Detail about what to do, etc.",
          completed_ts: "2023-11-30 00:00:00",
        },
        {
          id: 2,
          action: "Action item 2",
          completed_ts: "2023-11-30 00:00:00",
        },
        {
          id: 3,
          action: "Action item 3",
          completed_ts: "2023-11-30 00:00:00",
        },
        {
          id: 4,
          action: "Action item 4",
          completed_ts: null,
        },
        {
          id: 5,
          action: "Action item 5",
          completed_ts: "2024-01-01 00:00:00",
        },
        {
          id: 6,
          action: "Action item 6",
          completed_ts: "2024-01-01 00:00:00",
        },
        {
          id: 7,
          action: "Action item 7",
          completed_ts: null,
        },
        {
          id: 8,
          action: "Action item 8",
          completed_ts: null,
        },
      ],
      openExploreTab: false,
      roundToString,
      isEventsViewOpen: false,
    };
  },
  computed: {
    ...mapState({
      userData: (state) => state.user.userData,
      loading: (state) => state.turbine.loading,
      turbinesLoading: (state) => state.site.loading.getTurbinesBySiteId,
    }),
    converterName() {
      return this.converterData?.name ?? "";
    },
    converterModelName() {
      return this.converterData?.model_name ?? "Unknown model";
    },
    targetTurbineOptions() {
      if (this.turbinesData.length > 0) {
        const options = this.turbinesData.map((turbine) => ({
          id: turbine.id,
          text: turbine.turbine_name,
          value: turbine.id,
          position: turbine.position,
          site_id: turbine.site_id,
          turbine_model: turbine.turbine_model,
          mapInfoTitle: turbine.mapInfoTitle,
          color: "grey",
        }));
        return options;
      } else {
        return [];
      }
    },
    isInternal() {
      return this.userData.is_internal;
    },
    issues() {
      if (this.issuesByStatus && this.isInternal) {
        return this.issuesByStatus;
      } else {
        return this.issuesByStatus.filter(
          (issue) =>
            !issue.current_status_def_name_external.includes("internal"),
        );
      }
    },
    mappedIssues() {
      if (this.issues.length > 0) {
        return this.issues.map((issue) => {
          return {
            id: issue.id,
            name: issue.name,
            description: issue.description,
            status: issue.current_status_def_name_external,
            current_status_def_id: issue.current_status_def_id,
            impact_mwh: roundToString(issue.aep_loss_mwhpyr, 0),
            impact_usd: roundToString(issue.aep_loss_upper_usdpyr, 0),
            ticketsInProgress: issue.work_order_ids,
            last_updated: dayjs(issue.current_status_created_ts).format(
              "YYYY-MM-DD",
            ),
          };
        });
      }
      return [];
    },
    converterHelpInfo() {
      return (
        this.converterDescription.description1 +
        "\n\n" +
        this.converterDescription.description2 +
        "\n\n" +
        this.converterDescription.description3
      );
    },
    ambientTempPlotData() {
      if (
        this.analyticsFiguresData &&
        "ambient_temp" in this.analyticsFiguresData
      ) {
        return this.analyticsFiguresData.ambient_temp;
      }
      return null;
    },
    coolingLiquidTempPlotData() {
      if (
        this.analyticsFiguresData &&
        "cooling_liquid_temp" in this.analyticsFiguresData
      ) {
        return this.analyticsFiguresData.cooling_liquid_temp;
      }
      return null;
    },
    igbtTempPlotData() {
      if (
        this.analyticsFiguresData &&
        "igbt_temp" in this.analyticsFiguresData
      ) {
        return this.analyticsFiguresData.igbt_temp;
      }
      return null;
    },
  },
  methods: {
    ...mapActions({
      updateShowBurger: "app/updateShowBurger",
      updateBurgerData: "app/updateBurgerData",
    }),
    handleClickInfoIcon() {
      this.$emit("helpIconClicked");
    },
    openTab(tabId, tabName, method) {
      const url = new URL(window.location.href);
      const analyticsView = url.searchParams.get("analytics_view");
      if (tabName !== "EXPLORE" && analyticsView) {
        url.search = "";
        history.replaceState(null, null, url);
      }
      this.activeTab = tabId;
      if (method !== "method") {
        mixpanel.track("converter_detail_tab_click", {
          tab_name: tabName,
        });
      }
      this.setUrlByTab(tabId);
      if (tabId === 3) {
        if (this.siteId) {
          this.goToExploreTab();
          if (analyticsView === "events") {
            this.isEventsViewOpen = true;
          } else if (analyticsView === "power_curve_explorer") {
            this.isPowerCurveExplorerOpen = true;
          }
        } else {
          this.openExploreTab = true;
        }
      }
      // Using timeout because next tick is not fast enough to see the element
      if (tabName) {
        this.timeout = setTimeout(() => {
          const element = this.$refs[tabName];
          // Scroll to the active tab
          element[0].scrollIntoView({
            behavior: "smooth",
            block: "center",
            inline: "nearest",
          });
        }, 1500);
      }
    },
    // Call this when tab is clicked
    setUrlByTab(tabId) {
      const url = new URL(window.location.href);
      const tab = this.tabs.find((tab) => tab.id === tabId);
      if (tab) {
        url.hash = tab.name.toLowerCase();
        window.history.replaceState(null, null, url);
      }
    },
    // Call this on mounted
    setTabByUrl(hashName) {
      const tabName = hashName.replace("#", "").toUpperCase();
      const tab = this.tabs.find((tab) => tab.name === tabName);
      if (tab && tab.id) {
        this.openTab(tab.id, tabName, "method");
      }
    },
    isActiveTab(tabId) {
      return this.activeTab === tabId;
    },
    goToExploreTab() {
      this.$emit("goToExploreTab");
      this.openExploreTab = false;
    },
    setAnalyticsViewRequest(request) {
      // Request details for setting url params on navigation to site analytics tab
      this.staticAnalyticsViewRequest.site_id = request.site_id;
      this.staticAnalyticsViewRequest.start_date = request.start_date;
      this.staticAnalyticsViewRequest.end_date = request.end_date;
      this.staticAnalyticsViewRequest.turbine_ids = request.turbine_ids;
      this.staticAnalyticsViewRequest.view = request.view;
    },
    togglePowerCurveExplorer(isOpen) {
      this.isPowerCurveExplorerOpen = isOpen;
    },
    toggleEventsView(isOpen) {
      this.isEventsViewOpen = isOpen;
    },
    updateAnalyticsViewSelected(view) {
      if (view === "events") {
        this.toggleEventsView(true);
      } else {
        this.toggleEventsView(false);
      }
    },
    goToIssuesTable(payload) {
      this.$emit("goToIssuesTable", payload);
    },
    getRiskFactorDefinitions() {
      this.updateShowBurger(true);
      this.updateBurgerData({
        name: "Definitions",
        isRiskFactors: true,
      });
      document.documentElement.classList.add("no-scroll");
    },
  },
  mounted() {
    const url = new URL(window.location.href);
    // If the url has a hash, set the active tab by the hash
    if (url.hash) {
      this.setTabByUrl(url.hash);
    }
  },
  beforeDestroy() {
    clearTimeout(this.timeout);
  },
  watch: {
    // Setting up this watcher to run opening explore tab only after
    // site id is available
    siteId: {
      immediate: true,
      handler(value) {
        const url = new URL(window.location.href);
        const analyticsView = url.searchParams.get("analytics_view");
        if (value && this.openExploreTab) {
          this.goToExploreTab();
          if (analyticsView === "events") {
            this.isEventsViewOpen = true;
          } else {
            this.isEventsViewOpen = false;
          }
        }
      },
    },
    converterData: {
      handler(data) {
        // Send user to all site converters page if no converter is present
        if (!this.loading.getConverterData && data.siteId && !data?.id) {
          this.$router.push({
            path: `/sites/${data.siteId}/converters`,
          });
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.converter-page-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 40px 316px auto auto auto;
  grid-gap: 0.75rem;
}
.title-grid-row-1 {
  grid-column-start: 1;
  grid-column-end: 3;
}
.converter-risks-grid-row-2-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
  height: 316px;
  border: 1px dotted var(--v-componentImageBorder-base);
  border-radius: 8px;
}
.converter-risks-grid-row-2-col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
  height: 316px;
}
.converter-img {
  height: 100%;
  padding: 0.5rem 0.5rem;

  img {
    height: 100%;
    width: 100%;
  }
}

.tab-bank-grid-row-3 {
  grid-column-start: 1;
  grid-column-end: 3;
}

.evidence-grid-row-4-col-1 {
  grid-column-start: 1;
  grid-column-end: 2;
}
.evidence-grid-row-4-col-2 {
  grid-column-start: 2;
  grid-column-end: 3;
}

.summary-cards-grid-row-5-col-1 {
  height: 325px;
}
.summary-cards-grid-row-5-col-2 {
  height: 325px;
}

.converter-detail {
  &__subtitle {
    p {
      font:
        0.75rem "Museo Sans Rounded",
        sans-serif;
      color: var(--v-text-base);
      font-weight: 600;
    }
  }

  &__header {
    &__help {
      color: var(--v-black3-base);
      box-sizing: border-box;
      padding: 2px 16px 8px 8px;
      cursor: pointer;

      .v-icon {
        color: var(--v-black3-base);
      }
      .v-icon:hover {
        color: var(--v-primary-base) !important;
      }
    }
  }
}

.tabbed-section {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  border-top-left-radius: 0;
  min-height: 150px;
}

.converter-description {
  font-size: 0.95rem;
  padding: 1rem;
}

.converter-links-text {
  font-size: 0.95rem;
}

@media (max-width: 1264px) {
  .converter-page-grid {
    display: block;
  }
  .converter-risks-grid-row-2-col-1,
  .converter-risks-grid-row-2-col-2,
  .tab-bank-grid-row-3,
  .evidence-grid-row-4-col-1,
  .evidence-grid-row-4-col-2,
  .summary-cards-grid-row-5-col-1,
  .summary-cards-grid-row-5-col-2 {
    margin-top: 0.75rem;
  }
}
</style>
