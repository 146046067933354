<template>
  <div>
    <div v-if="figureLayout" class="converter-evidence-container">
      <Plotly
        id="plotly_diagram"
        :data="analyticsFigure.data"
        :layout="figureLayout"
        :responsive="true"
        :display-mode-bar="true"
        :showTips="false"
      ></Plotly>
    </div>
    <div v-else class="no-data d-flex justify-center align-center">
      <div
        class="d-flex justify-center align-center no-data__spinner"
        v-if="loading"
      >
        <v-progress-circular
          :size="45"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <p v-else>NO DATA AVAILABLE</p>
    </div>
    <!-- <div v-if="coolingLiquidTemp" class="liquid-cooled-figure">
      <Plotly
        id="plotly_diagram2"
        :data="coolingLiquidTemp.data"
        :layout="coolingLiquidTemp.layout"
        :responsive="true"
        :display-mode-bar="true"
        :showTips="false"
      ></Plotly>
    </div> -->
    <!-- <div v-else class="no-data2 mt-3 d-flex justify-center align-center">
      <div
        class="d-flex justify-center align-center no-data2__spinner"
        v-if="loading"
      >
        <v-progress-circular
          :size="45"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </div>
      <p v-else>NO DATA AVAILABLE</p>
    </div> -->
  </div>
</template>

<script>
import Plotly from "@/components/Plotly";

export default {
  name: "ConverterDetailAnalyticsFigure",
  components: {
    Plotly,
  },
  data() {
    return {};
  },
  props: {
    analyticsFigureData: {
      type: Object,
      required: false,
      default: null,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    analyticsFigure() {
      if (this.analyticsFigureData) {
        return this.analyticsFigureData;
      }
      return null;
    },
    figureLayout() {
      if (
        this.analyticsFigure &&
        Object.keys(this.analyticsFigure).length > 0
      ) {
        let plot = this.analyticsFigure;
        plot.layout.paper_bgcolor = this.$vuetify.theme.isDark ? "#1e1e1e" : "";
        plot.layout.font = {
          color: this.$vuetify.theme.isDark ? "#fff" : "",
        };
        return plot.layout;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
.no-data {
  position: relative;
  border: 1px solid var(--v-mediumGray-base);
  border-radius: 8px;
  padding: 0.5rem;
  height: 346px;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: #000;
    opacity: 0.4;
    z-index: 100;
  }
}

.converter-evidence-container {
  border: 1px solid var(--v-border-base);
  border-radius: 8px;
  padding: 0.25rem 0.5rem;
}

#plotly-diagram {
  border-radius: 8px;
  overflow: hidden;
}
</style>
