<template>
  <div>
    <div class="mr-3 mb-3 search-filter-wrapper">
      <v-text-field
        solo
        dense
        label="Search..."
        append-icon="mdi-magnify"
        hide-details
        :value="searchText"
        @input="updateSearch"
      ></v-text-field>
    </div>
  </div>
</template>

<script>
export default {
  name: "SearchWidget",
  props: {
    searchText: {
      type: String,
      required: false,
      default: "",
    },
  },
  data() {
    return {};
  },
  methods: {
    updateSearch(event) {
      this.$emit("searchTable", event);
    },
  },
};
</script>

<style lang="scss" scoped>
.search-filter-wrapper .v-text-field--enclosed {
  border-radius: 8px;
}
::v-deep {
  .search-filter-wrapper
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot {
    background-color: var(--v-search-base);
    border-color: var(--v-border-base);
    font-family: "Inter", sans-serif;
  }
  .search-filter-wrapper
    .v-text-field.v-text-field--solo:not(.v-text-field--solo-flat)
    > .v-input__control
    > .v-input__slot
    > .v-text-field__slot
    label {
    color: var(--v-black3-base) !important;
  }
}
</style>
