<template>
  <v-form>
    <div
      :class="
        $vuetify.theme.dark
          ? 'time-series-plot-container-dark'
          : 'time-series-plot-container-light'
      "
    >
      <div
        ref="controlsContainer"
        class="d-flex justify-center flex-wrap time-series-images"
      >
        <div class="d-flex flex-column source-input-container mr-1">
          <label for="source-input">Source:</label>
          <v-autocomplete
            name="source-input"
            id="source-input"
            content-class="pb-0"
            hide-no-data
            hide-selected
            placeholder="Select source..."
            dense
            solo
            :items="plotsSourceOptions"
            v-model="plotsSourceSelection"
            @blur="handleChangeSource"
          ></v-autocomplete>
        </div>
        <div class="d-flex flex-column time-window-input-container ml-1">
          <label for="time-window-input">Window:</label>
          <v-autocomplete
            name="time-window-input"
            id="time-window-input"
            content-class="pb-0"
            hide-no-data
            hide-selected
            placeholder="Select window..."
            dense
            solo
            :rules="[rules.required]"
            :items="timeWindowOptions"
            v-model="timeWindowSelection"
            @blur="handleChangeTimeWindow"
          ></v-autocomplete>
        </div>
        <div class="time-series-selections" ref="signalsContainer">
          <label for="signal-input">Signals:</label>
          <v-autocomplete
            ref="signalOptions"
            name="signal-input"
            id="signal-input"
            solo
            hide-details
            hide-selected
            multiple
            small-chips
            deletable-chips
            clearable
            content-class="pb-0"
            placeholder="Select signals..."
            :items="signalOptions"
            v-model="signalSelections"
            @keyup.enter="handleKeyupEnterSignals"
            @blur="commitSignalSelections"
            @change="limitNumberOfSignalSelections"
            @click:clear="clearSignals"
          ></v-autocomplete>
        </div>
      </div>
      <div ref="timeseriesPlots" class="time-series-plots">
        <div
          class="plot"
          :class="$vuetify.theme.dark ? 'plot-dark' : 'plot-light'"
        >
          <Plotly
            v-if="plotsData && Object.keys(plotsData).length > 0"
            :data="plotsData.data"
            :layout="plotsData.layout"
          ></Plotly>
          <div
            class="d-flex justify-center align-center plot-light__spinner"
            v-if="loading"
          >
            <v-progress-circular
              :size="50"
              color="primary"
              indeterminate
            ></v-progress-circular>
          </div>
          <div
            v-if="
              (!plotsData || Object.keys(plotsData).length === 0) && !loading
            "
            class="no-data-available"
          >
            TO DISPLAY A TIMESERIES FIGURE, CLICK A POINT ON ONE OF THE POWER
            CURGE FIGURES
          </div>
        </div>
      </div>
    </div>
  </v-form>
</template>

<script>
import Plotly from "@/components/Plotly";

export default {
  name: "TimeSeriesPlotCard",
  components: {
    Plotly,
  },
  props: {
    timeseriesPlots: {
      type: Object,
      required: false,
      default: () => {},
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      plotsSourceOptions: [
        {
          text: "10-min",
          value: "10m",
        },
        {
          text: "HF",
          value: "hf",
        },
      ],
      hfTimeWindowOptions: [
        {
          text: "1 hour",
          value: "1_hour",
        },
        {
          text: "3 hours",
          value: "3_hours",
        },
        {
          text: "6 hours",
          value: "6_hours",
        },
      ],
      tenMinTimeWindowOptions: [
        {
          text: "1 day",
          value: "1_day",
        },
        {
          text: "3 days",
          value: "3_days",
        },
        {
          text: "6 days",
          value: "6_days",
        },
      ],
      signalOptions: [
        {
          text: "Ambient temperature",
          value: "ambienttemp_degc_mn",
        },
        {
          text: "Blade 1 pitch",
          value: "pitchangleblade1_deg_mn",
        },
        {
          text: "Generator speed",
          value: "generatorspeed_rpm_mn",
        },
        {
          text: "Nacelle direction",
          value: "nacelledirection_deg_mn",
        },
        {
          text: "Power",
          value: "realpower_kw_mn",
        },
        {
          text: "Rotor speed",
          value: "rotorspeed_rpm_mn",
        },
        {
          text: "Wind direction",
          value: "winddirection_deg_mn",
        },
        {
          text: "Wind speed",
          value: "windspeed_mps_mn",
        },
        {
          text: "Yaw error",
          value: "yawerror_deg_mn",
        },
      ],
      rules: {
        required: (value) => !!value || "Window must be selected.",
      },
      plotsSourceSelection: "",
      signalSelections: [],
      resizePlotContainer: false,
      resizeObserver: null,
      heightOfTimeseriesControls: "",
      timeWindowSelection: "",
      staticPlotsSourceSelection: "10m",
    };
  },
  computed: {
    // Replot timeseries image when controls input container changes height
    plotsData() {
      let resize = "";
      if (
        this.timeseriesPlots &&
        Object.keys(this.timeseriesPlots).length > 0
      ) {
        if (this.heightOfTimeseriesControls <= 75) {
          resize = "one-row";
        } else if (this.heightOfTimeseriesControls <= 150) {
          resize = "two-rows";
        }
        return this.setTimeseriesPlotsStyle(this.timeseriesPlots, resize);
      } else {
        return [];
      }
    },
    timeWindowOptions() {
      if (this.plotsSourceSelection === "hf") {
        return this.hfTimeWindowOptions;
      } else {
        return this.tenMinTimeWindowOptions;
      }
    },
  },
  methods: {
    setPlotsSourceDefault() {
      this.plotsSourceSelection = this.plotsSourceOptions[0].value;
      this.timeWindowSelection = this.timeWindowOptions[0].value;
      this.setSignalsDefault();
      this.emitSelections();
    },
    setSignalsDefault() {
      if (this.plotsSourceSelection === "10m") {
        this.signalSelections = [
          "windspeed_mps_mn",
          "realpower_kw_mn",
          "pitchangleblade1_deg_mn",
          "rotorspeed_rpm_mn",
        ];
      } else if (this.plotsSourceSelection === "hf") {
        this.signalSelections = [
          "windspeed_mps",
          "realpower_kw",
          "pitchangleblade1_deg",
          "rotorspeed_rpm",
        ];
      }
    },
    handleChangeSource(event) {
      if (event) {
        if (
          (this.timeWindowSelection.includes("day") &&
            this.plotsSourceSelection === "10m") ||
          (this.timeWindowSelection.includes("hour") &&
            this.plotsSourceSelection === "hf")
        ) {
          this.emitSelections();
        }
      }
    },
    emitSelections() {
      const params = {
        source: this.plotsSourceSelection,
        timeWindow: this.timeWindowSelection,
        signals: this.signalSelections,
      };

      this.$emit("timeseriesOptions", params);
    },
    handleKeyupEnterSignals() {
      this.$nextTick(() => {
        this.$refs["signalOptions"].blur();
      });
    },
    limitNumberOfSignalSelections() {
      if (this.signalSelections.length > 4) {
        this.signalSelections.shift();
      }
    },
    commitSignalSelections() {
      if (this.signalSelections.length > 0) {
        this.emitSelections();
      }
    },
    clearSignals() {
      this.signalSelections = [];
      this.emitSelections();
    },
    // Keep track of the controls container
    observeTimeseriesControlsContainer() {
      const controlsContainer = this.$refs.controlsContainer;
      const plotEl = this.$refs.timeseriesPlots;
      const resizeObserver = new ResizeObserver((entries) => {
        for (let entry of entries) {
          const newHeight = entry.target.offsetHeight;
          if (this.heightOfTimeseriesControls !== newHeight) {
            this.heightOfTimeseriesControls = newHeight;
          }
          this.updateHeightOfPlotEls(plotEl, newHeight);
        }
      });
      resizeObserver.observe(controlsContainer);
      this.resizeObserver = resizeObserver;
    },
    setTimeseriesPlotsStyle(timeseriesPlots, resize) {
      // eslint-disable-next-line no-undef
      const clonedPlotlyLayout = structuredClone(timeseriesPlots);
      if (resize === "one-row") {
        clonedPlotlyLayout.layout.height = "615";
      } else if (resize === "two-rows") {
        clonedPlotlyLayout.layout.height = "543";
      }
      clonedPlotlyLayout.layout.paper_bgcolor = this.$vuetify.theme.isDark
        ? "#1e1e1e"
        : "";
      clonedPlotlyLayout.layout.font = {
        color: this.$vuetify.theme.isDark ? "#fff" : "",
      };

      return clonedPlotlyLayout;
    },
    // Update height of plotly image container
    updateHeightOfPlotEls(elementToUpdate, height) {
      if (height >= 150) {
        elementToUpdate.style.height = 34 + "rem";
      } else if (height <= 75) {
        elementToUpdate.style.height = 38.5 + "rem";
      }
    },
    handleChangeTimeWindow() {
      this.emitSelections();
    },
  },
  mounted() {
    this.setPlotsSourceDefault();
    this.observeTimeseriesControlsContainer();
  },
  beforeDestroy() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
    }
  },
  watch: {
    signalSelections: {
      handler(data) {
        if (data.length === 0) {
          this.emitSelections();
        }
      },
    },
    plotsSourceSelection: {
      handler(data) {
        if (this.staticPlotsSourceSelection !== data) {
          this.staticPlotsSourceSelection = data;
        }
        if (
          (!this.timeWindowSelection.includes("day") && data === "10m") ||
          (!this.timeWindowSelection.includes("hour") && data === "hf")
        ) {
          if (data === "10m") {
            this.timeWindowSelection = "1_day";
          } else if (data === "hf") {
            this.timeWindowSelection = "1_hour";
          }
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.time-series-plot-container-light,
.time-series-plot-container-dark {
  border-radius: 8px;
  width: 100%;
  height: 100%;
  padding: 0.5rem;
}

.time-series-plot-container-light {
  border: 1px solid var(--v-pcBorderColor-base);
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.1);
}

.time-series-plot-container-dark {
  border: 1px solid var(--v-pcSubtleBorderColor-base);
  box-shadow: 1px 1px 2px 0 rgba(30, 30, 30, 0.4);
}

.time-series-images {
  padding: 0 0.25rem;
}

::v-deep {
  .source-input-container,
  .time-window-input-container {
    display: flex;
    align-items: baseline;
    min-width: 85px;
    margin-right: 0.25rem;

    label {
      margin-bottom: 0.125rem;
    }

    .v-input__slot {
      margin-bottom: 0;
    }

    .v-text-field__details {
      margin-bottom: 0;
    }
  }
}

.signal-input-container label {
  margin-bottom: 0.125rem;
}

::v-deep {
  .time-series-selections {
    min-width: 445px;
    padding: 0 0.25rem;
    margin-bottom: 0.25rem;
    .v-input__slot,
    .v-text-field__details {
      margin: 0;
    }
    label {
      margin-bottom: 0.125rem;
    }
  }
}

.time-series-plots {
  margin: 0.5rem 0.35rem 0 0.35rem;
}

.adjust-height {
  height: 40.3125rem;
}

.plot-light,
.plot-dark {
  height: 100%;
  position: relative;

  &__spinner {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: var(--v-pcSpinnerOverlay-base);
    opacity: 0.4;
    z-index: 200;
  }
}

.plot-light {
  box-shadow: 1px 2px 4px 1px rgba(0, 0, 0, 0.1);
}

.plot-dark {
  box-shadow: 1px 2px 10px 1px rgba(0, 0, 0, 0.7);
}

.timeseries-text {
  padding-top: 15rem;
}

.timeseries-text {
  max-width: 400px;
}
</style>
